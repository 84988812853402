/* Modal component to display login / signup / forgot password options */
import React, { Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';

import appLogo from '../../assets/fantxt-com.svg';

import ForgotPasswordForm from './ForgotPasswordForm';
import RegisterForm from './RegisterForm';
import LoginForm from './LoginForm';

interface LoginRegisterForgotPasswordModalProps {
  modalType: string;
  initModalEmail?: string;
  setModalType: (val: string) => void;
  open: boolean;
  setOpen: (val: boolean) => void;
  setDirectToPayment?: (val: boolean) => void;
}

const LoginRegisterForgotPasswordModal: React.FC<LoginRegisterForgotPasswordModalProps> =
  (props: LoginRegisterForgotPasswordModalProps) => {
    const { modalType, initModalEmail, setModalType, open, setOpen, setDirectToPayment } = props;
    const isRegisterModal = modalType === 'register';
    const isLoginModal = modalType === 'login';
    const isForgottenPasswordModal = modalType === 'forgotten-password';
    const cancelButtonRef = useRef(null);

    const renderModalForm = () => {
      if (isLoginModal) return <LoginForm initModalEmail={initModalEmail} setOpen={setOpen} />;

      if (isRegisterModal)
        return (
          <RegisterForm
            setOpen={setOpen}
            initModalEmail={initModalEmail}
            setModalType={setModalType}
          />
        );

      return <ForgotPasswordForm />;
    };

    return (
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          onClose={() => {if (setDirectToPayment) setDirectToPayment(false); setOpen(!open);}}
        >
          <div ref={cancelButtonRef} className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-800 bg-opacity-50 transition-opacity" />
            </Transition.Child>

            <span
              className="hidden sm:inline-block align-middle h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white self-center rounded-lg p-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full sm:p-6">
                <div className="">
                  <img
                    className="mx-auto h-20 w-auto"
                    src={appLogo}
                    alt="Workflow"
                  />
                  <h2 className="mt-4 text-center text-xl sm:text-2xl font-bold text-gray-900">
                    {(isRegisterModal && 'Register to continue') ||
                      (isLoginModal && 'Sign in to Fantxt') ||
                      (isForgottenPasswordModal && 'Forgotten password')}
                  </h2>
                  <p className="text-center text-sm text-gray-600">
                    {isLoginModal
                      ? 'No account yet? '
                      : 'Already have an account? '}
                    <button
                      type="button"
                      onClick={() =>
                        isLoginModal
                          ? setModalType('register')
                          : setModalType('login')
                      }
                      className="font-medium text-blue-600 hover:text-blue-500"
                    >
                      {isLoginModal ? 'Sign up' : 'Sign in'}
                    </button>
                  </p>
                </div>

                {/*
                  Render social media buttons here
                */}

                { // Render login/register/forgotten password modal forms
                  renderModalForm()
                }

                {isLoginModal && (
                  <div className="text-sm text-center mt-3">
                    <button
                      type="button"
                      onClick={() => setModalType('forgotten-password')}
                      className="text-gray-600 hover:text-gray-500"
                    >
                      {' '}
                      Forgot your password?{' '}
                    </button>
                  </div>
                )}
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

export default LoginRegisterForgotPasswordModal;
