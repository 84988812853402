import React from 'react';

import type { LoginRequest } from '../../interfaces';

import { TextInput } from '../../components';

interface LoginViewProps {
  formState: LoginRequest;
  handleChange: ({
    target: { name, value }
  }: React.ChangeEvent<HTMLInputElement>) => void;
  handleLoginClick: () => Promise<void>;
}

const LoginView: React.FC<LoginViewProps> = (props: LoginViewProps) => {
  const { formState, handleChange, handleLoginClick } = props;

  return (
    <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col">
      <div className="mb-4">
        <TextInput
          name="email"
          value={formState.email}
          handleChange={handleChange}
          label="E-mail"
        />
      </div>
      <div className="mb-6">
        <TextInput
          name="password"
          value={formState.password}
          handleChange={handleChange}
          label="Password"
          type="password"
          placeholder="******************"
        />
      </div>
      <div className="flex items-center justify-between">
        <button type="button" onClick={handleLoginClick}>
          Sign In
        </button>
        <a
          className="inline-block align-baseline font-bold text-sm text-blue hover:text-blue-darker"
          href="#"
        >
          Forgot Password?
        </a>
      </div>
    </div>
  );
};

export default LoginView;
