import React from 'react';

import { MessageHistoryResponse, User, Avatar, PaymentType } from '../../interfaces';

import { ConfirmationModal, ConfirmationModalProps, LoginSignupFloater } from '../../components';
import { MediaViewer, PaymentModal, UnlockContentModal } from '../../containers';

import Character from './_Character';
import ChatForm from './_Chatform';
import ContactList from './_Contactlist';

interface BaseViewProps {
  msgHistory?: MessageHistoryResponse;
  user: User;
  openMediaViewer: boolean;
  mediaUrl: string;
  setOpenMediaViewer: (val: boolean) => void;
  setModalType: (val: string) => void;
  open: boolean;
  setOpen: (val: boolean) => void;
  contentModalOpen: boolean;
  setContentModalOpen: (val: boolean) => void;
  setPaymentModalOpen: (val: boolean) => void;
  activeAvatar?: Avatar;
  contentCreditCost: number | undefined;
  contentNodeId: number | undefined;
  isPaymentModalOpen?: boolean;
  screenViewType: string;
  setSlideInContactList: (val: boolean) => void;
  setContentCreditCost: (val: number | undefined) => void;
  setContentNodeId: (val: number | undefined) => void;
  handleMediaClick: (val: string, setCurrentReference?: (refId:string) => void, refId?:string) => void;
  transformContactList: () => string;
  slideInContactList: boolean;
  transformContactListDiv: () => void;
  paymentType?: PaymentType;
  openContactList: boolean;
  setOpenContactList: (val: boolean) => void;
  unfriendAvatarModalOpen: boolean;
  unfriendAvatarProps: ConfirmationModalProps;
  setUnfriendAvatarProps: (val: ConfirmationModalProps) => void;
  setUnfriendAvatarModalOpen: (val: boolean) => void;
  isMobileView: boolean;
  closeChatForm: boolean;
  setCloseChatForm: (val: boolean) => void;
  isIOS: boolean;
  directToPayment: boolean;
  setDirectToPayment: (val: boolean) => void
}

const BaseView: React.FC<BaseViewProps> = (props: BaseViewProps) => {
  const {
    openMediaViewer,
    mediaUrl,
    activeAvatar,
    setOpenMediaViewer,
    setModalType,
    open,
    setOpen,
    contentModalOpen,
    setContentModalOpen,
    setPaymentModalOpen,
    contentCreditCost,
    contentNodeId,
    isPaymentModalOpen,
    screenViewType,
    setSlideInContactList,
    setContentCreditCost,
    setContentNodeId,
    handleMediaClick,
    transformContactList,
    slideInContactList,
    transformContactListDiv,
    paymentType,
    openContactList,
    setOpenContactList,
    unfriendAvatarModalOpen,
    unfriendAvatarProps,
    setUnfriendAvatarProps,
    setUnfriendAvatarModalOpen,
    isMobileView,
    closeChatForm,
    setCloseChatForm,
    msgHistory,
    user,
    isIOS,
    directToPayment,
    setDirectToPayment
  } = props;

  return (
    <>
      {openMediaViewer ? (
        <MediaViewer
          isMobileView={isMobileView}
          mediaNodes={[{ url: mediaUrl, title: activeAvatar!.avatarName }]}
          initialMediaIdx={0}
          setOpenMediaViewer={setOpenMediaViewer}
          isIOS={isIOS}
          handle={activeAvatar?.handle}
        />
      ) : null}

      <main
        className={
          openMediaViewer ? 'hidden' : `${isMobileView ? "flex flex-1 h-full w-full" : "flex-1 border-t border-gray-200 lg:flex min-w-0"}`
        }
      >
        <section className={`flex-1 ${isMobileView ? "h-screen" : "h-full"} flex flex-col overflow-hidden lg:order-last`}>
          <div className={`flex-1 justify-between ${isMobileView && "h-screen"} flex flex-col ${isMobileView && open && "overflow-hidden"} absolute inset-0 md:relative lg:relative md:h-screen lg:h-screen`}>
            <UnlockContentModal
              contentModalOpen={contentModalOpen}
              setContentModalOpen={setContentModalOpen}
              setPaymentModalOpen={setPaymentModalOpen}
              contentCreditCost={contentCreditCost}
              contentNodeId={contentNodeId}
              avatar={activeAvatar}
              isPaymentModalOpen={isPaymentModalOpen}
            />
            <PaymentModal
              open={isPaymentModalOpen || false}
              setOpen={setPaymentModalOpen}
              avatar={activeAvatar}
              paymentType={paymentType || PaymentType.SUBSCRIPTION}
              redirectPage="chat"
              isMobileView={isMobileView}
              contentNodeId={contentNodeId}
            />

            {isMobileView && msgHistory && activeAvatar && user && !user.isRegistered ? (
              <LoginSignupFloater
                avatarName={activeAvatar.avatarName}
                setModalType={setModalType}
                setOpen={setOpen}
                isMobileView={isMobileView}
              />
            ) : null}

            <Character
              isMobileView={isMobileView}
              slideInContactList={slideInContactList}
              screenViewType={screenViewType}
              setSlideInContactList={setSlideInContactList}
              openContactList={openContactList}
              setOpenContactList={setOpenContactList}
            />
            {/* Chat form container */}
            {isMobileView ? (!closeChatForm &&
              <ChatForm
                setContentCreditCost={setContentCreditCost}
                setContentNodeId={setContentNodeId}
                setContentModalOpen={setContentModalOpen}
                setModalType={setModalType}
                setOpen={setOpen}
                setSlideInContactList={setSlideInContactList}
                handleMediaClick={handleMediaClick}
                slideInContactList={slideInContactList}
                screenViewType={screenViewType}
                isMobileView={isMobileView}
                open={open}
                directToPayment={directToPayment}
                setDirectToPayment={setDirectToPayment}
                setPaymentModalOpen={setPaymentModalOpen}
                openMediaViewer={openMediaViewer}
              />) :
              <ChatForm
                setContentCreditCost={setContentCreditCost}
                setContentNodeId={setContentNodeId}
                setContentModalOpen={setContentModalOpen}
                setModalType={setModalType}
                setOpen={setOpen}
                setSlideInContactList={setSlideInContactList}
                handleMediaClick={handleMediaClick}
                slideInContactList={slideInContactList}
                screenViewType={screenViewType}
                isMobileView={isMobileView}
                open={open}
                directToPayment={directToPayment}
                setDirectToPayment={setDirectToPayment}
                setPaymentModalOpen={setPaymentModalOpen}
                openMediaViewer={openMediaViewer}
              />
            }
          </div>
        </section>

        {/* Contact list (hidden on smaller screens) */}
        <aside
          style={{
            transform: transformContactList()
          }}
          className={`${screenViewType === 'mobile' && 'absolute'} ${
            screenViewType === 'mobile' && slideInContactList && 'w-full'
          } ${!isMobileView ? "lg:block inset-y-0 transform transition duration-200 ease-in-out lg:flex-shrink-0 lg:order-first sm:order-first md:order-first h-full" :
         "absolute w-full h-screen lg:block inset-y-0 flex flex-col"}`}
        >
          <div
            className={`h-full relative flex flex-1 justify-between flex-col ${transformContactListDiv()} border-r border-gray-200 bg-white`}
          >
            {
            unfriendAvatarModalOpen ?
              <ConfirmationModal {...unfriendAvatarProps} /> : null
            }
            <ContactList
              setCloseChatForm={setCloseChatForm}
              isMobileView={isMobileView}
              screenViewType={screenViewType}
              slideInContactList={slideInContactList}
              setSlideInContactList={setSlideInContactList}
              setOpenContactList={setOpenContactList}
              unfriendAvatarProps={unfriendAvatarProps}
              setUnfriendAvatarProps={setUnfriendAvatarProps}
              setUnfriendAvatarModalOpen={setUnfriendAvatarModalOpen}
            />
          </div>
        </aside>
      </main>
    </>
  );
};

export default BaseView;
