/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';

import { Avatar } from '../../interfaces';

import { PaymentElement, UnlockContentModalBtn} from '../../components';

import HrWithTitle from './HrWithTitle';

interface MembershipBlockViewProps {
  avatar: Avatar;
  activateMembershipAsync: () => Promise<void>;
  membershipChecked?: boolean;
  checkMembership: (e: React.MouseEvent<HTMLElement>) => void;
  onlyMembership?: boolean;
  subscribable?: boolean;
  handleSubscribe: () => void;
}

const MembershipBlockView: React.FC<MembershipBlockViewProps> = (
  props: MembershipBlockViewProps
) => {
  const {
    avatar,
    activateMembershipAsync,
    membershipChecked,
    checkMembership,
    onlyMembership,
    subscribable,
    handleSubscribe,
  } = props;

  return avatar && avatar.membership && !avatar.membership.active ? (
    <div>
      {!onlyMembership && <HrWithTitle title="Membership available" marginTop="8"/>}
      <p className="mt-2 mb-6 text-center text-sm sm:text-base text-gray-600">
        Become a member and
        <span
          onClick={() => activateMembershipAsync()}
          className="font-medium text-blue-600 cursor-pointer"
        >
          {' '}
          unlock all{' '}
        </span>
        content from <b>{avatar.avatarName}</b>
      </p>
      <div onClick={checkMembership}>
        <PaymentElement
          isActive={membershipChecked || false}
          leftText={`${avatar.avatarName}'s ${avatar.membership.name}`}
          id={`${avatar.avatarName}'s ${avatar.membership.name}`}
          rightText={
            <div className="block sm:text-right">
              <div className="font-medium text-red-500">
                {avatar.membership.firstTimePrice} credits first month
              </div>
              <div className="font-medium text-gray-900">
                {avatar.membership.price} credits{' '}
                <span className="ml-1 text-gray-500">/mo</span>
              </div>
            </div>
          }
        />
      </div>
      {subscribable && <UnlockContentModalBtn
        id="creditSubscriptionModalButton"
        btnClass="group relative w-full sm:max-w-lg inline-flex justify-center rounded-md shadow-sm px-4 py-2 border border-blue-500 font-medium text-blue-500 hover:border-blue-600 hover:text-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        btnText="Subscribe now"
        handleClick={handleSubscribe}
      />}
    </div>
  ) : null;
};

export default MembershipBlockView;
