/* Custom modal component */
import React from 'react';
import { toast } from 'react-toastify';

import { persistor } from '../../reducers/store';
import { useDeleteUserMutation } from '../../reducers/user';
import WSApi from '../../services/ws-api-handler/ws-api';

import CustomModalView, { CustomModalLayout } from './CustomModalView';

export interface ModalProps {
  icon?: string | JSX.Element;
  title: string;
  description: string | JSX.Element;
  button1Text: string;
  button2Text?: string;
  layout?: CustomModalLayout;
}

interface CustomModalProps {
  open: boolean;
  modalProps: ModalProps;
  setOpen: (value: boolean) => void;
}

const CustomModal: React.FC<CustomModalProps> = (props: CustomModalProps) => {
  const { open, setOpen, modalProps } = props;
  const { icon, title, description, button1Text, button2Text, layout } = modalProps;

  const [deleteUser] = useDeleteUserMutation();

  const handleDeleteClick = async () => {
    try {
      await deleteUser().unwrap();
      setOpen(false);

      toast.success('Successfully deleted account', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });

      logout();
    } catch (err) {
      toast.error('Something went wrong', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
    }
  };

  const logout = () => {
    WSApi.ws.disconnect();
    persistor.purge();
    try {
      localStorage.setItem('isUserSignedIn', 'false');
    } catch {
      console.warn('Cookies are blocked!');
    }
    window.location.href = `${process.env.REACT_APP_LANDINGPAGE_URL}`;
  };

  return (
    <CustomModalView
      open={open}
      setOpen={setOpen}
      title={title}
      icon={icon}
      description={description}
      handleDeleteClick={handleDeleteClick}
      button1Text={button1Text}
      button2Text={button2Text}
      layout={layout}
    />
  );
};

export default CustomModal;
