export interface Membership {
  active?: boolean;
  firstTimePrice: number;
  name: string;
  price: number;
}

export interface Post {
  id: number;
  avatarId: number;
  mediaId: number;
  mediaUrl?: string;
  postText: string;
  postDate: string;
  createdAt: string;
  updatedAt: string;
}

export interface Avatar {
  id: number;
  creatorId: number;
  isPromoted: boolean;
  avatarPicture: string;
  profileVideo: string;
  avatarName: string;
  handle: string;
  bio: string;
  isVerified: string;
  membership?: Membership;
  posts?: Array<Post>;
  createdAt: string;
  updatedAt: string;
  backgroundPicture?: string | null;
  popularity?: number;
  lastUpdated?: string;
  isIceBreaker?: boolean;
}

export interface UpdateBlockedStatusRequest {
  avatarId: number;
  change: string;
}

export interface OrderedAvatars {
  id: number;
  time: string;
}

export interface DiscoverAvatars {
  featured: Array<FeaturedImage>;
  recommended: Array<Avatar>;
}

export interface ChatSubscription {
  avatars: Array<Avatar>;
  active: boolean;
}

export interface FeaturedImage {
  active: boolean;
  rank: number;
  imageUrl: string;
  title: string | null;
  description: string | null;
  navigateTo: string | null;
}

export interface UnfriendAvatarResponse {
  status: string;
  message: string;
  result?: Array<Avatar>;
}

export enum SortType {
  POPULARITY = 'POPULARITY',
  LAST_UPDATED = 'LAST_UPDATED',
  DEFAULT = 'DEFAULT'
}

export interface ChatPromotion {
  avatar: Avatar;
  handle: string;
  image: string;
  video?: string;
  avatarName?: string;
  chatId?: number;
}
