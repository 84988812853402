/* Chat contactlist component to display chat partners */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { DotsVerticalIcon } from '@heroicons/react/solid';

import { Avatar } from '../../../interfaces';

interface ContactListViewProps {
  avatars: Array<Avatar>;
  unreadsByAvatar: {[key: string]: number};
  closeContactList: () => void;
  activeAvatar: Avatar;
  screenViewType: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleUnfriendButtonClick: (evt: any, id: number) => void;
  handleActiveAvatarChange: (avatar: Avatar | undefined) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleGoToProfile: (evt: any, avatarHandle: string) => void;
  isMobileView: boolean;
  scrollRef: React.RefObject<HTMLSpanElement>;
  newChatNotifications: Array<number>;
  slideInContactList: boolean;
}

const ContactListView: React.FC<ContactListViewProps> = (props: ContactListViewProps) => {
  const {
    avatars,
    unreadsByAvatar,
    closeContactList,
    activeAvatar,
    screenViewType,
    handleUnfriendButtonClick,
    handleActiveAvatarChange,
    handleGoToProfile,
    isMobileView,
    scrollRef,
    newChatNotifications,
    slideInContactList
  } = props;

  return (
    <>
    <span ref={scrollRef} />
    <ul className={`flex-1 divide-y divide-gray-200 ${isMobileView ? "bg-white pb-20" : "overflow-y-auto mb-14 md:mb-0 lg:mb-0"}`}>
      {(screenViewType !== 'mobile' || slideInContactList) && avatars ? avatars.map(avatar => (
        <li
          role="presentation"
          className="border-gray-300"
          key={avatar.id}
          onClick={closeContactList}
        >
          <div
            role="button"
            tabIndex={avatar.id}
            onClick={() => handleActiveAvatarChange(avatar)}
            className="relative group py-6 px-5 flex items-center"
          >
            <a className="-m-1 flex-1 block p-1 min-w-0">
              <div
                className={`${avatar && activeAvatar && avatar.id === activeAvatar.id && 'bg-gray-50' } absolute inset-0 group-hover:bg-gray-50`}
                aria-hidden="true"
              />
              <div className="flex-1 flex items-center min-w-0 relative">
                <span className="flex-shrink-0 h-10 inline-block relative">
                  <img
                    className={`h-10 w-10 rounded-full object-cover ${newChatNotifications.includes(avatar.id)
                      ? "ring ring-indigo-500 ring-offset-2" : ""}`}
                    src={avatar.avatarPicture}
                    alt=""
                  />
                  {(!activeAvatar && unreadsByAvatar[avatar.id] && unreadsByAvatar[avatar.id] > 0) ||
                   (activeAvatar && unreadsByAvatar[avatar.id] && unreadsByAvatar[avatar.id] > 0 &&
                    (avatar.id !== activeAvatar.id || screenViewType !== 'web')) ?
                      <span className="empty:hidden right-6 inline-flex justify-center px-2 py-1 text-xs font-medium leading-none text-red-100 transform translate-x-7 -translate-y-12 bg-red-600 rounded-full">
                        {unreadsByAvatar[avatar.id]}
                      </span>
                    :
                    null
                  }
                </span>
                <div className="ml-4 truncate">
                  <p className="text-sm font-medium text-gray-900 truncate">
                    {newChatNotifications.includes(avatar.id) &&
                      <span className="text-blue-500 inline-flex">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3 mr-1" viewBox="0 0 10 25" fill="currentColor">
                          <circle cx="5" cy="15" r="10"/>
                        </svg>
                      </span>
                    }
                    {avatar.avatarName}
                    {avatar && avatar.membership && avatar.membership.active &&
                      <span className="absolute top-0 items-center ml-2 px-2.5 py-0.5 rounded-full text-xs font-medium bg-pink-100 text-pink-500">
                        {avatar.membership.name}
                      </span>
                    }
                  </p>
                  <p className="text-sm text-gray-500 truncate">{avatar.bio}</p>
                </div>
              </div>
            </a>
            <Menu
              as="div"
              className="ml-2 flex-shrink-0 relative inline-block text-left"
            >
              {({ open }) => (
                <>
                  <Menu.Button className="md:mr-20 lg:mr-0 group relative w-8 h-8 bg-white rounded-full inline-flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-300">
                    <span className="sr-only">Open options menu</span>
                    <span className="flex items-center justify-center h-full w-full rounded-full">
                      <DotsVerticalIcon
                        className="w-5 h-5 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                    </span>
                  </Menu.Button>
                  <Transition
                    show={open}
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items
                      static
                      className="md:mr-20 lg:mr-0 origin-top-right absolute z-10 top-0 right-9 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                    >
                      <div className="py-1">
                        <Menu.Item>
                          {() => (
                            <a
                              onClick={(e) => handleGoToProfile(e, avatar.handle)}
                              role="button"
                              tabIndex={0}
                              className="text-gray-700 block px-4 py-2 text-sm relative"
                            >
                              Go to profile
                            </a>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {() => (
                            <a
                              onClick={(e) => handleUnfriendButtonClick(e, avatar.id)}
                              role="button"
                              tabIndex={0}
                              className="text-red-500 block px-4 py-2 text-sm relative"
                            >
                              Unfriend
                            </a>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </>
              )}
            </Menu>
          </div>
        </li>
      )) : null}
    </ul>
    </>
  );
};

export default ContactListView;
