/* Main navigation component for the app */
import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  PhotographIcon,
  ChatIcon,
  UserCircleIcon,
  SearchIcon
} from '@heroicons/react/outline';

import { SidebarNavigationObject } from '../../../interfaces';

import MainIcon from './MainIcon';

interface NavBarProps {
  item: SidebarNavigationObject;
  unreadsSum: number;
  isDisabled: boolean;
  navLinkClassName?: string;
  disabledOnClick?: () => void;
}

const NavBar: React.FC<NavBarProps> = (props: NavBarProps) => {
  const { item, isDisabled, navLinkClassName, unreadsSum, disabledOnClick } = props;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClick = (e: any) => {
    if (isDisabled) {
      e.preventDefault();
      if (disabledOnClick) disabledOnClick();
    }
  };

  return (
    <NavLink
      activeClassName={!isDisabled ? `${item?.icon === "MainIcon" ? "bg-indigo-500" : "bg-gray-900"} text-white` : undefined}
      onClick={handleClick}
      to={item && item.href}
      className={navLinkClassName || `text-gray-400 ${item?.icon === "MainIcon" ? "hover:bg-indigo-500" : "hover:bg-gray-700"} flex-shrink-0 inline-flex items-center justify-center h-14 w-14 rounded-lg`}
    >
      <span className="sr-only">{item && item.name}</span>
      {{
        SearchIcon: <SearchIcon className="h-6 w-6" aria-hidden="true" />,
        ChatIcon: (
          <div className='items-center justify-center h-6 w-6'>
            <ChatIcon className="h-6 w-6" aria-hidden="true" />
            {unreadsSum > 0 ? <span className="empty:hidden right-6 inline-flex justify-center px-2 py-1 text-xs font-medium leading-none text-red-100 transform translate-x-3 -translate-y-9 bg-red-600 rounded-full">
              {unreadsSum}
            </span> : null}
          </div>
        ),
        PhotographIcon: (
          <PhotographIcon className="h-6 w-6" aria-hidden="true" />
        ),
        UserCircleIcon: (
          <UserCircleIcon className="h-6 w-6" aria-hidden="true" />
        ),
        MainIcon: (
          <MainIcon className="h-6 w-6 bg-indigo-500" aria-hidden="true" />
        )
      }[item && item.icon] || (
        <UserCircleIcon className="h-6 w-6" aria-hidden="true" />
      )}
    </NavLink>
  );
};

export default NavBar;
