import React from 'react';

import { SubNavigationObject, PaymentType } from '../../interfaces';

import { Breadcrumb } from '../../components';
import { PaymentModal } from '../../containers';

import SettingsHeader from './SettingsHeader';

interface ProfileViewProps {
  handleMobileMenuOpen: (val: boolean) => void;
  renderPage: () => JSX.Element | null;
  mobileMenuOpen: boolean;
  classNames: (...classes: string[]) => string;
  subNavigation: Array<SubNavigationObject>;
  screenViewType: string;
  setPage: (val: string) => void;
  setOpenPurchaseHistory: (val: boolean) => void;
  page: string;
  purchaseHistoryOpen: boolean;
  closePurchaseHistory: () => void;
  isMobileView: boolean;
  navigateToMain: () => void;
  paymentType: PaymentType;
  isPaymentModalOpen: boolean;
  setOpen: (val: boolean) => void;
}

const ProfileView: React.FC<ProfileViewProps> = (props: ProfileViewProps) => {
  const {
    handleMobileMenuOpen,
    renderPage,
    mobileMenuOpen,
    classNames,
    subNavigation,
    screenViewType,
    setPage,
    setOpenPurchaseHistory,
    page,
    purchaseHistoryOpen,
    closePurchaseHistory,
    isMobileView,
    navigateToMain,
    paymentType,
    isPaymentModalOpen,
    setOpen,
  } = props;

  return (
    <main className={`${!isMobileView ? "flex-1 lg:flex" : "flex-1 lg:flex bg-white h-full"}`}>
      <PaymentModal
        open={isPaymentModalOpen || false}
        setOpen={setOpen}
        redirectPage="profile"
        paymentType={paymentType || PaymentType.SUBSCRIPTION}
        isMobileView={isMobileView}
      />
      {/* Breadcrumb */}
      <Breadcrumb
        setPage={setPage}
        text={purchaseHistoryOpen ? 'Billing' : 'Settings'}
        isRedirect={false}
        customBackFunction={purchaseHistoryOpen ? closePurchaseHistory : navigateToMain}
        setMobileMenuOpen={handleMobileMenuOpen}
        navClass={`lg:hidden border-b border-blue-gray-200 flex items-center ${isMobileView && "fixed top-0 w-full bg-gray-100 z-10"} ${mobileMenuOpen && isMobileView && "hidden"} space-x-2 lg:space-x-4 py-3`}
      />

      <div className={`${!isMobileView ? "flex-1 flex" : "flex flex-1 bg-white h-screen z-10"}`}>
        {/* Main content */}
        {renderPage()}

        {/* Secondary sidebar */}
        <SettingsHeader
          mobileMenuOpen={mobileMenuOpen}
          classNames={classNames}
          subNavigation={subNavigation}
          screenViewType={screenViewType}
          currentPage={page}
          setOpenPurchaseHistory={setOpenPurchaseHistory}
        />
      </div>
    </main>
  );
};

export default ProfileView;
