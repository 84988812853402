/* Membership choice block on an unlock / payment modal */
import React, { useEffect } from 'react';
import { toast, ToastOptions } from 'react-toastify';

import { Avatar, PaymentProgress } from '../../interfaces';

import { useAppDispatch, useAppSelector } from "../../reducers/hooks";
import { setPaymentProgress, setMembershipChecked, useActivateMembershipMutation } from '../../reducers/payment';
import { setFriendAvatars, useGetAllAvatarByUserMutation } from '../../reducers/avatar';
import { updateCredits } from '../../reducers/user/slice';

import MembershipBlockView from './MembershipBlockView';

interface MembershipBlockProps {
  avatar: Avatar;
  setOpen?: (open: boolean) => void;
  refFunc?: React.MutableRefObject<() => void>;
  subscribable?: boolean;
  onlyMembership?: boolean;
  successCallback?: () => void;
}

const MembershipBlock: React.FC<MembershipBlockProps> = (
  props: MembershipBlockProps
) => {
  const { avatar, setOpen, refFunc, subscribable, onlyMembership, successCallback } = props;

  useEffect(() => {
    if (refFunc) {
      refFunc.current = activateMembershipAsync;
    }
  }, []);

  const dispatch = useAppDispatch();
  const membershipChecked = useAppSelector((state) => state.payment.membershipChecked);

  const [activateMembership] = useActivateMembershipMutation();
  const [getFriendAvatars] = useGetAllAvatarByUserMutation();

  const checkMembership = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    dispatch(setMembershipChecked(!membershipChecked));
  };

  const activateMembershipAsync = async () => {
    if (avatar && avatar.handle) {
      dispatch(setPaymentProgress(PaymentProgress.WAIT_FOR_RESULT));
      const response = await activateMembership(avatar.handle).unwrap();
      if (response.status === 'success') {
        const avatars = await getFriendAvatars(null).unwrap();
        dispatch(setFriendAvatars(avatars));
        dispatch(setPaymentProgress(PaymentProgress.READY));

        if (response.credits || response.credits === 0) {
          dispatch(updateCredits(response.credits));
        }

        if (setOpen) {
          setOpen(false);
        }
        const toastConfig: ToastOptions = {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        };
        if (successCallback)
          successCallback();
        toast.success('Avatar membership activated', toastConfig);
      } else {
        dispatch(setPaymentProgress(PaymentProgress.READY));
      }
    }
  };

  const handleSubscribe = () => {
    if (membershipChecked)
      activateMembershipAsync();
  };

  return (
    <MembershipBlockView
      avatar={avatar}
      activateMembershipAsync={activateMembershipAsync}
      membershipChecked={membershipChecked}
      checkMembership={checkMembership}
      subscribable={subscribable}
      onlyMembership={onlyMembership}
      handleSubscribe={handleSubscribe}
    />
  );
};

export default MembershipBlock;
