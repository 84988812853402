import React from 'react';
import { Link } from 'react-router-dom';

import { Avatar, CharacterState } from '../../../interfaces';

interface CharacterViewProps {
  avatarState: CharacterState;
  handleContactListOpen: () => void;
  activeAvatarState: Avatar | null;
  activeAvatar: Avatar;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  history: any;
  isMobileView: boolean;
  slideInContactList: boolean;
  isDesktopView: boolean;
}

const CharacterView: React.FC<CharacterViewProps> = (
  props: CharacterViewProps
) => {
  const {
    avatarState,
    handleContactListOpen,
    activeAvatarState,
    activeAvatar,
    history,
    isMobileView,
    slideInContactList,
    isDesktopView
  } = props;

  const hideCharacterHeader = () => {
    let style = "";
    if(isMobileView) {
      if(slideInContactList) {
        style = "hidden";
      } else {
        style = "z-10";
      }
    }
    return style;
  };

  if(isMobileView && slideInContactList)
    return null;

  return (
    <div
      id="chat-header"
      className={`${isMobileView && "fixed top-0 w-full bg-gray-100"} ${hideCharacterHeader()} flex sm:items-center justify-between py-3 border-b border-gray-200 bg-white lg:bg-gray-100`}
    >
      <div className="flex items-center space-x-2 lg:space-x-4 min-w-0">
        {avatarState &&
          avatarState.avatars &&
          avatarState.avatars.length !== 0 && (
            <button
              onClick={handleContactListOpen}
              type="button"
              className={`inline-flex lg:hidden items-center justify-center rounded-full h-10 w-10 transition duration-500 ease-in-out text-gray-500 hover:bg-gray-300 focus:outline-none flex-none ${!slideInContactList ? "z-10" : ""}`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M15 19l-7-7 7-7"
                />
              </svg>
            </button>
          )}
        {activeAvatarState && (
          <Link className="flex-shrink-0 z-10" to={`/${activeAvatar?.handle}`}>
            <img
              src={activeAvatarState.avatarPicture}
              alt={activeAvatarState.avatarName}
              className="w-12 sm:w-16 h-12 sm:h-16 rounded-full object-cover"
            />
          </Link>
        )}
        <div className={`flex flex-col leading-tight min-w-0 sm:pl-20 sm:pr-14 sm:w-full sm:absolute
          ${!isDesktopView && "sm:pl-28 sm:pr-14"}`}>
          <div className="text-lg mt-1 flex items-center">
            <Link
              to={`/${activeAvatar?.handle}`}
              className="text-gray-700 mr-3"
            >
              {activeAvatarState && activeAvatarState.avatarName}
            </Link>
          </div>
          <div className="w-full truncate">
          <Link
            to={`/${activeAvatar?.handle}`}
            className="text-xs md:text-sm text-gray-600 truncate"
          >
            {activeAvatarState && activeAvatarState.bio}
          </Link>
          </div>
        </div>
      </div>
      {activeAvatarState && (
        <div className="flex items-end space-x-2 z-10">
          <button
            onClick={() => history.push(`/saved/${activeAvatarState.id}`)}
            type="button"
            className="inline-flex items-center justify-center rounded-full h-10 w-10 transition duration-500 ease-in-out text-gray-500 hover:bg-gray-300 focus:outline-none"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M7 4v16M17 4v16M3 8h4m10 0h4M3 12h18M3 16h4m10 0h4M4 20h16a1 1 0 001-1V5a1 1 0 00-1-1H4a1 1 0 00-1 1v14a1 1 0 001 1z"
              />
            </svg>
          </button>
        </div>
      )}
    </div>
  );
};

export default CharacterView;
