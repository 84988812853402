import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { Avatar, MessageNode, MediaNode } from '../../interfaces';

import { WithMobileDetectionProps } from '../../components';
import { useAppDispatch, useAppSelector } from "../../reducers/hooks";
import { useGetMediaNodesByAvatarMutation } from '../../reducers/base';
import { setPaymentModalOpen } from '../../reducers/payment';

import './Gallery.css';
import GalleryView from './GalleryView';

interface useParamsProps {
  id?: string;
  mediaId?: string;
}

const Gallery: React.FC<WithMobileDetectionProps> = (props: WithMobileDetectionProps) => {
  const { isMobileView, isIOS} = props;
  const { friendAvatars, isPaymentModalOpen, paymentType, user } = useAppSelector((state) => ({
    friendAvatars: state.avatar.friendAvatars,
    isPaymentModalOpen: state.payment.isPaymentModalOpen,
    paymentType: state.payment.paymentType,
    user: state.user.user
  }));

  const [getMediaNodesByAvatar, {isLoading}] = useGetMediaNodesByAvatarMutation();
  const [messageNodes, setMessageNodes] = useState<Array<MessageNode>>([]);
  const [mediaNodes, setMediaNodes] = useState<Array<MediaNode>>([]);
  const [mediaIdx, setMediaIdx] = useState<number>(0);
  const [openMediaViewer, setOpenMediaViewer] = useState(false);
  const [contentModalOpen, setContentModalOpen] = useState(false);
  const [contentCreditCost, setContentCreditCost] = useState<number | undefined>();
  const [contentNodeId, setContentNodeId] = useState<number | undefined>();
  const [refreshAfterUnlock, setRefreshAfterUnlock] = useState<boolean>(false);
  const [avatarName, setAvatarName] = useState<string | undefined>('');
  const [avatarHandle, setAvatarHandle] = useState<string | undefined>('');

  const location = useLocation();
  const history = useHistory();

  const { id, mediaId } = useParams<useParamsProps>();

  const dispatch = useAppDispatch();

  const getNodesOnMount = async () => {
    const result = await getMediaNodesByAvatar({ id: id === 'all' ? undefined : id }).unwrap();
    if (!result.messageHistory)
      return;
    const tmpNodes = [...result.messageHistory];
    tmpNodes.sort((a, b) => new Date(a.displayTime).valueOf() - new Date(b.displayTime).valueOf());
    setMessageNodes(tmpNodes);
    setMediaNodes(tmpNodes
      .filter(node => !node.isLocked)
      .map(node => ({url: node.mediaFile!, title: findAvatarById(node.avatarId!)?.avatarName || ''})));
  };

  useEffect(() => {
    if (!mediaId) {
      setOpenMediaViewer(false);
      return;
    }
    const idx = mediaNodes.findIndex(el => el.url.includes(mediaId));
    if (idx === -1)
      return;
    setMediaIdx(idx);
    setOpenMediaViewer(true);
  }, [mediaId, mediaNodes]);

  useEffect(() => {
    if (!user?.isRegistered)
      history.push('/');
  }, [history.location.pathname]);

  useEffect(() => {
    getNodesOnMount();
  }, [id, refreshAfterUnlock, friendAvatars]);

  const findAvatarById = (avatarId: number): Avatar | undefined =>
    friendAvatars.find(el => el.id === Number(avatarId));

  useEffect(() => {
    const avatar = findAvatarById(Number(id));
    if (avatar) {
      setAvatarName(avatar.avatarName);
      setAvatarHandle(avatar.handle);
    }
  }, [friendAvatars]);

  // If we clicked on a photo or video on another page, we arrive to Gallery and the MediaViewer will be opened.
  // Set the from state to the previous page path where the click was executed and open the MediaViewer.
  useEffect(() => {
    if (location.state && location.state.from) {
      setOpenMediaViewer(true);
    }
  }, [location.state]);
  
  const handleMediaClick = (url: string) => {
    const urlSplit = url ? url.split('/') : url;
    const name = urlSplit ? urlSplit[urlSplit.length - 1] : '';
    const idx = mediaNodes.findIndex(el => el.url === url);
    if (idx === -1)
      return;
    setMediaIdx(idx);
    setOpenMediaViewer(true);

    // Getting avatarHandle for clicked media
    let avtHandle : string | undefined;
    const splitByAvatar = url.split("avatar");
    if(splitByAvatar && splitByAvatar.length > 1) {
      const avatarId = splitByAvatar[1].split("/", 1);
      if(avatarId && avatarId.length > 0) {
        const [pathAvatarId] = avatarId;
        if(pathAvatarId) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const found = friendAvatars.find((f : any) => f.id.toString() === pathAvatarId);
          if(found) avtHandle = found.handle;
        }
      }
    }
    history.push(`/saved/${id || 'all'}/${name}`, {avtHandle});
  };

  const setPaymentModalOpenState = (isOpen: boolean) => {
    dispatch(setPaymentModalOpen(isOpen));
  };

  useEffect(() => {
    document.title = "Gallery - Fantxt";
  }, []);

  return (
    <GalleryView
      openMediaViewer={openMediaViewer}
      setOpenMediaViewer={setOpenMediaViewer}
      contentModalOpen={contentModalOpen}
      setContentModalOpen={setContentModalOpen}
      setPaymentModalOpen={setPaymentModalOpenState}
      contentCreditCost={contentCreditCost}
      contentNodeId={contentNodeId}
      isPaymentModalOpen={isPaymentModalOpen}
      setContentCreditCost={setContentCreditCost}
      setContentNodeId={setContentNodeId}
      handleMediaClick={handleMediaClick}
      paymentType={paymentType}
      setRefreshAfterUnlock={setRefreshAfterUnlock}
      friendAvatars={friendAvatars}
      id={id}
      mediaNodes={mediaNodes}
      mediaIdx={mediaIdx}
      messageNodes={messageNodes}
      avatarName={avatarName}
      avatarHandle={avatarHandle}
      history={history}
      isMobileView={isMobileView}
      isIOS={isIOS}
      userEmail={user?.email}
      isLoading={isLoading}
    />
  );
};

export default Gallery;
