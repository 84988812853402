import React from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import type { ResetPasswordRequest } from '../../interfaces';

import { persistor } from '../../reducers/store';
import { useAppDispatch } from "../../reducers/hooks";
import { useResetPasswordMutation } from '../../reducers/auth';
import { setCredentials } from '../../reducers/user';
import { setFriendAvatars, useGetAllAvatarByUserMutation } from '../../reducers/avatar';

import ResetPasswordView from './ResetPasswordView';

interface Props {
  match: {
    params: {
      token: string;
      uid: string;
    };
  };
}

const ResetPassword: React.FC<Props> = (props: Props) => {
  const { match } = props;

  const dispatch = useAppDispatch();
  const history = useHistory();
  const [getAvatars] = useGetAllAvatarByUserMutation();

  const [formState, setFormState] = React.useState<ResetPasswordRequest>({
    token: match.params.token,
    uid: match.params.uid,
    newPassword1: '',
    newPassword2: '',
  });

  const [resetPw] = useResetPasswordMutation();

  const handleChange = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) =>
    setFormState((prev) => ({ ...prev, [name]: value }));

  const getAvatarFriends = async () => {
    const avatars = await getAvatars(null).unwrap();
    dispatch(setFriendAvatars(avatars));
  };

  const onSubmit = async () => {
    try {
      if (formState.newPassword1 !== formState.newPassword2) {
        toast.error('The passwords do not match!', {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      }
      const response = await resetPw(formState).unwrap();
      if ('user' in response) {
        persistor.purge();
        dispatch(setCredentials(response));
        await getAvatarFriends();
  
        toast.success('You have successfully renewed your password.', {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        history.push(`/discover`);
      }
    } catch (err) {
      setFormState((prev) => ({ ...prev, newPassword1: '', newPassword2: '' }));
    }
  };

  return (
    <ResetPasswordView
      formState={formState}
      handleChange={handleChange}
      onSubmit={onSubmit}
    />
  );
};

export default ResetPassword;
