import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query';

import type { RootState } from '../store';

import { updateBothTokens } from '../user/slice';

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  prepareHeaders: (headers, { getState }) => {
    // By default, if we have a token in the store, let's use that for authenticated requests
    const { accessToken } = (getState() as RootState).user;
    if (accessToken) {
      headers.set('Authorization', `Bearer ${accessToken}`);
    }
    return headers;
  },
});

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    // try to get a new token
    const refreshResult = await baseQuery({
      url: '/auth/token/refresh/',
      method: 'POST',
      body: { refresh: (api.getState() as RootState).user.refreshToken},
    }, api, extraOptions);
    if (refreshResult.data) {
      // store the new token
      api.dispatch(updateBothTokens(refreshResult.data as unknown as {access: string; refresh: string;}));
      // retry the initial query
      result = await baseQuery(args, api, extraOptions);
    } else {
      localStorage.setItem('isUserSignedIn', 'false');
      window.location.href = `${process.env.REACT_APP_LANDINGPAGE_URL}`;
    }
  }
  return result;
};

export default baseQueryWithReauth;
