import React from 'react';

import { ConfirmationModal, ConfirmationModalProps } from '../../../components';

interface PurchaseHistoryViewProps {
  renderPaymentHistory: () => JSX.Element[] | null;
  hidePaymentModalOpen: boolean;
  hidePaymentModalProps: ConfirmationModalProps;
  isMobileView: boolean;
}

const PurchaseHistoryView: React.FC<PurchaseHistoryViewProps> = (
  props: PurchaseHistoryViewProps
) => {
  const { renderPaymentHistory, hidePaymentModalOpen, hidePaymentModalProps, isMobileView} = props;

  return (
    <main className="flex-1 border-t border-gray-200">
      {
        hidePaymentModalOpen ?
          <ConfirmationModal {...hidePaymentModalProps} /> : null
      }
      <div className="flex-1 max-h-screen overflow-y-auto pb-16">
        <div className={`${!isMobileView ? "max-w-3xl mx-auto sm:px-6 lg:py-12 lg:px-8" : "pt-20"} py-10 px-4`}>
          <div className="mt-6">
            <div className="divide-y divide-gray-200">
              <div className="space-y-1">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  History
                </h3>
              </div>
              <div className="mt-6">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Date
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Description
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Amount
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Status
                      </th>
                      <th
                        scope="col"
                        aria-label=" "
                        className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      />
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {renderPaymentHistory()}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default PurchaseHistoryView;
