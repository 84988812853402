import React from 'react';

import { Avatar, MessageHistoryResponse, MsgChoice, User } from '../../../interfaces';

import { ConnectionStatus } from '../../../reducers/websocket';
import { ConfirmationModalProps } from '../../../components/ConfirmationModal/ConfirmationModal';

interface ChoicepickerViewProps {
  user: User;
  msgHistory: MessageHistoryResponse;
  activeAvatar: Avatar;
  connectionStatus: ConnectionStatus;
  scrollRef: React.RefObject<HTMLInputElement>;
  isFetching: boolean;
  sendMessage: (msgChoice: MsgChoice, msgChoicesHeight: number | undefined) => void;
  initialBailoutProps: ConfirmationModalProps
}

const ChoicepickerView: React.FC<ChoicepickerViewProps> = (
  props: ChoicepickerViewProps
) => {
  const {
    user,
    msgHistory,
    activeAvatar,
    connectionStatus,
    scrollRef,
    isFetching,
    sendMessage,
    initialBailoutProps
  } = props;

  return msgHistory &&
    activeAvatar &&
    msgHistory[activeAvatar.id] &&
    msgHistory[activeAvatar.id].messageFuture &&
    msgHistory[activeAvatar.id].messageFuture!.length === 0 &&
    msgHistory[activeAvatar.id].msgChoices &&
    msgHistory[activeAvatar.id].msgChoices.length > 0 &&
    msgHistory[activeAvatar.id].isMsgChoicesShown === true &&
    connectionStatus !== ConnectionStatus.DISCONNECTED ? (
    <div className="choice-container" ref={scrollRef}>
      <div id="msg-choices" className="choice-area">
        <div className="choice-interactive choice-multiple-choice">
          <ul>
            {msgHistory[activeAvatar.id].msgChoices.map(ch => (
              <li key={ch.msgChoiceId || ch.text}>
                <button
                  id="msgChoiceButton"
                  disabled={isFetching}
                  onClick={() => sendMessage(ch, document.getElementById('msg-choices')?.clientHeight)}
                  type="button"
                >
                  {ch.text}
                </button>
              </li>
            ))}
          </ul>
        </div>
        {
          // Do not render Let's change topic if the msgChoices array has an icebreaker in it
          !msgHistory[activeAvatar.id].msgChoices.some(el => el.icebreaker) && user?.isRegistered && (
            <button
              type="button"
              id="changeTopicButton"
              onClick={() => initialBailoutProps.open?.() }
              className="choice-bailout opacity-70 text-xs p-1 px-2 rounded-lg transition duration-200 ease-in-out hover:bg-blue-400 hover:opacity-100"
            >
              <span>🙅🏼‍♂️ Let&apos;s change topic &rarr;</span>
            </button>
          )
        }
      </div>
    </div>
  ) : (
    <div ref={scrollRef} className="scrollToHere" />
  );
};

export default ChoicepickerView;
