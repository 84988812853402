/* Category list component for the avatar tags */
import React from 'react';

import { Tag } from '../../../interfaces';

import CategoryItem from './CategoryItem';

interface CategoryProps {
  tags: Array<Tag>
}

const Categories: React.FC<CategoryProps> = (props: CategoryProps) => {
  const { tags } = props;
  return (
    <>
      <h5 className="my-2 border-gray-500 uppercase text-xs font-medium text-gray-500">
        Recommended
      </h5>
      <div className="browse">
        {tags.map(tag => (
          <CategoryItem key={tag.id} tag={tag} />
        ))}
      </div>
    </>
  );
};

export default Categories;
