/* Input form component for signup request */
import React, { useState, useEffect } from 'react';
import { LockClosedIcon } from '@heroicons/react/solid';

import type { RegisterRequest } from '../../interfaces';

import { ModalFormInput } from '../../components';
import { useAppDispatch, useAppSelector } from "../../reducers/hooks";
import { useRegisterMutation, useUpgradeUserMutation } from '../../reducers/auth';
import { setCredentials } from '../../reducers/user';
import { setFriendAvatars, useGetAllAvatarByUserMutation } from '../../reducers/avatar';
import WSApi from '../../services/ws-api-handler/ws-api';

interface FormErrors {
  email?: string
  nickname?: string
  password?: string
  ageConsent?: string
}

interface RegisterFormProps {
  initModalEmail?: string;
  setOpen: (val: boolean) => void;
  setModalType: (val: string) => void;
}

const RegisterForm: React.FC<RegisterFormProps> = (props: RegisterFormProps) => {
  const {
    initModalEmail,
    setOpen,
    setModalType
  } = props;

  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.user);

  const [register, { error: registerError }] = useRegisterMutation();
  const [upgradeUser, { error: updateError }] = useUpgradeUserMutation();
  const [getAvatars] = useGetAllAvatarByUserMutation();

  const passwordInput = React.createRef<HTMLInputElement>();
  
  const [formState, setFormState] = React.useState<RegisterRequest>({
    email: '',
    nickname: '',
    password: ''
  });
  const [ageConsent, setAgeConsent] = React.useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<FormErrors>({});

  useEffect(() => {
    if (registerError && "data" in registerError) {
      const { data } = registerError;
      const errors = (data as {fieldErrors: FormErrors}).fieldErrors;
      if (errors)
        setValidationErrors(errors);
    }
  }, [registerError]);

  useEffect(() => {
    if (updateError && "data" in updateError) {
      const { data } = updateError;
      const errors = (data as {fieldErrors: FormErrors}).fieldErrors;
      if (errors)
        setValidationErrors(errors);
    }
  }, [updateError]);

  React.useEffect(() => {
    if (initModalEmail) {
      setFormState(prev => ({ ...prev, email: initModalEmail}));
      passwordInput.current?.focus();
    }
  }, [initModalEmail]);

  const handleChange = ({
    target: { name, value }
  }: React.ChangeEvent<HTMLInputElement>) =>
    setFormState(prev => ({ ...prev, [name]: value }));

  const validation = () => {
    const errors: FormErrors = {};
    if (formState.email === '') {
      errors.email = 'Email is required.';
    }
    if (formState.nickname === '') {
      errors.nickname = 'Username is required.';
    }
    if (formState.password.length < 6) {
      errors.password = 'The password is too short.';
    }
    if (formState.password === '') {
      errors.password = 'Password is required.';
    }
    if (!ageConsent) {
      errors.ageConsent = 'You must be 18 years old or older to continue.';
    }
    
    setValidationErrors(errors);
    return !Object.keys(errors).length;
  };

  const handleRegister = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      WSApi.ws.disconnect();
      if (!validation())
        return;

      // If the user is anonymous
      const requestUser = {
        email: formState.email.toLowerCase(),
        nickname: formState.nickname,
        password: formState.password
      };
      if (user && !user.isRegistered) {
        const responseUser = await upgradeUser(requestUser).unwrap();
        dispatch(setCredentials(responseUser));
      } else {
        const responseUser = await register(requestUser).unwrap();
        dispatch(setCredentials(responseUser));
      }
      WSApi.ws.connect();
      setOpen(false);
      getFriendAvatarsLocal();
    } catch (err) {
      setFormState(prev => ({ ...prev, password: ''}));
    }
  };

  const getFriendAvatarsLocal = async () => {
    const avatars = await getAvatars(null).unwrap();

    dispatch(setFriendAvatars(avatars));
  };

  return (
    <form onSubmit={handleRegister} className="mt-8 space-y-6" id="registerModal">
      <ModalFormInput
        label="Email address"
        name="email"
        required
        value={formState.email}
        handleChange={handleChange}
        autoComplete="email"
        placeHolder="Enter your email address"
        error={validationErrors.email}
      />
      <ModalFormInput
        label="Username"
        name="nickname"
        required
        value={formState.nickname}
        handleChange={handleChange}
        autoComplete="nickanme"
        placeHolder="Enter your username"
        error={validationErrors.nickname}
      />

      <ModalFormInput
        label="Password"
        name="password"
        required
        value={formState.password}
        inputRef={passwordInput}
        handleChange={handleChange}
        autoComplete="current-password"
        placeHolder="Enter your password"
        error={validationErrors.password}
      />

      <div>
        <div className="flex items-center">
          <input
            id="age-consent"
            name="age-consent"
            type="checkbox"
            checked={ageConsent}
            onChange={() => setAgeConsent(!ageConsent)}
            className={`h-4 w-4 text-blue-600 focus:ring-blue-500 ${validationErrors.ageConsent ? "border-red-600" : "border-gray-300"} rounded`}
          />
          <label htmlFor="age-consent" className="ml-2 block text-sm text-gray-900"> I am 18 years old or older.</label>
        </div>
        {validationErrors.ageConsent && <div className="mt-0 pt-0 text-sm text-red-600">{validationErrors.ageConsent}</div> }
      </div>

 
      <div className="mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
        <button
          id="registerModalButton"
          type="submit"
          className="group relative w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:col-start-2"
        >
          <span className="absolute left-0 inset-y-0 flex items-center pl-3">
            <LockClosedIcon
              className="h-5 w-5 text-blue-400 group-hover:text-blue-400"
              aria-hidden="true"
            />
          </span>
          Register
        </button>
        <button
          onClick={() => setModalType('login')}
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:col-start-1"
        >
          Sign in
        </button>
      </div>
    </form>
  );
};

export default RegisterForm;
