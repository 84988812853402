import React, { useContext, useEffect, useState } from 'react';

import { DiscoverState, Avatar } from '../../interfaces';

import { WithMobileDetectionProps, LoadingIndicator } from '../../components';
import { useAppDispatch, useAppSelector } from "../../reducers/hooks";
import { useGetAvatarTagsQuery, useGetAllDiscoverMutation } from '../../reducers/avatar';
import { setPaymentModalOpen } from '../../reducers/payment';

import { MainContext } from '../Main/Main';
import Recommended from './_Recommended';
import DiscoverView from './DiscoverView';

export interface DiscoverProps extends WithMobileDetectionProps {
  screenViewType: string;
}

const Discover: React.FC<DiscoverProps> = (props: DiscoverProps) => {
  const { isMobileView } = props;
  const { user, isPaymentModalOpen, paymentType } = useAppSelector((state) => ({
    user: state.user.user,
    isPaymentModalOpen: state.payment.isPaymentModalOpen,
    paymentType: state.payment.paymentType
  }));

  const [getAvatars, {isLoading}] = useGetAllDiscoverMutation();
  const [avatarState, setAvatarState] = useState<DiscoverState>({
    avatars: [],
    isLoaded: []
  });
  const {data: tags} = useGetAvatarTagsQuery();


  const {
    setModalTypeMain: setModalType,
    setOpenMain: setOpen,
  } = useContext(MainContext);

  const [shouldOpenPaymentModal, setShouldOpenPaymentModal] = useState(false);

  const dispatch = useAppDispatch();
  const setPaymentModalOpenState = (isOpen: boolean) => {
    dispatch(setPaymentModalOpen(isOpen));
  };

  const setAvatars = async (avatars? : Array<Avatar>) => {
    if(!avatars) {
      const recommended = await getAvatars(null).unwrap();
 
      // Set recommended avatars to state
      setAvatarState(prev => ({ ...prev, avatars: recommended }));
    } else {
        setAvatarState(prev => ({ ...prev, avatars }));
    }
  };

  useEffect(() => {
    setAvatars(undefined);
  }, []);

  useEffect(() => {
    document.title = "Fantxt";
  }, []);

  const renderRecommended = () => {
    if(isLoading) {
      return (
        <div className="flex flex-1 items-center justify-center">
          <LoadingIndicator />
        </div>
      );
    }
    return <Recommended
      recommendedImages={avatarState.avatars}
    />;

  };

  useEffect(() => {
    if(user?.isRegistered && shouldOpenPaymentModal) {
      setPaymentModalOpenState(true);
      setShouldOpenPaymentModal(false);
    }
  }, [user?.isRegistered]);

  return (
    <DiscoverView
      isMobileView={isMobileView}
      featuredImages={[]}
      tags={tags|| []}
      isPaymentModalOpen={isPaymentModalOpen}
      setPaymentModalOpen={setPaymentModalOpenState}
      renderRecommended={renderRecommended}
      paymentType={paymentType}
      setOpen={setOpen}
      setModalType={setModalType}
      setShouldOpenPaymentModal={setShouldOpenPaymentModal}
    />
  );
};

export default Discover;
