import React from 'react';
import { useHistory } from 'react-router-dom';

import NotFoundView from './NotFoundView';

const NotFound: React.FC = () => {
  const history = useHistory();

  const navigateToDiscover = () => {
    history.push('/discover');
  };

  return (
    <NotFoundView
      navigateToDiscover={navigateToDiscover}
    />
  );
};

export default NotFound;
