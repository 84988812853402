import moment from "moment";

export default class UtilsService {
    static classNames = (...classes: Array<string>): string => (classes.filter(Boolean).join(' '));

    static keysToSnakeCase = (input: {[key: string]: string | number }): {[key: string]: string | number } => {
        const keys = Object.keys(input);
        const output = {} as {[key: string]: string | number };
        keys.forEach((key) => {
            const normalizedKey = key.normalize().replace(/\u0130/g, 'I');
            output[normalizedKey.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)] = input[key];
        });
        return output;
    }

    static processDisplayTime = (displayTime: string): moment.Moment => {
      if (displayTime[displayTime.length -1] === 'Z') {
        displayTime = displayTime.slice(0, -1);
      }
      const formattedDisplayTime: string = moment(displayTime).format();
      let datetime = '';
      if (formattedDisplayTime[19] === '-' || formattedDisplayTime[19] === '+') {
        datetime = formattedDisplayTime.slice(0, -6);
      }
      const finalDisplayTime = `${datetime}+00:00`;
      return moment(moment(finalDisplayTime).format());
    };
    
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
    static bufferToBase64 = (buf: any): any => {
        if(buf) {
            const binstr = Array.prototype.map
          .call(buf, ch => String.fromCharCode(ch))
          .join('');
        return btoa(binstr);
        }
        return null;
      }

    static listToQuery: (querayParamName: string, list: Array<string | number>) =>
        string = (querayParamName: string, list: Array<string | number>) => {
      if (!querayParamName || !list || list.length === 0) {
        return '';
      }

      let query = `?${querayParamName}=${list[0]}`;

      if (list.length > 1) {
        for (let i=1; i<list.length; i+=1) {
          query += `&${querayParamName}=${list[i]}`;
        }
      }

      return query;
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    static checkIfMediaExists = async (imgUrl: string) => {
      if (!imgUrl) {
          return false;
      }
      return new Promise(res => {
          const image = new Image();
          image.onload = () => res(true);
          image.onerror = () => res(false);
          image.src = imgUrl;
      });
    }

    // if any element of array string is contained by text return true else false
    static arrayIncludesLikeString: (array: Array<string>, text: string) => boolean =
        (array: Array<string>, text: string) => {
      const includesArray = array.filter(t => text.includes(t));

      const includes = includesArray && includesArray.length > 0;
    
      return includes;
    };
}
