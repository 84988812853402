/* Modal component for content unlock functionality */
import React from 'react';

interface SkipWaitMessagePillProps {
  avatarName: string;
  handleSkipPillButtonClick: () => void;
  price?: number;
}

const SkipWaitMessagePill: React.FC<SkipWaitMessagePillProps> = (
  props: SkipWaitMessagePillProps
) => {
  const { avatarName, handleSkipPillButtonClick, price} = props;
  const reference = React.createRef<HTMLInputElement>();

  React.useEffect(() => {
    if (reference && reference.current) {
      reference.current.scrollIntoView({
        behavior: 'auto',
        block: 'start'
      });
    }
  }, [reference]);

  return (
    <div ref={reference} className="aooch-notification">
      <p>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0M10.5 8.25h3l-3 4.5h3"
          />
        </svg>
        <span>
          Waiting too long?{' '}
          <button type="button" onClick={handleSkipPillButtonClick} className="underline">
            Call {avatarName} back for <b>{price || 10}</b> credits
          </button>
          .
        </span>
      </p>
    </div>
  );
};

export default SkipWaitMessagePill;
