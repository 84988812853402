/* Header component to display additional information on a payment modal */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';

import { PaymentType } from '../../interfaces';

interface PaymentElementProps {
  paymentType: PaymentType;
  setPaymentType: (paymentType: PaymentType) => void;
  setOpen: (open: boolean) => void;
}

const PaymentModalHeader: React.FC<PaymentElementProps> = (props: PaymentElementProps) => {
  const { paymentType, setPaymentType, setOpen } = props;

  const renderHeader = () => {
    if (paymentType === PaymentType.CREDIT) {
      return (
        <div>
          <h2 className="text-center text-xl sm:text-2xl font-bold text-gray-900">Get credits</h2>
          <p className="mt-2 mb-6 text-center text-lg text-gray-600">
            Save even more with
            <a onClick={() => setPaymentType(PaymentType.SUBSCRIPTION)} className="font-medium text-blue-600 cursor-pointer"> credit subscriptions </a>
            or choose an option
          </p>
        </div>
      );
    }
    return (
      <div>
        <h2 className="text-center text-xl sm:text-2xl font-bold text-gray-900">Credit subscription</h2>
        <p className="mt-2 mb-6 text-center text-lg text-gray-600">
          Choose your monthly credit subscription or
          <a onClick={() => setPaymentType(PaymentType.CREDIT)} className="font-medium text-blue-600 cursor-pointer"> buy credits </a>
          individually
        </p>
      </div>
    );
  };

  return (
    <div className="relative">
      <div className="absolute top-0 right-0">
        <button type="button" onClick={() => setOpen(false)} className="p-2 rounded-full bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500">
          <span className="sr-only">Cancel</span>
          {/* Heroicon name: outline/x */}
          <svg className="h-4 w-4 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
      {renderHeader()}
    </div>
  );
};

export default PaymentModalHeader;
