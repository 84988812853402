import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PURGE } from "redux-persist";

import type { User, UserResponse } from '../../interfaces';

import type { RootState } from '../store';

type UserState = {
  user: User | null
  refreshToken: string | null
  accessToken: string | null
  hasUserVisitedPage: boolean
  hasAvatarFriends: boolean
  nodeInteractionCounter: number
};

const initialState : UserState = {
  user: null,
  refreshToken: null,
  accessToken: null,
  hasUserVisitedPage: false,
  hasAvatarFriends: false,
  nodeInteractionCounter: 0
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setCredentials: (
      state,
      { payload: { user, accessToken, refreshToken } }: PayloadAction<UserResponse>
    ) => {
      state.user = user;
      state.accessToken = accessToken;
      state.refreshToken = refreshToken;
      try {
        localStorage.setItem('isUserSignedIn', 'true');
      } catch {
        console.warn('Cookies are blocked!');
      }
    },
    updateToken: (
      state,
      { payload }: PayloadAction<string>
    ) => {
      state.refreshToken = payload;
    },
    updateBothTokens: (
      state,
      { payload }: PayloadAction<{access: string; refresh: string;}>
    ) => {
      state.accessToken = payload.access;
      state.refreshToken = payload.refresh;
    },
    setVisit: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.hasUserVisitedPage = payload;
    },
    setAvatarFriends: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.hasAvatarFriends = payload;
    },
    incrementNodeInteractionCounter: (state) => {
      state.nodeInteractionCounter += 1;
    },
    setNodeInteractionCounter: (state, {payload} :  PayloadAction<number>) => {
      state.nodeInteractionCounter = payload;
    },
    setUserProfileInfo: (state, {payload} : PayloadAction<User>) => {
      state.user = payload;
      try {
        localStorage.setItem('isUserSignedIn', 'true');
      } catch {
        console.warn('Cookies are blocked!');
      }
    },
    updateCredits: (state, {payload} : PayloadAction<number>) => {
      if(state.user)
        state.user.credits = payload;
    },
    updateProfilePicture: (state, {payload} : PayloadAction<string>) => {
      if(state.user)
        state.user.profilePicture = payload;
    },
    setModifiedUserData: (
      state,
      { payload }: PayloadAction<User>
    ) => {
      state.user = payload;
    },
  },
  extraReducers: (builder) => {
    const tempState = {
      user: null,
      refreshToken: null,
      accessToken: null,
      hasUserVisitedPage: true,
      hasAvatarFriends: false,
      nodeInteractionCounter: 0
    };
    builder.addCase(PURGE, () => tempState);
}
});


export const {
  setCredentials,
  updateToken,
  updateBothTokens,
  setUserProfileInfo,
  setNodeInteractionCounter,
  incrementNodeInteractionCounter,
  setModifiedUserData,
  updateProfilePicture,
  setVisit,
  setAvatarFriends,
  updateCredits
} = slice.actions;

export const selectCurrentUser = (state: RootState): User | null => state.user.user;

export default slice.reducer;
