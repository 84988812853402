import 'babel-polyfill';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import * as dotenv from 'dotenv';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { PersistGate } from 'redux-persist/integration/react';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import './stylesheets/app.css';
import { store, persistor } from './reducers/store';
import routes from './routes';

import UtilsService from './services/utils';

// initialize dotenv
dotenv.config();
const history = createBrowserHistory();

const ignoreErrors = [
  'The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission.',
  'NotAllowedError: play() can only be initiated by a user gesture.',
  'NotAllowedError: play() failed because the user didn\'t interact with the document first.',
  'ResizeObserver loop limit exceeded',
  'NotSupportedError: The operation is not supported.',
  'AbortError: The play() request was interrupted by a call to pause().',
  'The play method is not allowed by the user agent or the platform in the',
  'The play method is not allowed by the user agent or the platform in the current context, possibly because the user denied permission.',
  "Failed to read the 'localStorage' property from 'Window': Access is denied for this document.",
  "Cannot read properties of null (reading 'setItem')"
];

if (process.env.REACT_APP_SENTRY_KEY) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_KEY,
    integrations: [new Integrations.BrowserTracing()],
    beforeSend(event) {
      if (
        (event &&
        event.message &&
        UtilsService.arrayIncludesLikeString(ignoreErrors, event.message)) ||
        (event &&
        event.exception &&
        event.exception.values &&
        event.exception.values[0]
        && event.exception.values[0].value &&
        UtilsService.arrayIncludesLikeString(ignoreErrors, event.exception.values[0].value))
      ){
        return null;
      }
      
      return event;
    },
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router history={history}>{routes}</Router>
    </PersistGate>
  </Provider>,
  // eslint-disable-next-line no-undef
  document.getElementById('chatmate-app')
);
