import React from 'react';

import { FeaturedImage } from '../../../interfaces';

import DiscoverAvatarImage from '../_DiscoverAvatarImage';

interface FeaturedViewProps {
  featuredImages?: Array<FeaturedImage>;
  setModalType: (val:string) => void;
  setOpen: (val: boolean) => void;
  setShouldOpenPaymentModal: (val: boolean) => void;
}

const FeaturedView: React.FC<FeaturedViewProps> = (
  props: FeaturedViewProps
) => {
  const { featuredImages, setModalType, setOpen, setShouldOpenPaymentModal} = props;

  return (featuredImages && featuredImages.length > 0)
    ? <DiscoverAvatarImage featuredImages={featuredImages} featured
    setModalType={setModalType}
    setShouldOpenPaymentModal={setShouldOpenPaymentModal}
    setOpen={setOpen}/> : null;
};

export default FeaturedView;
