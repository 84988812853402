import React from 'react';

import { Avatar, MessageHistoryResponse, User } from '../../../interfaces';

import { LoginSignupFloater, ConfirmationModal, ConfirmationModalProps } from '../../../components';
import { ConnectionStatus } from '../../../reducers/websocket';

import OutOfChatsChoice from './OutOfChatsChoice';
import ChoicePicker from './Choicepicker';
import MessageContainer from './Messagecontainer';

interface ChatformViewProps {
  msgHistory: MessageHistoryResponse;
  activeAvatar: Avatar;
  user: User;
  currentReferenceId: string;
  addToLoadedMediaCount: () => void;
  setContentModalOpen: (val: boolean) => void;
  setContentNodeId: (val: number) => void;
  setContentCreditCost: (val: number) => void;
  setCurrentReference: (reference: string | undefined) => Promise<void>;
  setModalType: (val: string) => void;
  setOpen: (val: boolean) => void;
  handleMediaClick: (url: string, setCurrentReference?: (refId:string) => void, refId?:string) => void;
  renderTypingIndicator: () => JSX.Element;
  scrollRef: React.RefObject<HTMLInputElement>;
  isMobileView: boolean;
  renderChatformPadding: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  chatDivRef: React.MutableRefObject<any>;
  divHeight: number;
  connectionStatus: ConnectionStatus;
  accessToken: string;
  isFetching: boolean;
  subscribed: boolean;
  setSubscribed: (val: boolean) => void;
  handleRegisterClick: () => void;
  nodeInteractionCounter: number;
  handleSkipPillButtonClick: (nodeId?: number, skipPrice?: number) => void;
  isLastNodeLocked: () => boolean;
  unlockPayWall: () => void;
  isBailoutConfirmationOpen: boolean;
  initialBailoutProps: ConfirmationModalProps,
  openMediaViewer: boolean
}

const ChatformView: React.FC<ChatformViewProps> = (
  props: ChatformViewProps
) => {
  const {
    msgHistory,
    activeAvatar,
    user,
    currentReferenceId,
    addToLoadedMediaCount,
    setContentModalOpen,
    setContentNodeId,
    setContentCreditCost,
    setCurrentReference,
    setModalType,
    setOpen,
    handleMediaClick,
    renderTypingIndicator,
    scrollRef,
    isMobileView,
    renderChatformPadding,
    chatDivRef,
    divHeight,
    connectionStatus,
    accessToken,
    isFetching,
    subscribed,
    setSubscribed,
    handleRegisterClick,
    nodeInteractionCounter,
    handleSkipPillButtonClick,
    isLastNodeLocked,
    unlockPayWall,
    isBailoutConfirmationOpen,
    initialBailoutProps,
    openMediaViewer
  } = props;

  return (
    <div
      id="messages"
      className={`flex flex-1 flex-col ${isMobileView && "pt-20 pb-16 h-full"} ${renderChatformPadding()} pt-3 pr-3 pl-3 lg:p-3 md:p-3 xl:p-3 ${!isMobileView && "overflow-y-auto "} scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touch`}
    >
      {isBailoutConfirmationOpen ? (
        <ConfirmationModal {...initialBailoutProps} />
      ) : null}
      {!isMobileView && msgHistory && activeAvatar && user && !user.isRegistered ? (
        <LoginSignupFloater
          avatarName={activeAvatar.avatarName}
          setModalType={setModalType}
          setOpen={setOpen}
          isMobileView={isMobileView}
        />
       ) : null}
      <div ref={chatDivRef} className={`mt-auto ${isMobileView && (divHeight + 100 > window.innerHeight) ? "pb-16" : "pb-0"}`}>
        {msgHistory && activeAvatar && msgHistory[activeAvatar.id]
          ? msgHistory[activeAvatar.id].messageHistory.map((item, index) => (
              <MessageContainer
                key={item.displayTime}
                isScrollTo={
                  currentReferenceId === item.displayTime
                }
                openMediaViewer={openMediaViewer}
                actionAfterMediaLoaded={addToLoadedMediaCount}
                node={item}
                idx={index}
                activeAvatar={activeAvatar}
                setContentModalOpen={setContentModalOpen}
                setContentNodeId={setContentNodeId}
                setContentCreditCost={setContentCreditCost}
                setCurrentReference={setCurrentReference}
                fullChatLength={
                  msgHistory[activeAvatar.id].messageHistory.length
                }
                handleSkipPillButtonClick={handleSkipPillButtonClick}
                user={user}
                handleMediaClick={handleMediaClick}
                subscribed={subscribed}
                handleRegisterClick={handleRegisterClick}
                isTyping={msgHistory &&
                  activeAvatar &&
                  msgHistory[activeAvatar.id] &&
                  msgHistory[activeAvatar.id].isTyping === true}
                initialBailoutProps={initialBailoutProps}
                unlockPayWall={unlockPayWall}
                payWallUnlockPrice={
                  msgHistory[activeAvatar.id].messageHistory![ msgHistory[activeAvatar.id].messageHistory?.length - 1].skipPrice || 10
                }
                payWallText={
                  msgHistory[activeAvatar.id].messageHistory![ msgHistory[activeAvatar.id].messageHistory?.length - 1].text || ""
                }
              />
            ))
          : null}
        {msgHistory &&
        activeAvatar &&
        msgHistory[activeAvatar.id] &&
        msgHistory[activeAvatar.id].isTyping === true
          ? renderTypingIndicator()
          : null}
        <ChoicePicker
          user={user}
          scrollRef={scrollRef}
          isMobileView={isMobileView}
          activeAvatar={activeAvatar}
          msgHistory={msgHistory}
          connectionStatus={connectionStatus}
          accessToken={accessToken}
          isFetching={isFetching}
          setModalType={setModalType}
          setOpen={setOpen}
          nodeInteractionCounter={nodeInteractionCounter}
          initialBailoutProps={initialBailoutProps}
        />
        <OutOfChatsChoice
          subscribed={subscribed}
          setSubscribed={setSubscribed}
          activeAvatar={activeAvatar}
          msgHistory={msgHistory}
          connectionStatus={connectionStatus}
          isLastNodeLocked={isLastNodeLocked}
        />
      </div>
    </div>
  );
};

export default ChatformView;
