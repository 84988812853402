/* Avatar link component displayed in the featured section of the discover page */
import React from 'react';

import { FeaturedImage } from '../../../interfaces';

import FeaturedView from './FeaturedView';

export interface FeaturedProps {
  featuredImages?: Array<FeaturedImage>;
  setModalType: (val:string) => void;
  setOpen: (val: boolean) => void;
  setShouldOpenPaymentModal: (val: boolean) => void;
}

const Featured: React.FC<FeaturedProps> = ( props: FeaturedProps) => {
  const {featuredImages, setModalType, setOpen, setShouldOpenPaymentModal} = props;
  return (
    <FeaturedView featuredImages={featuredImages}
    setShouldOpenPaymentModal={setShouldOpenPaymentModal}
    setModalType={setModalType}
    setOpen={setOpen}/>
  );
};

export default Featured;