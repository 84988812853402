/* Message node component to display message details */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';

import { User, Avatar, MessageNode, NextNodeType, SenderType } from '../../../interfaces';

import { ProfilePicture, MediaHandler, ConfirmationModalProps } from '../../../components';

import LockedNode from './LockedNode';
import MessageDisplayTime from './MessageDisplayTime';
import NewChatNotification from './NewChatNotification';
import SkipWaitMessagePill from './SkipWaitMessagePill';

interface MessageContainerProps {
  node: MessageNode;
  isScrollTo: boolean;
  actionAfterMediaLoaded: () => void;
  idx: number;
  fullChatLength: number;
  activeAvatar: Avatar;
  user: User;
  setContentModalOpen: (val: boolean) => void;
  setCurrentReference: (ref?: string) => void;
  setContentCreditCost: (val: number) => void;
  setContentNodeId: (val: number) => void;
  handleMediaClick: (url: string, setCurrentReference?: (refId:string) => void, refId?:string) => void;
  subscribed: boolean;
  handleRegisterClick: () => void;
  handleSkipPillButtonClick: (nodeId?: number, skipPrice?: number) => void;
  isTyping: boolean;
  initialBailoutProps: ConfirmationModalProps;
  unlockPayWall: () => void;
  payWallUnlockPrice: number;
  payWallText: string;
  openMediaViewer: boolean;
}

const MessageContainer: React.FC<MessageContainerProps> = (
  props: MessageContainerProps
) => {
  const {
    node,
    idx,
    fullChatLength,
    activeAvatar,
    user,
    isScrollTo,
    actionAfterMediaLoaded,
    setContentModalOpen,
    setCurrentReference,
    setContentCreditCost,
    setContentNodeId,
    handleMediaClick,
    subscribed,
    handleRegisterClick,
    handleSkipPillButtonClick,
    isTyping,
    initialBailoutProps,
    unlockPayWall,
    payWallUnlockPrice,
    payWallText,
    openMediaViewer
  } = props;
  const isUser = node.sender === SenderType.USER;
  const isAvatar = node.sender === SenderType.AVATAR;
  const isSkipDelayNode = node.text === 'Skip the wait';
  const isFriendAddedNode = node.sender === 'SYSTEM' && node.text.includes("You became friends with");

  const { nextNodeType } = node;
  const reference = React.createRef<HTMLInputElement>();

  React.useEffect(() => {
    if (reference && reference.current && isScrollTo) {
      reference.current.scrollIntoView({
        behavior: 'auto',
        block: 'start'
      });
      if (!openMediaViewer) {
        setCurrentReference(undefined);
      }
    }
  }, [reference]);

  const defineChatClassNames = () => {
    let classNames = '';

    if (isUser) {
      classNames += 'response-text';
      if (
        nextNodeType === NextNodeType.USER_NODE &&
        idx !== fullChatLength - 1
      ) {
        classNames += ' chained';
      }
    } else {
      classNames += 'message-text';
      if (node.mediaFile) classNames += ' w-full';
      if (
        nextNodeType === NextNodeType.AVATAR_NODE &&
        idx !== fullChatLength - 1
      ) {
        classNames += ' chained';
      }
    }

    return classNames;
  };

  if(node.isLockedNode && node.nextNodeType !== "FINISHED") {
    return (
      <LockedNode
        handleUnlock={unlockPayWall}
        initialBailoutProps={initialBailoutProps}
        payWallUnlockPrice={payWallUnlockPrice}
        payWallText={payWallText}
        />
    );
  }

  if('isLockedNode' in node && (!node.isLockedNode || node.nextNodeType === "FINISHED")) return null;

  // Rendering user messages.
  if (isUser) {
    return (
      <div className="response-message">
        <div
          className={`${
            !defineChatClassNames().includes('chained') && 'mb-2'
          } response-container overflow-y-hidden`}
        >
          <div className="group response-area focus:outline-none" tabIndex={0}>
            <span className={defineChatClassNames()}>{node.text}</span>
            <MessageDisplayTime displayTime={node.displayTime} />
          </div>
          <ProfilePicture
            name={{ firstName: user.firstName, lastName: user.lastName, nickname: user.nickname }}
            profilePicture={user.profilePicture || ''}
          />
        </div>
      </div>
    );
  }

  if(isFriendAddedNode) {
    return (
        <div className="aooch-notification pb-6">
          <p>
            {node.text}
          </p>
        </div>
    );
  }

  if(isSkipDelayNode) {
    if(isTyping)
      return null;

    return (
      <SkipWaitMessagePill avatarName={activeAvatar.avatarName} price={node.skipPrice} handleSkipPillButtonClick={() => handleSkipPillButtonClick(node.nodeId, node.skipPrice)}/>
    );
  }

  if (isAvatar) {
    return (
      <div ref={reference} className="node-message">
        <div
          className={`${
            !defineChatClassNames().includes('chained') && 'mb-2'
          } message-container overflow-y-hidden`}
        >
          <div
            className={`group message-area focus:outline-none ${
              node.mediaFile ? 'w-full' : ''
            }`}
            tabIndex={0}
          >
            <span className={defineChatClassNames()}>
              {node.text}
              {node.mediaFile && (
                <MediaHandler
                  nodeId={node.nodeId}
                  actionAfterMediaLoaded={actionAfterMediaLoaded}
                  referenceId={node.displayTime}
                  mediaFile={node.mediaFile}
                  locked={'isLocked' in node}
                  available={!node.isLocked}
                  requiredCredits={node.creditCost}
                  setContentModalOpen={setContentModalOpen}
                  setCurrentReference={setCurrentReference}
                  setContentNodeId={setContentNodeId}
                  setContentCreditCost={setContentCreditCost}
                  handleMediaClick={handleMediaClick}
                  inChat
                  isUserTemp={user?.isRegistered === false}
                  handleRegisterClick={handleRegisterClick}
                  userEmail={user.email}
                />
              )}
            </span>
            <MessageDisplayTime displayTime={node.displayTime} />
          </div>
          <img
            src={activeAvatar.avatarPicture}
            alt={activeAvatar.avatarName}
            className="message-avatar object-cover"
          />
        </div>
      </div>
    );
  }

  if (subscribed)
    return <NewChatNotification nodeText={node.text}/>;

  return null;
};

export default MessageContainer;
