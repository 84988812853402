import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from "../../reducers/hooks";
import {
  setFriendAvatars,
  useAddFriendMutation,
  useAddFriendTempMutation,
  useGetAllAvatarByUserMutation
} from '../../reducers/avatar';
import { setCredentials, setVisit } from '../../reducers/user';

import handleByCampaign from "./config";


function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const AddFriend: React.FC= () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.user);

  const [addFriend] = useAddFriendMutation();
  const [addFriendTemp] = useAddFriendTempMutation();
  const [getAvatars] = useGetAllAvatarByUserMutation();
  const [addFriendFinished,setAddFriendFinished] = useState(false);
  const [tmpUserCreated, setTmpUserCreated] = useState(false);

  const query = useQuery();

  const addFriendLocal = async (handle: string) => {
    let response = null;
    if (user) {
      response = await addFriend(handle);
      setAddFriendFinished(true);
      getAvatarFriends();
    } else {
      const referrer = query.get("referrer") || document.referrer;
      response = await addFriendTemp({handle, referrer, campaign: query.get("campaign")}).unwrap();
      if (response.user) {
        dispatch(setVisit(true));
        dispatch(setCredentials({
          accessToken: response.accessToken,
          refreshToken: response.refreshToken,
          user: response.user
        }));
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if((response as any).handle) {
          setAddFriendFinished(true);
        } else {
          setTmpUserCreated(true);
        }
        getAvatarFriends();
      } else {
        history.push(`${process.env.REACT_APP_LANDINGPAGE_URL}`);
      }
    }
  };

  const getAvatarFriends = async () => {
    const avatars = await getAvatars(null).unwrap();
    dispatch(setFriendAvatars(avatars));
  };

  React.useEffect(() => {
    const handle = query.get("q") as string;
    const campaign = query.get("campaign") as string;
    if (!handle && campaign in handleByCampaign) {
      let url = `/add-friend?q=${handleByCampaign[campaign]}&campaign=${campaign}`;
      if (document.referrer)
        url = url.concat(`&referrer=${document.referrer}`);
      window.location.href = url;
    } else {
      addFriendLocal(handle);
    }
  }, []);

  React.useEffect(() => {
    const handle = query.get("q") as string;
    const finalEmail = user?.isRegistered ? undefined : query.get("email");
    const finalType = user?.isRegistered ? undefined : query.get("action");

    if(user && tmpUserCreated) {
      history.push('/discover');
    }

    if (user && addFriendFinished) {
      history.push(`/chat/${query.get("q")}`, {
        render: true,
        handle,
        email: finalEmail,
        type: finalType,
        openChat: !!((location && location.state && location.state.openChat)),
        chatId: query.get("chatId"),
      });
    }
  }, [user, tmpUserCreated, addFriendFinished, location.state]);

  return null;
};

export default AddFriend;
