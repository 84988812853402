import React from 'react';

interface MainIconProps {
  className?: string;
}

const MainIcon: React.FC<MainIconProps> = (props: MainIconProps) => {
  const { className } = props;
  return (
    <svg
      fill="none"
      height="41"
      viewBox="0 0 35 41"
      width="35"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g fill="#fff">
        <path d="m16.9668 8.3847c.1678-.51293.8986-.51293 1.0664 0l1.8583 5.6797c.075.2294.2903.3847.5332.3847h6.0136c.5431 0 .7689.6902.3295 1.0072l-4.8651 3.5102c-.1965.1418-.2787.3931-.2037.6225l1.8584 5.6797c.1678.5129-.4234.9395-.8628.6224l-4.8651-3.5102c-.1965-.1418-.4625-.1418-.659 0l-4.8651 3.5102c-.4394.3171-1.0306-.1095-.8628-.6224l1.8583-5.6797c.0751-.2294-.0071-.4807-.2036-.6225l-4.86513-3.5102c-.43937-.317-.21356-1.0072.32953-1.0072h6.0136c.2429 0 .4581-.1553.5332-.3847z" />
        <path
          clipRule="evenodd"
          d="m10.2083 33.4132c-6.02443-2.765-10.2083-8.8505-10.2083-15.9132 0-9.66498 7.83502-17.5 17.5-17.5 9.665 0 17.5 7.83502 17.5 17.5 0 9.6157-7.7553 17.42-17.3523 17.4994l-7.4394 5.9515zm-7.29163-15.9132c0-8.05415 6.52918-14.58333 14.58333-14.58333 8.0542 0 14.5833 6.52918 14.5833 14.58333 0 7.5802-5.7834 13.8097-13.1784 14.5165 0 0-.6757.0668-1.4049.0668s-.846-.0241-.846-.0241l-3.529 2.8232v-3.4667c-5.91661-1.8583-10.20833-7.3858-10.20833-13.9157z"
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
};

export default MainIcon;
