import type {
  GeneralResponse,
  UserResponse,
  LoginRequest,
  RegisterRequest,
  ResetPasswordRequest,
  JoinRequest,
  JoinResponse,
} from '../../interfaces';

import baseApi from '../api';

const auth = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<UserResponse, LoginRequest>({
      query: (credentials) => ({
        url: '/auth/login/',
        method: 'POST',
        body: credentials,
      }),
    }),
    register: builder.mutation<UserResponse, RegisterRequest>({
      query: (userData) => ({
        url: '/auth/register/',
        method: 'POST',
        body: userData,
      }),
    }),
    upgradeUser: builder.mutation<UserResponse, RegisterRequest>({
      query: (userData) => ({
        url: '/auth/user/upgrade/',
        method: 'POST',
        body: userData,
      }),
    }),
    join: builder.mutation<JoinResponse, JoinRequest>({
      query: (data) => ({
        url: '/auth/join/',
        method: 'POST',
        body: data,
      }),
    }),
    forgotPassword: builder.mutation<GeneralResponse, string>({
      query: (email) => ({
        url: '/auth/password/reset/',
        method: 'POST',
        body: {email},
      }),
    }),
    resetPassword: builder.mutation<UserResponse, ResetPasswordRequest>({
      query: (credentials) => ({
        url: '/auth/password/reset/confirm/',
        method: 'POST',
        body: credentials,
      })
    }),
  }),
  overrideExisting: false,
});

export const {
  useLoginMutation,
  useRegisterMutation,
  useUpgradeUserMutation,
  useJoinMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
} = auth;
