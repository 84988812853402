/* Component to display images and handle unlock logic */
import React, { useEffect, useState } from 'react';
import * as Sentry from "@sentry/react";

import { useGetPresignedUrlMutation } from '../../reducers/base';

interface ImageHandler {
  locked?: boolean;
  actionAfterMediaLoaded?: () => void;
  available?: boolean;
  bucketImage?: string | null;
  requiredCredits?: number;
  nodeId?: number;
  referenceId?: string;
  inChat?: boolean;
  setContentModalOpen?: (val:boolean) => void;
  setCurrentReference?: (ref:string) => void;
  setContentCreditCost?: (val:number) => void;
  setContentNodeId?: (val:number) => void;
  handleImageClick?: (url: string, setCurrentReference?: (refId:string) => void, refId?:string) => void;
  isUserTemp?: boolean;
  handleRegisterClick?: () => void;
  fromGallery?: boolean;
  userEmail?: string;
}

const ImageHandler: React.FC<ImageHandler> = (props: ImageHandler) => {
  const { isUserTemp, nodeId, referenceId, inChat, locked, available, bucketImage, requiredCredits, actionAfterMediaLoaded,
    setContentModalOpen, setCurrentReference, setContentCreditCost, setContentNodeId, handleImageClick,
    handleRegisterClick, fromGallery, userEmail} = props;
  const [getPresignedUrl] = useGetPresignedUrlMutation();
  const [image, setImage] = useState('');
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    // This variable prevents the state to be updated if the component is unmounted
    let isCancelled = false;

    if (bucketImage) {
      // Splitting string into bucket and image
      (async () => {
        try {
          if (locked) {
            if (available) {
              const response = await getPresignedUrl({
                media: bucketImage
              }).unwrap();

              if(!isCancelled)
                setImage(response.url);
            } else {
              // If the image is locked but the user is not authorized, we return the fetched image.
              // This is the blurred/locked image e.g. riley_locked.jpg
              // Splitting the splittedImage into avatar name and image name
              const replacLockedWithBlurred = bucketImage.replace(
                'locked',
                'blurred'
              );
              setImage(replacLockedWithBlurred);
            }
          } else if(!isCancelled) {
            // If the image is free, return the original image
            setImage(bucketImage);
          }
        } catch (err) {
          console.log(err);
        }
      })();
    }

    return () => {
      isCancelled = true;
    };

  }, [available]);

  const actionOnLoad: () => void = () => {
    setLoading(false);
    if (actionAfterMediaLoaded) {
      actionAfterMediaLoaded();
    }
  };

  const actionOnError: () => void = () => {
    Sentry.captureMessage(`${userEmail} - Cannot load image: ${image}`);
  };

  const onImageClick: () => void = () => {
    if (handleImageClick)
      handleImageClick(bucketImage!, setCurrentReference, referenceId);
  };

  if (bucketImage !== null) {
    if (locked && !available) {
      return (
        <div className={`${!fromGallery && "message-image"} message-ppv`}>
          { image !== '' ?
            <div className={`${fromGallery ? "flex flex-1 w-full h-full justify-center" : "relative"}`}>
              <img src={image} className="flex-1" alt="Error while loading" onLoad={() => actionOnLoad()} onError={() => actionOnError()}/>
              <button
              id="unlockLockedImageButton"
              type="button"
              className="inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 hover:bg-indigo-700
                focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 text-base font-medium text-white focus:outline-none sm:text-sm
                absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-5/6"
                onClick={async () => {
                  try {
                    if(requiredCredits && nodeId) {
                      if(setContentCreditCost) setContentCreditCost(requiredCredits);
                      if(setContentNodeId) setContentNodeId(nodeId);
                    }
                    if (isUserTemp) {
                      if (handleRegisterClick) {handleRegisterClick();}
                      return;
                    }
                    if(setContentModalOpen) setContentModalOpen(true);
                  } catch (err) {
                    console.log(err);
                  }
                }}
              >
              Unlock for {requiredCredits} credits 🔓
            </button>
          </div>
          : (
            <div className="flex flex-1 justify-center">
              <div style={{borderTopColor: "transparent"}} className="w-12 h-12 border-4 border-blue-400 border-solid rounded-full animate-spin" />
           </div>
          )}
        </div>
      );
    }

    return (
      <div className={inChat ? "message-image" : ""}>
        <a role="button" tabIndex={0} onClick={onImageClick} onKeyDown={onImageClick}>
          <img
            src={image}
            className={`h-full w-full max-h-full max-w-full object-cover${isLoading ? " invisible" : ""}`}
            alt="Loading..."
            onLoad={() => actionOnLoad()}
            onError={() => actionOnError()}
          />
        </a>
      </div>
    );
  }
  return null;
};

export default ImageHandler;
