import { persistCombineReducers, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import api from './api';
import user from './user';
import base from './base';
import avatarReducer from './avatar';
import websocket from './websocket';
import payment from './payment';

export const config = {
  key: 'root',
  storage,
  whitelist: ['user', 'payment'],
  timeout: 10000
};

const avatarPersistConfig = {
  key: 'avatar',
  storage,
  whitelist: ['newChatNotifications']
};

const rootReducer = persistCombineReducers(config, {
  [api.reducerPath]: api.reducer,
  user,
  base,
  websocket,
  avatar: persistReducer(avatarPersistConfig, avatarReducer),
  payment
});

export default rootReducer;
