/* Component to display videos and handle unlock logic */
/* ts-lint:disable */
/* eslint-disable */
import React, { useEffect } from 'react';
import videojs from 'video.js';

// Styles
import 'video.js/dist/video-js.css';

interface VideoPlayerProps {
  video: string | null;
  handleSectionClick: () => void;
  isIOS: boolean;
}

const VideoPlayer: React.FC<VideoPlayerProps> = (props: VideoPlayerProps) => {
  const { video, handleSectionClick, isIOS} = props;

  // Set fluid to false, and aspectRatio to undefined, this way the video will fit in the MediaViewer.
  const initialOptions: videojs.PlayerOptions = {
    controls: true,
    autoplay: true,
    fluid: false,
    fill: true,
    controlBar: {
      volumePanel: {
        inline: false
      }
    }
  };

  const videoNode = React.useRef<HTMLVideoElement>(null);

  const player = React.useRef<videojs.Player>();

  const videoSetup = async () => {
    if (!video) {
      return;
    }

    const options = {
      sources: [
        {
          src: isIOS ? video.replace(".m3u8","_1.m3u8") : video,
          type: 'application/x-mpegURL'
        }
      ]
    };
    const x = videojs(document.querySelector(`#videoPlayer1`) as Element, {
      ...initialOptions,
      ...options
    });

    if(!isIOS) {
      //@ts-ignore
      videojs.Vhs.xhr.beforeRequest = (options) => {
          const requestURL = new URL(options.uri);
          const baseURL = new URL(video);
          requestURL.search = baseURL.search;
          options.uri = requestURL.href;
        return options;
      };
    }


    x.on("touchstart", () => {
      handleSectionClick();
    });

    if (!x) {
      return () => {};
    }

    player.current = x;

    return () => {
      if (player.current) {
        player.current.dispose();
      }
    };
  };

  useEffect(() => {
    videoSetup();
  }, [video]);

  if (video) {

    // If the video is being opened in the MediaViewer apply different styling to fit the video to the screen
    return (
     <div className="flex flex-1 justify-center items-center">
      <video
        id="videoPlayer1"
        ref={videoNode}
        className="video-js vjs-default-skin vjs-big-play-centered vjs-show-big-play-button-on-pause"
      />
     </div>
    );
  }
  return null;
};

export default VideoPlayer;
