/* Google Translator integration */
import React, { useEffect } from 'react';

const GoogleTranslator: React.FC = () => {
  /* Function to add style element */
  const addStyle = (styles: string) => {
             
    /* Create style document */
    const css = document.createElement('style');

    css.appendChild(document.createTextNode(styles));
     
    /* Append style to the tag name */
    document.getElementsByTagName("head")[0].appendChild(css);
  };

  const googleTranslateElementInit = () => {
    /* eslint-disable no-new */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    new (window as any).google.translate.TranslateElement(
      {
        pageLanguage: 'en',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        layout: (window as any).google.translate.TranslateElement.InlineLayout.VERTICAL
      },
      'google_translate_element'
    );
  };

  useEffect(() => {
    const addScript = document.createElement('script');
    addScript.setAttribute(
      'src',
      '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
    );
    document.body.appendChild(addScript);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).googleTranslateElementInit = googleTranslateElementInit;

    let styles = '.goog-logo-link {display: none !important;}';
    styles += '.goog-te-banner-frame.skiptranslate {display: none !important;}';
    styles += '.goog-te-gadget {display: inline !important;color: transparent !important;}';
    styles += '.goog-te-combo {color: black !important;}';
    addStyle(styles);
  }, []);

  return (
    <div className="sm:col-span-3" >
      <div id="google_translate_element" className="flex flex-col" />
    </div>
  );
};

export default GoogleTranslator;
