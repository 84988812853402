/* Horizontal navigation bar component used in mobile view */
import React from 'react';

import { User, SidebarNavigationObject } from '../../../interfaces';

import NavBar from './NavBar';

export interface BottomBarProps {
  subNavigation: Array<SidebarNavigationObject>;
  unreadsSum: number;
  user?: User;
  setOpenMain: (val:boolean) => void;
  setModalTypeMain: (val:string) => void;
}
const disabledPages = ['Saved', 'My profile'];

const BottomBar: React.FC<BottomBarProps> = (props: BottomBarProps) => {
  const { subNavigation, user, unreadsSum, setOpenMain, setModalTypeMain } = props;

  return (
    <nav aria-label="Sections" className="block fixed inset-x-0 bottom-0 z-20">
      <div id="tabs" className="flex lg:hidden md:hidden justify-between">
          {subNavigation && subNavigation.length !== 0 &&
            subNavigation.map(item => {
              const isDisabled = (disabledPages.includes(item.name) && !user?.isRegistered) || (item.name === 'Chats' && !user);
              return <NavBar
                key={item.id}
                unreadsSum={unreadsSum}
                item={item}
                isDisabled={isDisabled}
                navLinkClassName={`text-gray-400 ${item?.icon === "MainIcon" ? "hover:bg-indigo-500 bg-indigo-500" : "hover:bg-gray-700 bg-gray-800"} w-full focus:text-teal-500 hover:text-teal-500 flex justify-center h-14 items-center inline-block text-center pt-2 pb-1`}
                disabledOnClick={() => {
                  setOpenMain(true);
                  setModalTypeMain('register');
                }}
              />;
            })}
      </div>
    </nav>
  );
};

export default BottomBar;
