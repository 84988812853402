import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  Avatar,
  MessageHistoryResponse,
  OutOfChatChoicesState
} from '../../../interfaces';

import {
  useGetChatSubscriptionMutation,
  useSubscribeToNewChatsMutation,
  useUnSubscribeFromNewChatsMutation
} from '../../../reducers/avatar';
import { ConnectionStatus } from '../../../reducers/websocket';

import OutOfChatsChoiceView from './OutOfChatsChoiceView';

interface OutOfChatChoiceProps {
  msgHistory: MessageHistoryResponse;
  activeAvatar: Avatar;
  connectionStatus: ConnectionStatus;
  subscribed: boolean;
  setSubscribed: (val: boolean) => void;
  isLastNodeLocked: () => boolean;
}

const OutOfChatsChoice: React.FC<OutOfChatChoiceProps> = (
  props: OutOfChatChoiceProps
) => {
  const { msgHistory, activeAvatar, connectionStatus, subscribed, setSubscribed, isLastNodeLocked} = props;

  const [subscribe] = useSubscribeToNewChatsMutation();
  const [unSubscribe] = useUnSubscribeFromNewChatsMutation();
  const [avatarState, setAvatarState] = useState<OutOfChatChoicesState>({
    avatars: [],
    isLoaded: []
  });
  const [getSubscription] = useGetChatSubscriptionMutation();
  const history = useHistory();

  const handleSubscription = async (id: number) => {
    if (!subscribed) {
      await subscribe(id).unwrap();
      setSubscribed(true);
    } else {
      await unSubscribe(id).unwrap();
      setSubscribed(false);
    }
  };

  const setAvatars = async (avatars?: Array<Avatar>) => {
    if (!avatars) {
      if (activeAvatar) {
        const resp = await getSubscription(activeAvatar.id).unwrap();
        setAvatarState(prev => ({ ...prev, avatars: resp.avatars }));
        setSubscribed(resp.active);
      }
    } else {
      setAvatarState(prev => ({ ...prev, avatars }));
    }
  };

  const goToDiscover = () => {
    history.push('/discover');
  };

  const goToAvatarProfile = (handle: string) => {
    history.push(`/${handle}`, { from: 'chat' });
  };

  useEffect(() => {
    setAvatars(undefined);
  }, [activeAvatar]);

  return (
    <OutOfChatsChoiceView
      msgHistory={msgHistory}
      activeAvatar={activeAvatar}
      connectionStatus={connectionStatus}
      subscribed={subscribed}
      handleSubscription={handleSubscription}
      recommendedAvatars={avatarState.avatars}
      goToDiscover={goToDiscover}
      goToAvatarProfile={goToAvatarProfile}
      isLastNodeLocked={isLastNodeLocked}
    />
  );
};

export default OutOfChatsChoice;
