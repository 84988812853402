import React, { useState, useEffect } from "react";
import InfiniteScroll from 'react-infinite-scroller';
import { isMobile } from 'react-device-detect';

import { ChatPromotion } from "../../interfaces";

import { useLazyGetBrowseRecommendedQuery } from '../../reducers/avatar';

import BrowseTileImage from './BrowseTileImage';

interface Props {
  searched: boolean;
}

const BrowseRecommended: React.FC<Props> = (props: Props) => {
  const { searched } = props;
  const [items, setItems] = useState<Array<ChatPromotion>>([]);
  const [trigger, { data, error }] = useLazyGetBrowseRecommendedQuery();

  const fetchData = async () => {
    await trigger(!items.length);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (data) {
      setItems([...items, ...data]);
    }
  }, [data]);

  return (
    <>
    <InfiniteScroll
      pageStart={0}
      loadMore={fetchData}
      initialLoad={false}
      useWindow={isMobile}
      hasMore
      loader={<div className="loader" key={0}>Loading ...</div>}
      getScrollParent={() => document.getElementById('scrollableDiv')!}
    >
      <div className={`explore ${searched ? "mt-0" : ""}`}>
        {items.map((item, idx) => (
          <BrowseTileImage promotion={item} key={idx.toString()} />
        ))}
      </div>
    </InfiniteScroll>
    {error && <p>Error: {error}</p>}
  </>
  );
};

export default BrowseRecommended;
