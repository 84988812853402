import type { GeneralResponse, ModifyUserData, NotificationSetting, User } from '../../interfaces';

import baseApi from '../api';

const userInfo = baseApi.injectEndpoints({
    endpoints: (builder) => ({
      getUser: builder.mutation<User, void>({
        query: () => ({
          url: '/auth/user/',
          method: 'GET'
        }),
      }),
      updateUserInfo: builder.mutation<User, ModifyUserData>({
        query: (userData) => ({
            url: '/auth/user/',
            method: 'PATCH',
            body: userData,
          }),
      }),
      changePassword: builder.mutation<GeneralResponse, {currentPassword: string, newPassword: string}>({
        query: (passwords) => ({
            url: '/auth/password/change/',
            method: 'POST',
            body: {
              oldPassword: passwords.currentPassword,
              newPassword1: passwords.newPassword,
              newPassword2: passwords.newPassword,
            },
          }),
      }),
      deleteUser: builder.mutation<GeneralResponse, void>({
        query: () => ({
          url: '/auth/user/delete/',
          method: 'DELETE'
        }),
      }),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      updateProfileImage: builder.mutation<{imagePath: string, imgName: string}, {profileImage: any, imgName: string}>({
        query: (image) => ({
            url: '/auth/user/profile-image/',
            method: 'PUT',
            body: image,
          }),
      }),
      removeProfileImage: builder.mutation<{user: User | null, status?: string, message?: string}, {imgName: string}>({
        query: (image) => ({
            url: '/auth/user/profile-image/',
            method: 'DELETE',
            body: image,
          }),
      }),
      updateNotificationSettings: builder.mutation<GeneralResponse, NotificationSetting>({
        query: (setting) => ({
            url: '/user/update-notification-settings',
            method: 'PUT',
            body: setting,
          }),
      }),
   }),
    overrideExisting: false,
  });
  
  export const {
    useGetUserMutation,
    useUpdateUserInfoMutation,
    useChangePasswordMutation,
    useDeleteUserMutation,
    useUpdateProfileImageMutation,
    useRemoveProfileImageMutation,
    useUpdateNotificationSettingsMutation,
  } = userInfo;
