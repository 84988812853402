/* Notification settings component */
import React from 'react';

import { User } from '../../../interfaces';

import { useAppDispatch, useAppSelector } from "../../../reducers/hooks";
import { useUpdateUserInfoMutation, setModifiedUserData} from '../../../reducers/user';

import NotificationsView from './NotificationsView';

interface Props {
  isMobileView: boolean;
}

const Notifications: React.FC<Props> = (props: Props) => {
  const { isMobileView} = props;
  const { user, friendSuggestions, newsAndPromotions, billingUpdates } = useAppSelector((state) => ({
    user: state.user.user,
    friendSuggestions: state.user.user?.friendSuggestions,
    newsAndPromotions: state.user.user?.newsAndPromotions,
    billingUpdates: state.user.user?.billingUpdates,
  }));

  const [updateNotification] = useUpdateUserInfoMutation();
  const dispatch = useAppDispatch();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleCheckBoxChange = async ({ target }: any) => {
    const tmpObj = { [target.name]: target.checked };

    await updateNotification(tmpObj);

    const modifiedUser = {...user, ...tmpObj};
    dispatch(setModifiedUserData(modifiedUser as User));
  };

  return (
    <NotificationsView
      isMobileView={isMobileView}
      friendSuggestions={friendSuggestions as boolean}
      newsAndPromotions={newsAndPromotions as boolean}
      billingUpdates={billingUpdates as boolean}
      handleCheckBoxChange={handleCheckBoxChange}
    />
  );
};

export default Notifications;
