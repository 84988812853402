import React from "react";

import { useGetPartnershipsQuery } from "../../../reducers/partnership";

import PartnershipsView from "./PartnershipsView";

interface Props {
  isMobileView: boolean;
}

const Partnerships: React.FC<Props> = (props: Props) => {
  const { isMobileView } = props;
  const { data: partnerships } = useGetPartnershipsQuery();
  return (
    <PartnershipsView
      partnerships={partnerships || []}
      isMobileView={isMobileView}
    />
  );
};

export default Partnerships;
