/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { RefObject } from 'react';

import { Avatar, ChatPromotion, Tag } from '../../interfaces';

import { Breadcrumb } from '../../components';

import BrowseRecommended from './BrowseRecommended';
import BrowseTileImage from './BrowseTileImage';
import DiscoverBrowseAvatarItem from './DiscoverBrowseAvatarItem';
import NoResults from './NoResults';
import './DiscoverBrowse.css';

interface DiscoverBrowseViewProps {
    fromDiscover: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    history: any;
    scrollRef: RefObject<HTMLUListElement>
    sideScroll: (element: HTMLUListElement | null, speed: number, distance: number, step: number) => void;
    tags: Array<Tag>;
    isTagActiveClassName: (val: number) => string;
    setSelectedTagsToState: (val: number) => void;
    selectedAvatars: Array<Avatar>;
    chatPromotions: Array<ChatPromotion>;
    setActiveAvatar: (avatar: Avatar | undefined) => Promise<void>;
    search: string;
    onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    tagsSelected: boolean;
    loadingSearch: boolean;
}

const DiscoverBrowseView : React.FC<DiscoverBrowseViewProps> = (props: DiscoverBrowseViewProps) => {
    const {
      fromDiscover,
      history,
      scrollRef,
      sideScroll,
      tags,
      isTagActiveClassName,
      setSelectedTagsToState,
      selectedAvatars,
      chatPromotions,
      setActiveAvatar,
      search,
      onSearchChange,
      tagsSelected,
      loadingSearch,
    } = props;

    return (
      <div className="relative min-h-screen bg-gray-100 overflow-auto" id="scrollableDiv">
        {fromDiscover && <Breadcrumb isRedirect={fromDiscover} history={history} text="Discover" backRedirect='/discover' navClass="z-30 bg-white w-full bg-opacity-95 border-b border-blue-gray-200 flex items-center space-x-2 lg:space-x-4 py-3" />}

        {/* Search bar */}
        <div className="searchbar group">
          <label htmlFor="search" className="sr-only">Search</label>
          <input id="search" name="search" type="search" className="" placeholder="Search for names, categories, tags, etc…" value={search} onChange={onSearchChange} />
          <svg className="magnifying-glass-icon group-focus-within:opacity-0" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.47 21.53a.75.75 0 1 0 1.06-1.06l-1.06 1.06Zm-9.97-4.28a6.75 6.75 0 0 1-6.75-6.75h-1.5a8.25 8.25 0 0 0 8.25 8.25v-1.5ZM3.75 10.5a6.75 6.75 0 0 1 6.75-6.75v-1.5a8.25 8.25 0 0 0-8.25 8.25h1.5Zm6.75-6.75a6.75 6.75 0 0 1 6.75 6.75h1.5a8.25 8.25 0 0 0-8.25-8.25v1.5Zm11.03 16.72-5.196-5.197-1.061 1.06 5.197 5.197 1.06-1.06Zm-4.28-9.97c0 1.864-.755 3.55-1.977 4.773l1.06 1.06A8.226 8.226 0 0 0 18.75 10.5h-1.5Zm-1.977 4.773A6.727 6.727 0 0 1 10.5 17.25v1.5a8.226 8.226 0 0 0 5.834-2.416l-1.061-1.061Z" />
          </svg>
        </div>

        {/* No results */}
        <NoResults found={!!(selectedAvatars.length || chatPromotions.length) || loadingSearch} />

        {/* <!-- Filter --> */}
        <div className="relative z-20 flex w-full pt-5">
          <ul className="browse-filter-tags scrollbar-hide flex-1" ref={scrollRef}>
            <button type="button" className="tags-arrow tags-left" onClick={() => {sideScroll(scrollRef.current, 25, 100, -10);}}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />
              </svg>
            </button>
            <button type="button" className="tags-arrow tags-right" onClick={() => {sideScroll(scrollRef.current, 25, 100, 10);}}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
              </svg>
            </button>
            {tags && tags.map(tag => (
              <li key={tag.id} role="presentation" className={isTagActiveClassName(tag.id)} onClick={() => setSelectedTagsToState(tag.id)}>
                <a>{tag.tagText}</a>
              </li>
            ))}
          </ul>
        </div>

        {/* <!-- Browse --> */}
        {(search || tagsSelected) &&
          <div className="browse py-4 flex flex-col px-4">
            <div className="browse">
              {selectedAvatars.map(a => (
                <DiscoverBrowseAvatarItem
                  key={a.id}
                  image={a.avatarPicture.replace("_free.", "_thumbnail_free.")}
                  mainText={a.avatarName}
                  secondaryText={a.bio}
                  onClick={() => {
                    setActiveAvatar(a);
                    history.push(`/add-friend?q=${a.handle}`, {
                      openChat: true
                    });
                  }}
                />
              ))}
            </div>
          </div>
        }

        {/* <!-- Avatar Modal --> */}
        {(!search && !tagsSelected) || (!selectedAvatars.length && !chatPromotions.length)  ? (
            <BrowseRecommended searched={!!(search || tagsSelected)} />
          ) : (
            <div className="explore">
              {chatPromotions.map(c => (
                <BrowseTileImage promotion={c} key={c.handle} />
              ))}
            </div>
          )
        }
      </div>
    );
};

export default DiscoverBrowseView;
