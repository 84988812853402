import React from 'react';

import { ConfirmationModalProps } from '../../../components';

interface LockedNodeProps {
  handleUnlock: () => void;
  initialBailoutProps: ConfirmationModalProps;
  payWallUnlockPrice: number;
  payWallText: string;
}

const LockedNode: React.FC<LockedNodeProps> = (props: LockedNodeProps) => {
  const { handleUnlock, initialBailoutProps, payWallUnlockPrice, payWallText} = props;

  return (
    <div className="choice-container">
      <div className="items-center my-4 min-w-full flex flex-col space-y-4 bg-gradient-to-br from-purple-600 via-blue-400 to-blue-300 rounded-xl p-4 text-white">
        <span className="font-medium text-xl opacity-90">{payWallText}</span>
        <div className="mt-4 text-center w-full">
          <button
            onClick={handleUnlock}
            type="button"
            className="group relative w-full sm:max-w-lg inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-4 bg-purple-900 bg-opacity-70 text-lg font-medium text-white hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-600 transition"
          >
            💬 Unlock for {payWallUnlockPrice} credits
          </button>
        </div>
        <button
          type="button"
          onClick={initialBailoutProps.open}
          className="text-sm hover:bg-white hover:bg-opacity-20 transition p-1 px-2 rounded-lg"
        >
          Skip this chat &rarr;
        </button>
      </div>
    </div>
  );
};

export default LockedNode;
