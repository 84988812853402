/* Avatar list item component displayed on the discover page */
import React from 'react';
import { useHistory } from 'react-router-dom';

import { ChatPromotion } from '../../interfaces';

import { useAppDispatch } from "../../reducers/hooks";
import { setActiveAvatar } from '../../reducers/avatar';

interface Props {
  promotion: ChatPromotion;
}

const BrowseTileImage: React.FC<Props> = (props: Props) => {
  const { promotion } = props;
  const history = useHistory();
  const dispatch = useAppDispatch();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClick = async (e: any) =>  {
    e.stopPropagation();
    if(promotion.handle) {
      await dispatch(setActiveAvatar(promotion.avatar));
      let url = `/add-friend?q=${promotion.handle}`;
      if(promotion.chatId) {
        url = `/add-friend?q=${promotion.handle}&chatId=${promotion.chatId}`;
      }
      history.push(url, {
        openChat: true,
        chatId: promotion.chatId
      });
    }
  };

  return (
    <div>
      <a href="#" onClick={handleClick}>
      {promotion.video ? (
          <video loop muted autoPlay playsInline>
            <source src={promotion.video} type="video/mp4" />
          </video>
        ) : (
          <img
            alt={promotion.handle}
            src={promotion.image}
          />
        )}
      </a>
    </div>
  );
};

export default BrowseTileImage;
