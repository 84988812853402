/* Media viewer full screen modal component */
import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import { MediaNode } from '../../interfaces';

import { useGetPresignedUrlMutation } from '../../reducers/base';

import MediaViewerView from './MediaViewerView';

interface MediaViewerProps {
  mediaNodes: Array<MediaNode>;
  initialMediaIdx: number;
  setOpenMediaViewer: (value: boolean) => void;
  isMobileView: boolean;
  isIOS: boolean;
  avatarId?: number;
  fromAvatarPost?: boolean;
  handle?: string;
}

const MediaViewer: React.FC<MediaViewerProps> = (props: MediaViewerProps) => {
  const {
    mediaNodes,
    initialMediaIdx,
    setOpenMediaViewer,
    isMobileView,
    isIOS,
    avatarId,
    fromAvatarPost,
    handle
  } = props;
  const [getPresignedUrl] = useGetPresignedUrlMutation();
  const [mediaIdx, setMediaIdx] = useState<number>(initialMediaIdx);
  const [clickedMedia, setClickedMedia] = useState<string | undefined>('');
  const [openBreadcrumb, setOpenBreadcrumb] = useState<boolean>(true);
  const [scrollPosition, setScrollPosition] = useState<number>(window.scrollY);
  const swipeRef = useRef(true);
  
  const controlDirection = (e: WheelEvent) => {
      if(e.deltaY > scrollPosition)
        rightSwipe();
      else
        leftSwipe();
      setScrollPosition(e.deltaY);
  };
  
  useEffect(() => {
      window.addEventListener('wheel', controlDirection);
      window.addEventListener("keydown", handleKeyDown);
      return () => {
          window.removeEventListener('wheel', controlDirection);
          document.removeEventListener("keydown", handleKeyDown);
        };
  }, []);

  const history = useHistory();
  
  const handleBackClick = async () => {
    if(history && history.location) {
      if( history.location.pathname.includes('/chat')) {
        history.push(`/chat${handle ? `/${handle}` : ""}`);
      } else if(history.location.pathname.includes('/saved')) {
        history.push(`/saved/${avatarId || 'all'}`);
      }
    }
    setOpenMediaViewer(false);
  };

  const handleTextClick = () => {
    let tmpHandle = handle;
    if(history?.location?.state) {
      const {avtHandle} = history.location.state;
      tmpHandle = avtHandle;
    }
    
    if (history && history.location && history.location.pathname.includes('/chat')) {
      setOpenMediaViewer(false);
    } else {
      if (history && history.location && !history.location.pathname.includes('/chat')
      && !history.location.pathname.includes('/saved')) {
        setOpenMediaViewer(false);
        return ;
      }
      history.push(`/chat${tmpHandle ? `/${tmpHandle}` : ""}`);
    }
  };

  const setAvatarNameAndPicture = async (url: string) => {
      // If the mediaFile on the node is locked (not _free), sign the url and set the clickedMedia state
      if (!url.includes('_free')) {
        const response = await getPresignedUrl({
          media: url
        }).unwrap();
        setClickedMedia(response.url);
      } else {
        // If the mediaFile on the node is free, set the clickedMedia state with the default media on the node
        setClickedMedia(url);
      }
  };

  useEffect(() => {
    setAvatarNameAndPicture(mediaNodes[mediaIdx].url);
  }, [mediaIdx]);

  const disableSwipe = () => {
    swipeRef.current = false;
    window.setTimeout(() => {
      swipeRef.current = true;
    }, 500);
  };

  const rightSwipe = () => {
    if (swipeRef.current) {
      setMediaIdx(idx => idx === mediaNodes.length - 1 ? 0 : idx + 1);
      disableSwipe();
    }
  };

  const leftSwipe = () => {
    if (swipeRef.current) {
      setMediaIdx(idx => idx === 0 ? mediaNodes.length - 1 : idx - 1);
      disableSwipe();
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleKeyDown = (e: any) => {
    if (e.keyCode === 27)
      setOpenMediaViewer(false);
    if (e.keyCode === 37)
      leftSwipe();
    if (e.keyCode === 39)
      rightSwipe();
  };
  
  const handleSectionClick = () => {
    setOpenBreadcrumb(prev => !prev);
  };

  return (
    <MediaViewerView
      handleBackClick={handleBackClick}
      handleTextClick={handleTextClick}
      mediaNodes={mediaNodes}
      mediaIdx={mediaIdx}
      clickedMedia={clickedMedia}
      openBreadcrumb={openBreadcrumb}
      handleSectionClick={handleSectionClick}
      leftSwipe={leftSwipe}
      rightSwipe={rightSwipe}
      isMobileView={isMobileView}
      isIOS={isIOS}
      history={history}
      fromAvatarPost={fromAvatarPost}
      handle={handle}
    />
  );
};

export default MediaViewer;
