import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';

import { Avatar } from '../../interfaces';

export interface AvatarState {
  activeAvatar?: Avatar;
  friendAvatars: Avatar[];
  loadedAvatarIds: number[];
  unreads: {[key: string]: number};
  newChatNotifications: Array<number> ;
  isFetching: boolean;
  error?: string;
}

const initialState: AvatarState = {
  isFetching: false,
  friendAvatars: [],
  loadedAvatarIds: [],
  unreads: {},
  error: '',
  newChatNotifications: []
};

const slice = createSlice({
  name: 'avatar',
  initialState,
  reducers: {
    setActiveAvatar: (state, action: PayloadAction<Avatar | undefined>) => {
      state.activeAvatar = action.payload;
    },
    setFriendAvatars: (state, action: PayloadAction<Avatar[]>) => {
      state.friendAvatars = action.payload;
    },
    setUnreads: (state, action: PayloadAction<{[key: string]: number}>) => {
      state.unreads = action.payload;
    },
    setLoadedAvatars: (state, action: PayloadAction<number[]>) => {
      state.loadedAvatarIds = action.payload;
    },
    setNewChatNotifications: (state, action: PayloadAction<Array<number>>) => {
      state.newChatNotifications = action.payload;
    },
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState);
  }
});

export const {
  setActiveAvatar,
  setFriendAvatars,
  setUnreads,
  setLoadedAvatars,
  setNewChatNotifications,
  setError,
} = slice.actions;

export default slice.reducer;
