/* Component to display videos and handle unlock logic */
import React from 'react';

interface VideoHandlerProps {
  locked?: boolean;
  actionAfterMediaLoaded?: () => void;
  available?: boolean;
  bucketVideo?: string | null;
  requiredCredits?: number;
  nodeId?: number;
  referenceId?: string;
  inChat?: boolean;
  setContentModalOpen?: (val:boolean) => void;
  setCurrentReference?: (ref:string) => void;
  setContentCreditCost?: (val:number) => void;
  setContentNodeId?: (val:number) => void;
  handleVideoClick?: (url: string, setCurrentReference?: (refId:string) => void, refId?:string) => void;
  isUserTemp?: boolean;
  handleRegisterClick?: () => void;
  fromGallery?: boolean;
}

const VideoHandler: React.FC<VideoHandlerProps> = (props: VideoHandlerProps) => {
  const {
    isUserTemp,
    nodeId,
    referenceId,
    locked,
    available,
    bucketVideo,
    requiredCredits,
    actionAfterMediaLoaded,
    inChat,
    setContentModalOpen,
    setCurrentReference,
    setContentCreditCost,
    setContentNodeId,
    handleVideoClick,
    handleRegisterClick,
    fromGallery,
  } = props;

  const thumbnailUrl = bucketVideo ? `${bucketVideo.substring(0, bucketVideo.lastIndexOf("."))}_tn_free.jpg` : '';
  
  const actionOnLoad: () => void = () => {
    if (actionAfterMediaLoaded) {
      actionAfterMediaLoaded();
    }
  };

  const onVideoClick = (): void => {
    if (handleVideoClick)
      handleVideoClick(bucketVideo!, setCurrentReference, referenceId);
  };

  if (thumbnailUrl) {
    if (locked && !available) {
      return (
        <div className={`${!fromGallery && "message-image"} message-ppv`}>
          {thumbnailUrl !== '' ? (
            <div className={`${!fromGallery && "relative"}`}>
              <img src={thumbnailUrl} alt="Error while loading" onLoad={() => actionOnLoad()}/>
              <button
              id="unlockLockedVideoButton"
              type="button"
              className="inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 hover:bg-indigo-700
                focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 text-base font-medium text-white focus:outline-none sm:text-sm
                absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-5/6"
                onClick={async () => {
                  try {
                    if(requiredCredits && nodeId) {
                      if(setContentCreditCost) setContentCreditCost(requiredCredits);
                      if(setContentNodeId) setContentNodeId(nodeId);
                    }
                    if (isUserTemp) {
                      if (handleRegisterClick) {handleRegisterClick();}
                      return;
                    }
                    if(setContentModalOpen) setContentModalOpen(true);
                  } catch (err) {
                    console.log(err);
                  }
                }}
              >
              ▶ Unlock video for {requiredCredits} credits 🔓
            </button>
            </div>
          ) : (
            <div className="flex flex-1 justify-center">
            <div style={{borderTopColor: "transparent"}} className="w-12 h-12 border-4 border-blue-400 border-solid rounded-full animate-spin" />
           </div>
          )}
        </div>
      );
    }

    return (
      <div className={inChat ? "message-image relative" : ""}>
        <a role="button" tabIndex={0} className="h-full w-full" onClick={onVideoClick} onKeyDown={onVideoClick}>
          <img src={thumbnailUrl} alt="thumbnail" className="h-full w-full object-cover self-center" onLoad={() => actionOnLoad()} />
          <svg xmlns="http://www.w3.org/2000/svg" className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-14 w-14" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z" clipRule="evenodd" />
          </svg>
        </a>
      </div>
    );

  }

  return null;

};

export default VideoHandler;
