import React, { useEffect, useState } from 'react';
import { BellIcon, CashIcon, CogIcon, GiftIcon } from '@heroicons/react/outline';
import { useHistory, useParams } from 'react-router-dom';

import { User } from '../../interfaces';

import { WithMobileDetectionProps, Breadcrumb } from '../../components';
import { useAppDispatch, useAppSelector } from "../../reducers/hooks";
import { setUserProfileInfo, useGetUserMutation } from '../../reducers/user';
import { setPaymentModalOpen } from '../../reducers/payment';

import PurchaseHistory from './_PurchaseHistory';
import Accountform from './_Accountform';
import Notifications from './_Notifications';
import Billing from './_Billing';
import Partnerships from './_Partnerships';
import ProfileView from './ProfileView';

const classNames = (...classes: string[]) => classes.filter(Boolean).join(' ');

interface useParamsProps {
  paramPage?: string;
}


const Profile: React.FC<WithMobileDetectionProps> = (props: WithMobileDetectionProps) => {
  const {
    isBarOpen,
    screenViewType,
    handleIsBarOpen,
    isMobileView,
  } = props;
  const { user, isPaymentModalOpen, paymentType } = useAppSelector((state) => ({
    user: state.user.user,
    isPaymentModalOpen: state.payment.isPaymentModalOpen,
    paymentType: state.payment.paymentType,
  }));

  // If the user.nickname is not undefined append "@" to the nickname
  const subNavigation = [
    {
      name: 'Account',
      description: user?.nickname ? `@${  user.nickname}` : 'Unknown account',
      href: 'account',
      icon: CogIcon,
      current: true
    },
    {
      name: 'Notifications',
      href: 'notifications',
      icon: BellIcon,
      current: false
    },
    {
      name: 'Billing',
      href: 'billing',
      icon: CashIcon,
      current: false
    },
    {
      name: 'Special offers',
      href: 'offers',
      icon: GiftIcon,
      current: false
    }
  ];
  const [page, setPage] = useState<string>("Account");
  const [purchaseHistoryOpen, setPurchaseHistoryOpen] = useState<boolean>(false);
  const [getUser] = useGetUserMutation();
  const dispatch = useAppDispatch();

  const { paramPage } = useParams<useParamsProps>();

  const history = useHistory();

  useEffect(() => {
    switch (paramPage) {
      case 'account':
        setPage('Account');
        break;
      case 'notifications':
        setPage('Notifications');
        break;
      case 'billing':
        setPage('Billing');
        break;
      case 'offers':
        setPage('Special offers');
        break;
      default:
        break;
    }
    handleIsBarOpen(false);
  }, [paramPage]);

  useEffect(() => {
    if (!user?.isRegistered)
      history.push('/');
  }, [history.location.pathname]);

  useEffect(() => {
    if(isMobileView) {
      if(!isBarOpen)
        setPage("");
    }
  }, [history.location]);

  const navigateToMain = () => {
    history.push('/profile');
  };

  const setUserProfileInStore = async (info: User) => {
    dispatch(setUserProfileInfo(info));
  };

  const getUserProfileInfo = async () => {
    const info = await getUser().unwrap();

    setUserProfileInStore(info);
  };

  const setOpenPurchaseHistory = (value: boolean) => {
    setPurchaseHistoryOpen(value);
  };

  const closePurchaseHistory = () => {
    setPurchaseHistoryOpen(false);
  };

  const setOpen = (isOpen: boolean) => {
    dispatch(setPaymentModalOpen(isOpen));
  };

  useEffect(() => {
    getUserProfileInfo();
    if (isPaymentModalOpen) {
      setPage('Billing');
    }
  }, []);

  useEffect(() => {
    document.title = "Settings - Fantxt";
  }, []);

  const renderPage = () => {
    if (purchaseHistoryOpen)
      return (<div className="w-full">
        {screenViewType === 'web' && <Breadcrumb
          text='Billing'
          isRedirect={false}
          customBackFunction={purchaseHistoryOpen ? closePurchaseHistory : undefined}
          navClass={`${isMobileView && "fixed top-0 bg-gray-100 w-full"} border-b border-blue-gray-200 flex items-center space-x-2 lg:space-x-4 py-3`}
        />}
      <PurchaseHistory isMobileView={isMobileView}/>
      </div>);
    switch (page) {
      case 'Account':
        return <Accountform user={user as User} isMobileView={isMobileView}/>;
      case 'Notifications':
        return <Notifications isMobileView={isMobileView}/>;
      case 'Billing':
        return (
          <Billing
            setOpenPurchaseHistory={setOpenPurchaseHistory}
            isMobileView={isMobileView}
            setOpen={setOpen}
          />
        );
      case 'Special offers':
        return (
          <Partnerships
            isMobileView={isMobileView}
          />
        );
      default:
        return null;
    }
  };

  return (
    <ProfileView
      handleMobileMenuOpen={handleIsBarOpen}
      renderPage={renderPage}
      mobileMenuOpen={isBarOpen}
      classNames={classNames}
      subNavigation={subNavigation}
      screenViewType={screenViewType}
      setPage={setPage}
      setOpenPurchaseHistory={setOpenPurchaseHistory}
      page={page}
      purchaseHistoryOpen={purchaseHistoryOpen}
      closePurchaseHistory={closePurchaseHistory}
      isMobileView={isMobileView}
      navigateToMain={navigateToMain}
      setOpen={setOpen}
      paymentType={paymentType}
      isPaymentModalOpen={isPaymentModalOpen}
    />
  );
};

export default Profile;
