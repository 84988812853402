import React from 'react';

interface NotificationsViewProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleCheckBoxChange: ({ target }: any) => Promise<void>;
  newsAndPromotions: boolean;
  friendSuggestions: boolean;
  billingUpdates: boolean;
  isMobileView: boolean;
}

const NotificationsView: React.FC<NotificationsViewProps> = (
  props: NotificationsViewProps
) => {
  const {
    handleCheckBoxChange,
    newsAndPromotions,
    friendSuggestions,
    billingUpdates,
    isMobileView
  } = props;

  return (
    <div className={`flex-1 max-h-screen overflow-y-auto pb-16 ${isMobileView && "h-screen bg-gray-100"}`}>
      <div className={`${!isMobileView ?  "max-w-3xl mx-auto py-10 px-4 sm:px-6 lg:py-12 lg:px-8" : "py-10 px-4 pt-20 md:pt-0"}`}>
        <h1 className="text-3xl font-extrabold text-blue-gray-900">
          Notifications
        </h1>
        <div className="mt-6">
          <fieldset>
            <legend className="text-base font-medium text-gray-900">
              Email notifications
            </legend>
            <p className="text-sm text-gray-500">
              Choose what email updates you՚d like to receive.
            </p>
            <div className="mt-4 space-y-4">
              <div className="relative flex items-start">
                <div className="flex items-center h-5">
                  <input
                    id="news_and_promotions"
                    name="newsAndPromotions"
                    type="checkbox"
                    className="focus:ring-blue-400 h-4 w-4 text-blue-600 border-gray-300 rounded"
                    onChange={handleCheckBoxChange}
                    checked={newsAndPromotions}
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label htmlFor="promo" className="font-medium text-gray-700">
                      Updates and special offers
                  </label>
                </div>
              </div>
              <div className="relative flex items-start">
                <div className="flex items-center h-5">
                  <input
                    id="friend_suggestions"
                    name="friendSuggestions"
                    type="checkbox"
                    className="focus:ring-blue-400 h-4 w-4 text-blue-600 border-gray-300 rounded"
                    onChange={handleCheckBoxChange}
                    checked={friendSuggestions}
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label
                    htmlFor="suggestions"
                    className="font-medium text-gray-700"
                  >
                    Content suggestions based on your interest
                  </label>
                </div>
              </div>
              <div className="relative flex items-start">
                <div className="flex items-center h-5">
                  <input
                    id="billing_updates"
                    name="billingUpdates"
                    type="checkbox"
                    className="focus:ring-blue-400 h-4 w-4 text-blue-600 border-gray-300 rounded"
                    onChange={handleCheckBoxChange}
                    checked={billingUpdates}
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label
                    htmlFor="billing"
                    className="font-medium text-gray-700"
                  >
                    Billing updates
                  </label>
                </div>
              </div>
            </div>
          </fieldset>
          <fieldset className="mt-8">
            <div>
              <legend className="text-base font-medium text-gray-900">
                Push notifications
              </legend>
              <p className="text-sm text-gray-500">
                Allow notifications for Fantxt in your browser for push
                notifications.
              </p>
            </div>
            <div className="mt-4 space-y-4">
              <div className="flex items-center">
                <input
                  id="push-msg"
                  name="push-notifications"
                  type="radio"
                  className="focus:ring-blue-400 h-4 w-4 text-blue-600 border-gray-300"
                />
                <label
                  htmlFor="push-msg"
                  className="ml-3 block text-sm font-medium text-gray-700"
                >
                  New messages and recommendations
                </label>
              </div>
              <div className="flex items-center">
                <input
                  id="push-email"
                  name="push-notifications"
                  type="radio"
                  className="focus:ring-blue-400 h-4 w-4 text-blue-600 border-gray-300"
                />
                <label
                  htmlFor="push-email"
                  className="ml-3 block text-sm font-medium text-gray-700"
                >
                  New messages only
                </label>
              </div>
              <div className="flex items-center">
                <input
                  id="push-none"
                  name="push-notifications"
                  type="radio"
                  className="focus:ring-blue-400 h-4 w-4 text-blue-600 border-gray-300"
                />
                <label
                  htmlFor="push-none"
                  className="ml-3 block text-sm font-medium text-gray-700"
                >
                  None
                </label>
              </div>
            </div>
          </fieldset>
        </div>
      </div>
    </div>
  );
};

export default NotificationsView;
