import React, { ReactElement } from 'react';

import { useForgotPasswordMutation } from '../../reducers/auth';

import ForgotPasswordView from './ForgotPasswordView';

const ForgotPassword = (): ReactElement => {

  const [formState, setFormState] = React.useState<string>('');

  const [sendRequest] = useForgotPasswordMutation();
  
  const handleChange = ({target: { value }}: React.ChangeEvent<HTMLInputElement>) =>
    setFormState(value);

  const handleRequestPasswordClick = async () => {
    try {
      await sendRequest(formState).unwrap();
    } catch (err) {
      console.log('Error while requesting new password!');
    }
  };

  return (
    <ForgotPasswordView
      formState={formState}
      handleChange={handleChange}
      handleRequestPasswordClick={handleRequestPasswordClick}
    />
  );
};

export default ForgotPassword;
