/* Account settings form component */
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ExclamationIcon } from '@heroicons/react/outline';

import { ModifyUserData, User, ErrorIF } from '../../../interfaces';

import { persistor } from '../../../reducers/store';
import { useAppDispatch } from "../../../reducers/hooks";
import {
  setModifiedUserData,
  updateProfilePicture,
  useUpdateProfileImageMutation,
  useUpdateUserInfoMutation,
  useChangePasswordMutation,
  useRemoveProfileImageMutation
} from '../../../reducers/user';
import WSApi from '../../../services/ws-api-handler/ws-api';

import AccountformView from './AccountformView';

interface AccountformProps {
  user: User;
  isMobileView: boolean;
}

const Accountform: React.FC<AccountformProps> = (props: AccountformProps) => {
  const { user, isMobileView} = props;
  const dispatch = useAppDispatch();

  const [update, { error: updateError }] = useUpdateUserInfoMutation();
  const [changePassword] = useChangePasswordMutation();
  const [updateProfileImage] = useUpdateProfileImageMutation();
  const [removeProfileImage] = useRemoveProfileImageMutation();

  const [open, setOpen] = useState(false);
  const ModalProps = {
    icon: (
      <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
        <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
      </div>
    ),
    title: 'Delete account',
    description:
      'Are you sure you want to delete your account? All of your data will be permanently removed from our servers forever. This action cannot be undone.',
    button1Text: 'Cancel',
    button2Text: 'Delete'
  };

  const [errors, setErrors] = useState<ErrorIF>({});
  const [profileImageName, setProfileImageName] = useState('');
  const [loading, setLoading] = useState(false);
  const [profileFormState, setProfileFormState] = useState<ModifyUserData>({
    nickname: '',
    profilePicture: '',
    lastName: '',
    firstName: '',
    email: '',
    currentPassword: '',
    newPassword: ''
  });

  useEffect(() => {
    if (updateError && "data" in updateError) {
      const { data } = updateError;
      const apiErrors = (data as {fieldErrors: ErrorIF}).fieldErrors;
      if (apiErrors)
        setErrors(apiErrors);
    }
  }, [updateError]);

  useEffect(() => {
    setInputFieldsValueFromDB();
  }, [user]);

  const convertToBase64 = (file: File) =>
    new Promise((resolve, reject) => {
      if (file) {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
          resolve(fileReader.result);
        };
        fileReader.onerror = error => {
          reject(error);
        };
      }
    });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const fileSelectHandler = async (e:any) => {
    const {name, value, files} = e.target;
    validate({ [name]: value });
    
    if (files && files[0]) {
      const imageFile = files[0];

        if(imageFile.name.match(/\.(jpg|jpeg|png|gif)$/)) {
        const base64 = await convertToBase64(imageFile);
        if (base64) {
          setLoading(true);
          const profImage =
            await updateProfileImage({
              profileImage: base64,
              imgName: profileImageName
            }).unwrap();
          setProfileFormState(prev => ({
            ...prev,
            profilePicture: profImage.imagePath
          }));
          setProfileImageName(profImage.imgName);
          dispatch(updateProfilePicture(profImage.imagePath));
          setLoading(false);
        }
      }
    }
  };

  const handleRemoveButton = async () => {
      await removeProfileImage({imgName: profileImageName}).unwrap();
      setProfileImageName('');
      setProfileFormState(prev => ({
        ...prev,
        profilePicture: ''
      }));
    };

  const handleChange = ({
    target: { name, value }
  }: React.ChangeEvent<HTMLInputElement>) => {
    validate({ [name]: value });
    setProfileFormState(prev => ({ ...prev, [name]: value }));
  };

  const setInputFieldsValueFromDB = () => {
    if (user) {
      setProfileFormState({
        nickname: user.nickname,
        profilePicture: user.profilePicture,
        // language: user.language,
        // location: user.location,
        lastName: user.lastName,
        firstName: user.firstName,
        email: user.email,
        currentPassword: '',
        newPassword: ''
      });
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = async (e: any) => {
    e.preventDefault();
    if (profileFormState.currentPassword && profileFormState.newPassword) {
      try {
        await changePassword({
          currentPassword: profileFormState.currentPassword,
          newPassword: profileFormState.newPassword
        }).unwrap();
        toast.success('Successfully updated password', {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
      } catch (err) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if ((err as any).data.fieldErrors) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const keys = Object.keys((err as any).data.fieldErrors);
          for (let i=0; i<keys.length; i += 1) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            toast.error((err as any).data.fieldErrors[keys[i]][0], {
              position: 'top-center',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined
            });
          }
        }
        setProfileFormState(prev => ({
          ...prev,
          currentPassword: '',
          newPassword: '',
        }));
      }
    }
    try {
      const modifiedUser =
        await update(profileFormState).unwrap();
      if (modifiedUser) {
        toast.success('Successfully updated account', {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
      }
      dispatch(setModifiedUserData(modifiedUser));
    } catch (err) {
      setProfileImageName('');
      setProfileFormState(prev => ({
        ...prev,
        currentPassword: '',
        newPassword: '',
        profilePicture: ''
      }));
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const validate: any = (fieldValues = profileFormState) => {
    const temp: ErrorIF = { ...errors };

    if ('currentPassword' in fieldValues) {
      temp.newPassword =
        fieldValues.currentPassword !== '' &&
        profileFormState.newPassword === ''
          ? 'Please enter new password.'
          : '';
    }

    if ('currentPassword' in fieldValues) {
      temp.currentPassword =
        fieldValues.currentPassword === '' &&
        profileFormState.newPassword !== ''
          ? 'Please enter current password.'
          : '';
    }

    if ('newPassword' in fieldValues) {
      temp.newPassword =
        profileFormState.currentPassword !== '' &&
        fieldValues.newPassword === ''
          ? 'Please enter new password.'
          : '';
    }

    if ('newPassword' in fieldValues) {
      temp.currentPassword =
        profileFormState.currentPassword === '' &&
        fieldValues.newPassword !== ''
          ? 'Please enter current password.'
          : '';
    }

    if ('nickname' in fieldValues)
      temp.nickname = fieldValues.nickname ? '' : 'This field is required.';

    if ('profilePicture' in fieldValues)
      temp.profilePicture = fieldValues.profilePicture && fieldValues.profilePicture.match(/\.(jpg|jpeg|png|gif)$/) ? '' : 'Invalid image format.';

    if ('firstName' in fieldValues)
      temp.firstName = fieldValues.firstName ? '' : 'This field is required.';

    if ('email' in fieldValues) {
      temp.email = fieldValues.email
        ? ''
        : 'This field is required.';
      if (fieldValues.email)
        temp.email = fieldValues.email !== '' && /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(
          fieldValues.email
        )
          ? ''
          : 'Email is not valid.';
    }

    setErrors({
      ...temp
    });
  };

  const formIsValid = () => {
    const isValid =
      (!errors.nickname || errors.nickname === '') &&
      (!errors.firstName || errors.firstName === '') &&
      (!errors.email || errors.email === '') &&
      (!errors.currentPassword || errors.currentPassword === '') &&
      (!errors.newPassword || errors.newPassword === '') &&
      !errors.profilePicture;

    return isValid;
  };

  const logout = () => {
    WSApi.ws.disconnect();
    persistor.purge();
    try {
      localStorage.setItem('isUserSignedIn', 'false');
    } catch {
      console.warn('Cookies are blocked!');
    }
    window.location.href = `${process.env.REACT_APP_LANDINGPAGE_URL}`;
  };

  return (
    <AccountformView
      open={open}
      setOpen={setOpen}
      ModalProps={ModalProps}
      onSubmit={onSubmit}
      handleChange={handleChange}
      errors={errors}
      profileFormState={profileFormState}
      user={user}
      logout={logout}
      formIsValid={formIsValid}
      profileImageName={profileImageName}
      handleRemoveButton={handleRemoveButton}
      fileSelectHandler={fileSelectHandler}
      loading={loading}
      isMobileView={isMobileView}
    />
  );
};

export default Accountform;
