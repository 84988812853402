/* Header component for the unlock content modal */
import React from 'react';

interface UnlockContentModalHeaderProps {
  balance: number;
}

const UnlockContentModalHeader: React.FC<UnlockContentModalHeaderProps> = (
  props: UnlockContentModalHeaderProps
) => {
  const { balance } =
    props;

  return (
    <p className="mt-2 text-center text-lg text-gray-600">
      Your balance: <b>{balance || ""} credits</b>
    </p>
  );
};

export default UnlockContentModalHeader;
