/* Category list component for the avatar tags */
import React from 'react';

const LoadingIndicator: React.FC = () => (
  <div role="status">
    <div className="w-16 h-16 border-t-9 border-b-4 border-indigo-500 rounded-full animate-spin" />
  </div>
);

export default LoadingIndicator;
