import React from 'react';

import { Avatar, MessageHistoryResponse } from '../../../interfaces';

import { ConnectionStatus } from '../../../reducers/websocket';

import './OutOfChatsChoice.css';

interface OutOfChatChoiceViewProps {
  msgHistory: MessageHistoryResponse;
  activeAvatar: Avatar;
  connectionStatus: ConnectionStatus;
  subscribed: boolean;
  handleSubscription: (id: number) => void;
  recommendedAvatars: Array<Avatar>;
  goToDiscover: () => void;
  goToAvatarProfile: (handle: string) => void;
  isLastNodeLocked: () => boolean;
}

const OutOfChatsChoiceView: React.FC<OutOfChatChoiceViewProps> = (
  props: OutOfChatChoiceViewProps
) => {
  const {
    msgHistory,
    activeAvatar,
    connectionStatus,
    goToDiscover,
    goToAvatarProfile,
    subscribed,
    handleSubscription,
    recommendedAvatars,
    isLastNodeLocked
  } = props;

  return msgHistory &&
    activeAvatar &&
    activeAvatar.id !== 666 &&
    msgHistory[activeAvatar.id] &&
    msgHistory[activeAvatar.id].messageFuture &&
    msgHistory[activeAvatar.id].messageFuture!.length === 0 &&
    msgHistory[activeAvatar.id].msgChoices &&
    msgHistory[activeAvatar.id].msgChoices.length === 0 &&
    msgHistory[activeAvatar.id].isMsgChoicesShown === true &&
    !isLastNodeLocked() &&
    connectionStatus !== ConnectionStatus.DISCONNECTED ? (
      <div>
        <div className="choice-container pt-4">
          <div className="choice-area aooch-choice">
            <span> If you liked chatting with {activeAvatar.avatarName}...</span>
            <ul>
              <li
                role="presentation"
                onClick={() => handleSubscription(activeAvatar.id)}
                className={`narrow ${!subscribed ? 'toggled' : ''}`}
              >
                Notify me when {activeAvatar.avatarName} is available
                <div className="toggle-checkmark mt-2">
                  {subscribed ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-10 w-10"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-10 w-10"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      />
                    </svg>
                  )}
                </div>
              </li>
              {recommendedAvatars.map(
                (el, idx) =>
                  idx < 6 && (
                    <li key={el.id}>
                      <div className="group relative flex-col flex-none bg-black rounded-md">
                        <div role="presentation" onClick={() => goToAvatarProfile(el.handle)}>
                          <div className="absolute z-10 p-4 text-white drop-shadow-md font-semibold rounded-md">
                            <h3 className="text-lg block">{el.avatarName}</h3>
                          </div>
                          <img
                            alt={el.avatarName}
                            src={el.avatarPicture}
                            className="rounded-md hover:rounded-md group-hover:opacity-70 duration-200"
                          />
                        </div>
                      </div>
                    </li>
                  )
              )}
              <li role="presentation" onClick={goToDiscover} className="narrow">
                Check out
                <br />
                others &rarr;
              </li>
            </ul>
          </div>
        </div>
      </div>
  ) : null;
};

export default OutOfChatsChoiceView;
