import React from "react";

interface Props {
  found: boolean;
}

const NoResults: React.FC<Props> = (props: Props) => {
  const { found } = props;
  if (found) {
    return null;
  }

  return (
    <div className="mt-4 py-4 px-4 text-center">
      <h3 className="text-2xl font-bold text-gray-400">No results found</h3>
      <p className="text-gray-400">Why not dive into our library instead!</p>
    </div>
  );
};

export default NoResults;
