import React from 'react';

import { TextInput } from '../../components';

interface ForgotPasswordViewProps {
  formState: string;
  handleChange: ({
    target: { value }
  }: React.ChangeEvent<HTMLInputElement>) => void;
  handleRequestPasswordClick: () => Promise<void>;
}

const ForgotPasswordView: React.FC<ForgotPasswordViewProps> = (
  props: ForgotPasswordViewProps
) => {
  const { formState, handleChange, handleRequestPasswordClick } = props;

  return (
    <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col">
      <div className="mb-4">
        <TextInput
          name="email"
          value={formState}
          handleChange={handleChange}
          label="E-mail"
        />
      </div>
      <div className="flex items-center justify-between">
        <button type="button" onClick={handleRequestPasswordClick}>
          Request password reset link
        </button>
      </div>
    </div>
  );
};

export default ForgotPasswordView;
