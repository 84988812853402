import React from "react";

import { Partnership } from "../../../interfaces";

import "./style.css";

interface Props {
  partnership: Partnership;
}

const Partnerships: React.FC<Props> = (props: Props) => {
  const { partnership } = props;
  return (
    <div className="deal-item">
      <div className="deal-img">
        <a href={partnership.url} target="_blank" rel="noreferrer">
          <img src={partnership.image} alt={partnership.title} />
        </a>
      </div>
      <div className="w-full flex flex-row justify-between items-start mt-2">
        <a href={partnership.url} target="_blank" rel="noreferrer">
          {partnership.title}
          <p className="text-sm text-gray-500 whitespace-pre-wrap">{partnership.description}</p>
        </a>
        <a
          target="_blank"
          href={partnership.url}
          rel="noreferrer"
          className="ml-3 py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Go
        </a>
      </div>
    </div>
  );
};

export default Partnerships;
