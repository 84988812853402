import React from 'react';
import { ToastContainer } from 'react-toastify';

import { User } from '../../interfaces';

import { LoginRegisterForgotPasswordModal, CustomModal, ModalProps } from '../../containers';

import { BottomBar, Sidebar, TranslateModal } from './_components';
import PushChat from './_PushChat';

interface SidebarNavigationIF {
  id: number;
  name: string;
  href: string;
  icon: string;
  current: boolean;
}

interface MainViewProps {
  user: User | undefined;
  unreadsSum: number;
  sidebarNavigation: Array<SidebarNavigationIF>;
  bottomBarNavigation: Array<SidebarNavigationIF>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any;
  isMobileView: boolean;
  open: boolean;
  setOpen: (val:boolean) => void;
  permissionOpen: boolean;
  setPermissionOpen: (val:boolean) => void;
  permissionModalProps: ModalProps;
  modalType: string;
  setModalType: (val:string) => void;
  initModalEmail: string;
  setDirectToPayment: (val:boolean) => void;
  translateModalOpen: boolean;
  setTranslateModalOpen: (val:boolean) => void;
}

const MainView: React.FC<MainViewProps> = (props: MainViewProps) => {
  const {
    user,
    unreadsSum,
    sidebarNavigation,
    children,
    isMobileView,
    open,
    setOpen,
    permissionOpen,
    setPermissionOpen,
    permissionModalProps,
    modalType,
    setModalType,
    initModalEmail,
    setDirectToPayment,
    translateModalOpen,
    setTranslateModalOpen,
    bottomBarNavigation
  } = props;

  const renderSideBar = () => (
    <Sidebar
      isMobileView={isMobileView}
      user={user}
      unreadsSum={unreadsSum}
      sidebarNavigation={sidebarNavigation}
      setOpenMain={setOpen}
      setModalTypeMain={setModalType}
    />
  );

  const renderBottomBar = () => <BottomBar
      user={user}
      unreadsSum={unreadsSum}
      subNavigation={bottomBarNavigation}
      setOpenMain={setOpen}
      setModalTypeMain={setModalType}
    />;

  if (isMobileView) {
    return (
      <div className="flex bg-gray-100">
        {renderSideBar()}
        {children}
        <LoginRegisterForgotPasswordModal
          modalType={modalType}
          initModalEmail={initModalEmail}
          setModalType={setModalType}
          open={open}
          setOpen={setOpen}
          setDirectToPayment={setDirectToPayment}
        />
        <CustomModal open={permissionOpen} setOpen={setPermissionOpen} modalProps={permissionModalProps} />
        {renderBottomBar()}
        <ToastContainer closeButton={false} />
        <PushChat />
      </div>
    );
  }

  return (
      <div className="text-gray-600">
        <div className="absolute inset-0 overflow-hidden bg-gray-100 flex flex-col">
          <div className="flex flex-1 min-h-0 relative overflow-hidden">
            {renderSideBar()}
            {children}
            <LoginRegisterForgotPasswordModal
              modalType={modalType}
              initModalEmail={initModalEmail}
              setModalType={setModalType}
              open={open && !translateModalOpen}
              setOpen={setOpen}
              setDirectToPayment={setDirectToPayment}
            />
            <CustomModal open={permissionOpen} setOpen={setPermissionOpen} modalProps={permissionModalProps} />
            {translateModalOpen && <TranslateModal close={()=>setTranslateModalOpen(false)} />}
          </div>
            {renderBottomBar()}
          <nav className="block md:hidden fixed min-h-20 z-10 flex-shrink-0 bg-gray-800">
            <div className="sm:hidden md:flex lg:flex relative h-20 flex-row md:p-3 lg:p-3 xl:p-3 2xl:p-3 space-x-3">
              {renderSideBar()}
            </div>
          </nav>
        </div>
        <ToastContainer closeButton={false} />
        <PushChat />
      </div>
  );
};

export default MainView;
