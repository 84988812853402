/* Input form component for password change request */
import React from 'react';
import { LockClosedIcon } from '@heroicons/react/solid';

import { ModalFormInput } from '../../components';
import { useForgotPasswordMutation } from '../../reducers/auth';

const ForgotPasswordForm: React.FC = () => {
  const [formState, setFormState] = React.useState<string>('');

  const [sendRequest] = useForgotPasswordMutation();

  const handleChange = ({
    target: { value }
  }: React.ChangeEvent<HTMLInputElement>) => setFormState(value);

  const handleRequestNewPassword = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await sendRequest(formState).unwrap();
    } catch (err) {
      console.log('Error while requesting new password!');
    }
  };

  return (
    <form onSubmit={handleRequestNewPassword} className="mt-8 space-y-6" id="forgotPasswordModal">
      <ModalFormInput
        label="Email address"
        name="email"
        required
        value={formState}
        handleChange={handleChange}
        autoComplete="email"
        placeHolder="Enter your email address"
      />

      <div className="mt-6">
        <button
          id="forgotPasswordModalButton"
          type="submit"
          className="group relative w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          <span className="absolute left-0 inset-y-0 flex items-center pl-3">
            <LockClosedIcon
              className="h-5 w-5 text-blue-400 group-hover:text-blue-400"
              aria-hidden="true"
            />
          </span>
          Request new password
        </button>
      </div>
    </form>
  );
};

export default ForgotPasswordForm;
