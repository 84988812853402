/* Billing settings form component */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { toast } from 'react-toastify';

import { BillingMembership, PaymentType } from '../../../interfaces';

import { ConfirmationModalProps } from '../../../components';
import { useAppDispatch, useAppSelector } from "../../../reducers/hooks";
import {
  setPaymentType,
  useActivateMembershipMutation,
  useCancelMembershipMutation,
  useGetBillingSettingsQuery
} from '../../../reducers/payment';

import BillingView from './BillingView';

export interface BillingProps {
  setOpenPurchaseHistory: (value: boolean) => void;
  isMobileView: boolean;
  setOpen: (val: boolean) => void;
}

const Billing: React.FC<BillingProps> = (props: BillingProps) => {
  const { setOpenPurchaseHistory, isMobileView, setOpen} = props;
  const credits = useAppSelector((state) => state.user.user?.credits);
  const dispatch = useAppDispatch();

  const { data: billingSettings } = useGetBillingSettingsQuery();

  const [cancelMembership] = useCancelMembershipMutation();
  const [activateMembership] = useActivateMembershipMutation();

  const history = useHistory();

  const closeCancelMembershipModal = () => {
    setCancelMembershipModalOpen(false);
  };

  const cancelMembershipAsync = async (avatarHandler: string) => {
    const response = await cancelMembership(avatarHandler).unwrap();
    if (response.status === 'success') {
      toast.success('Membership cancelled', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
    }
    closeCancelMembershipModal();
  };

  const initCancelMembershipProps = {
    close: closeCancelMembershipModal,
    handleModalAction: cancelMembershipAsync,
    handleModalActionParameter: '',
    modalText: null,
    leftBtnText: '',
    rightBtnText: '',
    modalTitle: '',
    avatarHandle: '',
  };

  const [isCancelMembershipModalOpen, setCancelMembershipModalOpen] = useState<boolean>(false);
  const [cancelMembershipProps, setCancelMembershipProps] = useState<ConfirmationModalProps>(initCancelMembershipProps);

  const setPaymentTypeState = (newPaymentType: PaymentType) => {
    dispatch(setPaymentType(newPaymentType));
  };

  const renewMembershipAsync = async (avatarHandler: string) => {
    if (billingSettings?.memberships) {
      const response = await activateMembership(avatarHandler).unwrap();
      if (response.status === 'success') {
        toast.success('Avatar membership renewed', {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
      }
    }
  };

  const handleCancelMembership = (membership: BillingMembership) => {
    const newProps = cancelMembershipProps;
    newProps.handleModalActionParameter = membership.avatar.avatarName;
    newProps.rightBtnText = "Cancel membership";
    newProps.leftBtnText = "Keep my membership";
    newProps.modalText = <p className="text-sm text-gray-500">Are you sure you want to cancel your membership with <b>{membership.avatar.avatarName}</b>? You will continue to keep {membership.memberName} privileges until {moment(membership.nextPaymentAt).format('D MMMM')}.</p>;
    newProps.modalTitle = "Confirm cancelation";
    newProps.avatarHandle = membership.avatar.handle;
    setCancelMembershipProps(newProps);
    setCancelMembershipModalOpen(true);
  };

  const renderSubscriptions = () => {
    if (billingSettings?.subscriptions?.length === 0) {
      return (
        <div key={0} className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5">
        <dt className="text-sm font-medium text-gray-500">Credit subscription</dt>
        <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          <span className="flex-grow">-</span>
          <span className="ml-4 flex-shrink-0">
            <button type="button" id="billingCreditSubscriptionButton" onClick={() => {setPaymentTypeState(PaymentType.SUBSCRIPTION); setOpen(true);}} className="rounded font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">Subscribe</button>
          </span>
        </dd>
      </div>
      );
    }

    const subscription = billingSettings?.subscriptions?.[0];
    return (
      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5">
        <dt className="text-sm font-medium text-gray-500">Credit subscription</dt>
        <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          <span className="flex-grow">{subscription?.product?.creditAmount} credits/mo</span>
          <span className="ml-4 flex-shrink-0">
            {/* TODO: Add onClick once subscription change is working */}
            <button type="button" className="rounded font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">Change</button>
          </span>
        </dd>
      </div>
    );
  };


  const renderMemberships = () => {
    if (!billingSettings?.memberships) return null;
    if (billingSettings?.memberships?.length === 0) {
      return (
        <li>
          <div className="text-center py-4">
            <h3 className="text-gray-400 text-lg font-semibold">No active memberships</h3>
            <p className="text-gray-400 text-base">You are not subscribed to anyone at the moment.</p>
          </div>
      </li>
      );
    }

    const renderedList = billingSettings?.memberships?.map(membership => (
      <a style={{display: 'list-item'}} role="button" tabIndex={0} key={membership.id} onClick={() => history.push(`/${membership.avatar.handle}`, {from: "settings"})} onKeyDown={() => history.push(`/${membership.avatar.handle}`, {from: "settings"})}>
        <div className="py-4 flex cursor-pointer">
          <img className="h-10 w-10 rounded-full" src={membership.avatar.avatarPicture} alt="" />
          <div className="ml-3 flex flex-1 flex-col">
            <span className="font-medium text-gray-900">{membership.avatar.avatarName}</span>
            <span className="text-gray-500">{membership.memberName}</span>
          </div>
          <div className="flex-1">{membership.price} credits /mo</div>
          <div className="flex-1">{membership.status === "cancelled" ? `ends at: ${moment(membership.nextPaymentAt).format('D MMM YYYY, HH:mm')}` : ''}</div>
          <div>
            <span className="ml-4 flex-shrink-1 flex items-start space-x-4">
              <button onClick={(e) => {e.stopPropagation(); if (membership.status === "cancelled") renewMembershipAsync(membership.avatar.handle); else handleCancelMembership(membership);}} type="button" className="rounded font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">{membership.status === "cancelled" ? "Renew" : "Cancel"}</button>
            </span>
          </div>
        </div>
      </a>
    ));

    return renderedList;
  };

  return (
    <BillingView
      isMobileView={isMobileView}
      setOpen={setOpen}
      isCancelMembershipModalOpen={isCancelMembershipModalOpen}
      cancelMembershipProps={cancelMembershipProps}
      credits={credits}
      setPaymentType={setPaymentTypeState}
      renderSubscriptions={renderSubscriptions}
      setOpenPurchaseHistory={setOpenPurchaseHistory}
      renderMemberships={renderMemberships}
    />
  );
};

export default Billing;
