import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';

import type { LoginRequest } from '../../interfaces';

import { persistor } from '../../reducers/store';
import { useAppDispatch } from "../../reducers/hooks";
import { useLoginMutation } from '../../reducers/auth';
import { setCredentials } from '../../reducers/user';
import { setFriendAvatars, useGetAllAvatarByUserMutation } from '../../reducers/avatar';

import LoginView from './LoginView';

const Login = (): ReactElement => {
  const dispatch = useAppDispatch();
  const { push } = useHistory();

  const [formState, setFormState] = React.useState<LoginRequest>({
    email: '',
    password: '',
  });

  const [login] = useLoginMutation();

  const [getAvatars] = useGetAllAvatarByUserMutation();
  
  const handleChange = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) =>
    setFormState((prev) => ({ ...prev, [name]: value }));
  
  const redirect = async () => {
    const avatars = await getAvatars(null).unwrap();

    if (!avatars || avatars.length === 0) {
      push('/discover');
    } else {
      push('/chat');
    }

    dispatch(setFriendAvatars(avatars));
  };

  const handleLoginClick = async () => {
    try {
      persistor.purge();
      const user = await login(formState).unwrap();
      dispatch(setCredentials(user));
      redirect();
    } catch (err) {
      setFormState((prev) => ({ ...prev, 'password': '' }));
    }
  };

  return (
    <LoginView
      handleLoginClick={handleLoginClick}
      formState={formState}
      handleChange={handleChange}
    />
  );
};

export default Login;
