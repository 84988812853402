/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import { ContactListState, MessageNode, Avatar, OrderedAvatars } from '../../../interfaces';

import { ConfirmationModalProps } from '../../../components';
import { useAppDispatch, useAppSelector } from "../../../reducers/hooks";
import { setAvatarFriends } from '../../../reducers/user';
import {
  setActiveAvatar,
  setLoadedAvatars,
  setFriendAvatars,
  setNewChatNotifications,
  useGetAllAvatarByUserMutation,
  useUnfriendAvatarMutation
} from '../../../reducers/avatar';
import WSApi from '../../../services/ws-api-handler/ws-api';

import ContactListView from './ContactlistView';

export interface ContactListProps {
  screenViewType: string;
  setSlideInContactList: (value: boolean) => void;
  setOpenContactList: (value: boolean) => void;
  unfriendAvatarProps: ConfirmationModalProps;
  setUnfriendAvatarProps: (val: ConfirmationModalProps) => void;
  setUnfriendAvatarModalOpen: (val: boolean) => void;
  setCloseChatForm: (val: boolean) => void;
  isMobileView: boolean;
  slideInContactList: boolean;
}

const ContactList: React.FC<ContactListProps> = (props: ContactListProps) => {
  const {
    screenViewType,
    setSlideInContactList,
    setOpenContactList,
    unfriendAvatarProps,
    setUnfriendAvatarProps,
    setUnfriendAvatarModalOpen,
    isMobileView,
    setCloseChatForm,
    slideInContactList,
  } = props;
  const { ws } = WSApi;
  const {
    activeAvatar,
    friendAvatars,
    loadedAvatarIds,
    unreadsByAvatar,
    newChatNotifications,
    msgHistoryStore,
    accessToken,
  } = useAppSelector((state) => ({
    activeAvatar: state.avatar.activeAvatar,
    friendAvatars: state.avatar.friendAvatars,
    loadedAvatarIds: state.avatar.loadedAvatarIds,
    unreadsByAvatar: state.avatar.unreads,
    newChatNotifications: state.avatar.newChatNotifications,
    msgHistoryStore: state.base.msgHistory,
    accessToken: state.user?.accessToken,
  }));

  const [avatarState, setAvatarState] = React.useState<ContactListState>({
    avatars: []
  });
  const [unfriendAvatar] = useUnfriendAvatarMutation();
  const [getAvatars] = useGetAllAvatarByUserMutation();
  const location = useLocation();
  const routerHistory = useHistory();
  const dispatch = useAppDispatch();

  useEffect(() => {
    // On mount set avatars to component state and actual avatars to state and store
    if (friendAvatars) {
      if(friendAvatars.length > 0) {
        setAvatars();
      } else {
        setAvatarState(prev => ({ ...prev, avatars: [] }));
      }
    }
  }, [friendAvatars]);

  useEffect(() => {
    // On mount set avatars to component state and actual avatars to state and store
    if (activeAvatar) {
      setSlideInContactList(false);
    }
  }, [activeAvatar]);

  useEffect(() => {
    // If the user is redirected to chat page after clickin on a message, send a
    // a boolean value (render) to chat page. This indicates that an avatar was added
    // for the user's friend list, and should re-fetch the avatars from the backend.
    if (location?.state?.render) {
      setAvatars();

      if(location.state.openChat) {
        setOpenContactList(false);
        setSlideInContactList(false);
      }
      orderAvatarsByMessages();
    }
  }, [location.state]);

  useEffect(() => {
    if(activeAvatar) {
      if(!loadedAvatarIds.includes(activeAvatar.id)) {
        setMsgHistory();
        const tempLoadedList = JSON.parse(JSON.stringify(loadedAvatarIds));
        tempLoadedList.push(activeAvatar.id);
        dispatch(setLoadedAvatars(tempLoadedList));
        setAvatarState(prev => ({ ...prev, isLoaded: tempLoadedList }));
      } else {
        setMsgHistory();
      }
    }
  }, [activeAvatar]);

  // When new avatar selected (clicked) from list load data for given avatar
  const setActiveAvatarInStore = async (avatar: Avatar | undefined) => {
    // Set selected avatar to store
    if(avatar)
      dispatch(setActiveAvatar(avatar));
    else
    dispatch(setActiveAvatar(undefined));
  };

  const setAvatars = async () => {
    // Request avatars from Backend api with RTK mutation
    const avatars = friendAvatars;

    if (!avatars || avatars.length === 0) {
      dispatch(setAvatarFriends(false));
    } else {
      dispatch(setAvatarFriends(true));
    }

    // Setting the selected avatar to the store and state only if the user has at least one avatar friend
    if (avatars && avatars.length !== 0) {
      // Setting user's avatar friends to state.

      setAvatarState(prev => ({ ...prev, avatars }));
    }
  };

  useEffect(() => {
    if (msgHistoryStore && friendAvatars && friendAvatars.length) {
      for (let i=0; i < friendAvatars.length; i += 1) {
        const msgLen = msgHistoryStore[friendAvatars[i].id]?.messageHistory?.length;
        if(
          msgLen === 1 &&
          !newChatNotifications.includes(friendAvatars[i].id)
        ) {
          dispatch(setNewChatNotifications([...newChatNotifications, friendAvatars[i].id]));
        }
      }
    }
  }, [msgHistoryStore]);

  useEffect(() => {
    // Sort only if there are more than two avatars in the contact list
    if(friendAvatars.length > 1) {
      orderAvatarsByMessages();
    }
  }, [msgHistoryStore]);

  const orderAvatarsByMessages = () => {
    const msgHistory = msgHistoryStore;
    const messagesWithTime : Array<OrderedAvatars> = [];
    const orderedAvatars : Array<Avatar> = [];
    const avatarsWithoutMessages : Array<Avatar> = [];
    
    const avatars = friendAvatars;

    avatars.forEach(avt => {
      if (
        msgHistory &&
        msgHistory[avt.id] &&
        msgHistory[avt.id].messageHistory
      )
      {
        // Length of the messages of the active avatar
        const msgLen =  msgHistory[avt.id].messageHistory.length;

        if(msgLen !== 0) {
          // Get the last message from the active avatar's message history
          const lastMsg = msgHistory[avt.id].messageHistory[msgLen-1];
          messagesWithTime.push({id: avt.id, time: lastMsg.displayTime});
        } else {
          // When adding a new friend the message history is empty
          // Add the object to the sortable array with the avatar id and
          // an empty string for the time
          avatarsWithoutMessages.push(avt);
        }
      } else {
        avatarsWithoutMessages.push(avt);
      }
    });

    // Sort the avatars messages
    messagesWithTime.sort((a: OrderedAvatars, b: OrderedAvatars) => new Date(a.time).getTime() < new Date(b.time).getTime() ? 1 : -1);

    // Loop through the sorted array and find the avatar by id
    messagesWithTime.forEach(el => {
      const foundAvatar = avatars.find(avt => el.id === avt.id);
      if(foundAvatar)
        orderedAvatars.push(foundAvatar);
    });
    // Set the state with the ordered avatar list
    setAvatarState(prev => ({ ...prev, avatars: [...avatarsWithoutMessages,...orderedAvatars] }));
  };

  const setMsgHistory = async () => {
    // Request msg history with avatar
    const history = msgHistoryStore;

    // Emit initialization request for WS for active Avatar
    // If there is chat history the last node, if there is not then undefined
    if (
      history &&
      activeAvatar &&
      history[activeAvatar.id] &&
      history[activeAvatar.id].messageHistory.length > 0
    ) {
      let node: MessageNode = history[activeAvatar.id].messageHistory[
        history[String(activeAvatar.id)].messageHistory.length - 1
      ];
      if (
        history[activeAvatar.id].messageFuture &&
        history[activeAvatar.id].messageFuture!.length > 0
      ) {
        node = history[activeAvatar.id].messageFuture![
          history[String(activeAvatar.id)].messageFuture!.length - 1
        ];
      }
      ws.sendMessageInit(accessToken as string, node, location.state?.chatId);
    } else if (activeAvatar) {
      ws.sendMessageInit(accessToken as string, undefined, location.state?.chatId);
    }
  };

  const closeContactList = () => {
    if (screenViewType === 'mobile') {
      setSlideInContactList(false);
    }
    setCloseChatForm(false);
  };

  const handleUnfriend = async (id: number) => {
    // Prevent the onClick evenet to fire on the div,
    // which is beneath the modal menu
    // evt.stopPropagation();
    await unfriendAvatar(id).unwrap();
    const avatars = await getAvatars(null).unwrap();

    // If the activeAvatar was unfriended set it to undefined
    if(activeAvatar?.id === id)
      handleActiveAvatarChange(undefined);
    
    if(avatars && avatars) {
      dispatch(setFriendAvatars(avatars));
    }

    if(setUnfriendAvatarModalOpen)
    setUnfriendAvatarModalOpen(false);

  };
  
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleUnfriendButtonClick = (evt: any, id: number) => {
    evt.stopPropagation();

    const avatarName = friendAvatars.find( fa => fa.id === id)?.avatarName;

    const newProps = unfriendAvatarProps;
    if(newProps) {
      newProps.handleModalAction = () => handleUnfriend(id);
      newProps.handleModalActionParameter = avatarName;
      newProps.rightBtnText = "Unfriend";
      newProps.leftBtnText = "Keep contact";
      newProps.modalText = <p className="text-sm text-gray-500">Are you sure you want to remove <b>{avatarName}</b> from your contacts?</p>;
      newProps.modalTitle = "Are you sure?";
      if(setUnfriendAvatarProps)
        setUnfriendAvatarProps(newProps);
      if(setUnfriendAvatarModalOpen)
        setUnfriendAvatarModalOpen(true);
    }
  };

  const handleActiveAvatarChange = (avatar: Avatar | undefined) => {
    setActiveAvatarInStore(avatar);
    if (avatar?.handle) {
      routerHistory.push(`/chat/${avatar?.handle}`);
    } else {
      routerHistory.push('/chat');
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleGoToProfile = (evt: any, avatarHandle: string) => {
    evt.stopPropagation();
    routerHistory.push(`/${avatarHandle}`);
  };

  const scrollRef = React.createRef<HTMLSpanElement>();

  return (
    <ContactListView
      avatars={avatarState.avatars}
      unreadsByAvatar={unreadsByAvatar}
      closeContactList={closeContactList}
      activeAvatar={activeAvatar as Avatar}
      screenViewType={screenViewType}
      handleUnfriendButtonClick={handleUnfriendButtonClick}
      handleActiveAvatarChange={handleActiveAvatarChange}
      handleGoToProfile={handleGoToProfile}
      isMobileView={isMobileView}
      scrollRef={scrollRef}
      newChatNotifications={newChatNotifications}
      slideInContactList={slideInContactList}
    />
  );
};

export default ContactList;
