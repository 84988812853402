import { Partnership } from '../../interfaces';

import baseApi from '../api';

export const api = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getPartnerships: builder.query<Array<Partnership>, void>({
      query: () => ({
        url: '/partnerships/',
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetPartnershipsQuery,
} = api;
