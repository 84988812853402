/* Modal component for content unlock functionality */
import React from 'react';
import moment from 'moment';

import { Avatar, User, PayWallOrSkipMessageType } from '../../interfaces';

import { useAppDispatch, useAppSelector } from "../../reducers/hooks";
import {
  useUnlockContentMutation,
  unlockLockedNode,
  unlock,
  setCurrentReference,
  useSkipMessageWaitMutation,
  useUnlockLockedNodeMutation
} from '../../reducers/base';
import { updateCredits } from '../../reducers/user';

import UnlockContentModalView from './UnlockContentModalView';
import { setUnlockPayWallOrSkipMsg } from '../../reducers/payment';

interface UnlockContentModalProps {
  contentModalOpen: boolean;
  setContentModalOpen: (val:boolean) => void;
  setPaymentModalOpen: (val:boolean) => void;
  avatar?: Avatar;
  contentCreditCost?: number | undefined;
  contentNodeId?: number | undefined;
  refresh?: (val:boolean) => void;
  isPaymentModalOpen?: boolean;
}

const UnlockContentModal: React.FC<UnlockContentModalProps> = (props: UnlockContentModalProps) => {
  const {
    contentModalOpen,
    setContentModalOpen,
    setPaymentModalOpen,
    avatar,
    contentCreditCost,
    contentNodeId,
    refresh,
    isPaymentModalOpen
  } = props;
  
  const dispatch = useAppDispatch();
  const { user, unlockPayWallOrSkipMsg, msgHistory } = useAppSelector((state) => ({
    user: state.user.user,
    unlockPayWallOrSkipMsg: state.payment.unlockPayWallOrSkipMsg,
    msgHistory: state.base.msgHistory,
  }));
  const [unlockContent, { isLoading: isContentLoading }] = useUnlockContentMutation();
  const [skipMessageWait, { isLoading: isSkipLoading }] = useSkipMessageWaitMutation();
  const [unlockNode, { isLoading: isPaywallLoading }] = useUnlockLockedNodeMutation();

  // For testing the unlock function, change the user.credits to number greater than the contentCreditCost (20 is fine)
  const userCreditbalance = user?.credits;

  React.useEffect(() => {
    if(contentModalOpen){
      // If the user's does not have enough credits to unlock the content,
      // Render the Buy credits modal
      if(contentCreditCost && (userCreditbalance || userCreditbalance === 0)) {
        if(userCreditbalance < contentCreditCost) {
          setPaymentModalOpen(true);
          setContentModalOpen(false);
          }
        }
      }
  },[contentModalOpen]);

  const handleUnlockPaywall = async (nodeId: number) => {
    let credits;
    if(unlockPayWallOrSkipMsg.avatarId === avatar?.id && unlockPayWallOrSkipMsg.unlock) {
      if(msgHistory && avatar && msgHistory[avatar.id]) {
          const historyLength = msgHistory[avatar.id].messageHistory?.length;
          if(historyLength >= 1) {
              const resp = await unlockNode({nodeId, avatarId: avatar?.id}).unwrap();
              if(resp.status !== "fail") {
                dispatch(unlockLockedNode({avatarId: avatar.id}));
                credits = resp.credits;
              }
          }
        }
    }
    return credits;
  };

  const handleSkipMessageWait = async (nodeId: number) => {
    let credits;
    if(unlockPayWallOrSkipMsg.avatarId === avatar?.id && unlockPayWallOrSkipMsg.unlock &&
      msgHistory && avatar && msgHistory[avatar.id] &&
      msgHistory[avatar.id].messageFuture &&
      msgHistory[avatar.id].messageHistory) {
       const foundHistoryMsg =  msgHistory[avatar.id].messageHistory!.find(msg => msg.nodeId === contentNodeId);
       if(foundHistoryMsg && foundHistoryMsg.text === "Skip the wait") {
         const foundFutureMsg = msgHistory[avatar.id].messageFuture!.find(msg => msg.nodeId === contentNodeId);
         if(foundFutureMsg) {
          if (user && (!user.credits || (user.credits && user.credits <= 0))) {
            setContentModalOpen(false);
            setPaymentModalOpen(true);
            credits = user.credits;
          } else {
            const delayFromSecondMsg = moment(foundFutureMsg.displayTime).diff(moment(), 'seconds');
            if(delayFromSecondMsg <= 3) {
              setContentModalOpen(false);
              setPaymentModalOpen(false);
              handlePayWallSkipMsgModalClose();
              return credits;
            }
            const resp = await skipMessageWait({nodeId, avatarId: avatar?.id}).unwrap();
            credits = resp.credits;
            setContentModalOpen(false);
            handlePayWallSkipMsgModalClose();
          }
        }
       }
     }
     return credits;
  };

  const handlePayWallSkipMsgModalClose = () => {
    if(unlockPayWallOrSkipMsg.type === PayWallOrSkipMessageType.PAY_WALL && unlockPayWallOrSkipMsg.unlock)
    dispatch(setUnlockPayWallOrSkipMsg({type: undefined, avatarId: avatar?.id, unlock: false}));

  if(unlockPayWallOrSkipMsg.type === PayWallOrSkipMessageType.SKIP_THE_WAIT && unlockPayWallOrSkipMsg.unlock)
    dispatch(setUnlockPayWallOrSkipMsg({type: undefined, avatarId: avatar?.id, unlock: false}));
  };

  const handleClick = async (action: string) => {
    if(action === "Cancel") {
      setContentModalOpen(false);
      handlePayWallSkipMsgModalClose();
    } else {
      let credits : (number | undefined) = 0;
      if(contentNodeId && contentCreditCost && userCreditbalance) {
        if(unlockPayWallOrSkipMsg.type === PayWallOrSkipMessageType.PAY_WALL) {
          credits = await handleUnlockPaywall(contentNodeId);
        } else if(unlockPayWallOrSkipMsg.type === PayWallOrSkipMessageType.SKIP_THE_WAIT) {
          credits = await handleSkipMessageWait(contentNodeId);
        } else {
          const resp = await unlockContent(contentNodeId!).unwrap();
          credits = resp.credits;
          dispatch(unlock(contentNodeId!));
        }
        // Substract content credits amount from user credits and set it to the store
        if (credits || credits === 0) {
          dispatch(updateCredits(credits));
        }
        if(contentModalOpen)
          setContentModalOpen(false);
        if(isPaymentModalOpen)
          setPaymentModalOpen(false);
        // Set this state variable true, so the unlocked content in the Gallery
        // coould appear after the unlock
        if(refresh) refresh(true);
        }

    }
    dispatch(setCurrentReference(undefined));
  };

  const onClose = () => {
    dispatch(setCurrentReference(undefined));
    setContentModalOpen(false);
    handlePayWallSkipMsgModalClose();
  };

  return (
    <UnlockContentModalView
      contentModalOpen={contentModalOpen}
      setContentModalOpen={setContentModalOpen}
      onClose={onClose}
      avatar={avatar}
      user={user as User}
      contentCreditCost={contentCreditCost}
      handleClick={handleClick}
      userCreditbalance={userCreditbalance as number}
      buttonDisabled={isContentLoading || isPaywallLoading || isSkipLoading}
    />
  );
};

export default UnlockContentModal;
