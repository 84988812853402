/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';

const ServerErrorView: React.FC = () => (
  <main className="flex-1 border-t border-gray-200 h-screen">
    <section className="flex-1 h-full flex flex-col">
      <div className="flex-1 justify-between flex flex-col">
        <ul className="gallery-ul"/>
        <div className="content-center m-auto text-center">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-40 w-40 text-red-300 m-auto" fill="currentColor" viewBox="0 0 20 20" stroke="currentColor">
          <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
        </svg>
        <h3 className="text-gray-600 font-bold text-2xl">We encountered an error</h3>
        <p className="text-gray-600">Something went wrong. Please try again later!</p>
        </div>
      </div>
    </section>
  </main>
);

export default ServerErrorView;