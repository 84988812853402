/* eslint-disable jsx-a11y/no-redundant-roles */
import React from 'react';

import { PaymentType } from '../../../interfaces';

import { ConfirmationModal, ConfirmationModalProps } from '../../../components';

interface Props {
  setOpen: (isOpen: boolean) => void;
  isCancelMembershipModalOpen: boolean;
  cancelMembershipProps: ConfirmationModalProps;
  credits: number | undefined;
  setPaymentType: (newPaymentType: PaymentType) => void;
  renderSubscriptions: () => JSX.Element;
  setOpenPurchaseHistory: (value: boolean) => void;
  renderMemberships: () => JSX.Element[] | JSX.Element | null;
  isMobileView: boolean;
}

const BillingView: React.FC<Props> = (props: Props) => {
  const {
    setOpen,
    isCancelMembershipModalOpen,
    cancelMembershipProps,
    credits,
    setPaymentType,
    renderSubscriptions,
    setOpenPurchaseHistory,
    renderMemberships,
    isMobileView
  } = props;

  return (
    <main className={`${isMobileView && "flex h-screen bg-gray-100"} flex-1 border-t border-gray-200 lg:flex`}>
      {isCancelMembershipModalOpen ? (
        <ConfirmationModal {...cancelMembershipProps} />
      ) : null}
      <div className={`${!isMobileView ? "flex-1 max-h-screen overflow-y-auto pb-16" : "py-10 px-4 pt-20 pb-16"}`}>
        <div className={`${!isMobileView && "max-w-3xl mx-auto py-10 px-4 sm:px-6 lg:py-12 lg:px-8"}`}>
          <h1 className="text-3xl font-extrabold text-blue-gray-900">
            Billing
          </h1>
          <div className="mt-6">
            {/* Credits section */}
            <div className="mt-10 divide-y divide-gray-200">
              <div className="space-y-1">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Credits
                </h3>
              </div>
              <div className="mt-6">
                <dl className="divide-y divide-gray-200">
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                    <dt className="text-sm font-medium text-gray-500">
                      Your credits
                    </dt>
                    <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      <span className="flex-grow font-bold">
                        {credits != null ? `${credits} credits` : '-'}
                      </span>
                      <span className="ml-4 flex-shrink-0">
                        <button
                          id="billingBuyCreditsButton"
                          type="button"
                          onClick={() => {
                            setPaymentType(PaymentType.CREDIT);
                            setOpen(true);
                          }}
                          className="rounded font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        >
                          Buy credits
                        </button>
                      </span>
                    </dd>
                  </div>
                  {renderSubscriptions()}
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-b sm:border-gray-200">
                    <dt className="text-sm font-medium text-gray-500">
                      Purchase history
                    </dt>
                    <dd className="mt-1 flex flex-grow text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      <span className="flex-grow" />
                      <span className="ml-4 flex-shrink-0">
                        <button
                          id="billingViewPurchaseHistoryButton"
                          type="button"
                          onClick={() => setOpenPurchaseHistory(true)}
                          className="rounded font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        >
                          View
                        </button>
                      </span>
                    </dd>
                  </div>
                </dl>
              </div>
              <div className="py-4 sm:py-5 text-xs text-gray-500">
                For billing support, contact <a href="http://cs.segpay.com/" target="_blank" rel="noreferrer" className="text-blue-600 underline">Segpay</a>.
              </div>
            </div>
            {/* Memberships section */}
            <div className={`mt-10 divide-y divide-gray-200 ${isMobileView && "pb-20"}`}>
              <div className="space-y-1">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Memberships
                </h3>
                {/* <p className="max-w-2xl text-sm text-gray-500">Help text placeholder, if needed</p> */}
              </div>
              <div className="mt-6">
                <ul role="list" className="divide-y divide-gray-200 text-sm">
                  {renderMemberships && renderMemberships()}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default BillingView;
