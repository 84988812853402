/* Avatar feed component for the avatar profile page */
import React from 'react';

import { Post } from '../../../interfaces';

import AvatarPost from './AvatarPost';

interface AvatarFeedProps {
  posts?: Array<Post>;
  avatarName: string;
  avatarHandle: string;
  avatarPicture: string;
  handleMediaClick: (url: string) => void;
  isMobileView: boolean;
}

const AvatarFeed: React.FC<AvatarFeedProps> = (props: AvatarFeedProps) => {
  const {
    posts,
    avatarName,
    avatarHandle,
    avatarPicture,
    handleMediaClick,
    isMobileView
  } = props;

  return (
    <div className={`mt-2 px-4 sm:px-6 py-4 lg:px-8 bg-white shadow-sm ${isMobileView && "pb-20"}`}>
      <h5 className="uppercase tracking-wider font-medium text-xs md:text-sm text-gray-500 mb-1">
        Latest posts
      </h5>
      <div className="flex-1 items-center text-center">
        {posts?.length ? posts.map(post => (
          <AvatarPost
            key={post.id}
            post={post}
            avatarName={avatarName}
            avatarHandle={avatarHandle}
            profileImageUrl={avatarPicture}
            handleMediaClick={handleMediaClick}
            />
        ))
        : <h1>No posts yet</h1> }
      </div>
    </div>
  );
};

export default AvatarFeed;
