/* Floater component for anonymous chat to display login / signup possibilities */
import React from 'react';
import { HeartIcon } from '@heroicons/react/outline';

interface Props {
  avatarName: string;
  setModalType: (val: string) => void;
  setOpen: (val: boolean) => void;
  isMobileView: boolean;
}

const LoginSignupFloater: React.FC<Props> = (
  props: Props
) => {
  const { avatarName, setModalType, setOpen, isMobileView} = props;

  const handleLoginSignupClick = (opt: string) => {
    setOpen(true);
    if (opt === 'register') setModalType('register');
    else setModalType('login');
  };

  return (
    <div className={`${isMobileView ? "fixed" : "sticky"} z-10 ${isMobileView ? "top-20" : "top-0"} mb-5 inset-x-0 pt-2 sm:pt-5`}>
      <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
        <div className="p-2 rounded-lg bg-blue-600 shadow-lg sm:p-3">
          <div className="flex items-center justify-between flex-wrap">
            <div className="w-full flex-1 flex items-center">
              <span className="flex p-1">
                <HeartIcon className="h-6 w-6 text-white" aria-hidden="true" />
              </span>
              <p className="ml-1 text-sm font-medium text-white truncate">
                Sign up to save your chat with {avatarName}
              </p>
            </div>
            <div className="flex w-full sm:w-auto gap-2 sm:gap-0">
              <div className="order-2 mt-2 flex-1 w-full sm:mr-2 sm:mt-0 sm:w-auto">
                <button
                  type="button"
                  onClick={() => handleLoginSignupClick('login')}
                  className="flex items-center justify-center px-4 py-2 border border-white rounded-md shadow-sm text-xs sm:text-sm font-medium text-white hover:bg-blue-500 truncate w-full"
                >
                  Log in
                </button>
              </div>
              <div className="order-3 mt-2 flex-1 w-full sm:mt-0 sm:w-auto">
                <button
                  type="button"
                  onClick={() => handleLoginSignupClick('register')}
                  className="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-xs sm:text-sm font-medium text-blue-600 bg-white hover:bg-blue-50 truncate w-full"
                >
                  Register
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginSignupFloater;
