/* Avatar image and description component for the brwose page */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';

interface DiscoverBrowseAvatarItemProps {
  image: string;
  mainText: string;
  secondaryText: string;
  onClick?: () => void;
}

const DiscoverBrowseAvatarItem: React.FC<DiscoverBrowseAvatarItemProps> = (props: DiscoverBrowseAvatarItemProps) => {
  const { image, mainText, secondaryText, onClick } = props;

  return (
    <div className="browse-item cursor-pointer" onClick={() => onClick && onClick()}>
      <div className="browse-avatar-lg">
        <img src={image} alt="alt" />
      </div>
      <div className="browse-link">
        <a>
          {mainText}
          <p className="text-sm text-gray-500 break-normal">{secondaryText}</p>
        </a>
      </div>
    </div>
  );
};

export default DiscoverBrowseAvatarItem;
