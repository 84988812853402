import React from 'react';
import { LockClosedIcon } from '@heroicons/react/solid';

import { ResetPasswordRequest } from '../../interfaces';

import { ModalFormInput } from '../../components';

interface ResetPasswordViewProps {
  formState: ResetPasswordRequest;
  handleChange: ({
    target: { name, value }
  }: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: () => Promise<void>;
}

const ResetPasswordView: React.FC<ResetPasswordViewProps> = (
  props: ResetPasswordViewProps
) => {
  const { formState, handleChange, onSubmit } = props;

  return (
    <div className="fixed z-40 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 transition-opacity" aria-hidden="true" />
        <span className="hidden sm:inline-block align-middle h-screen" aria-hidden="true">&#8203;</span>
        <div className="inline-block align-bottom bg-white rounded-lg px-4 p-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full sm:p-6">
          <div className="">
            <img className="mx-auto h-12 w-auto" src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg" alt="Workflow" />
            <h2 className="mt-6 text-center text-xl sm:text-2xl font-bold text-gray-900">Reset your password</h2>
          </div>

          <form className="mt-8 space-y-6" action="#" method="POST">
            <ModalFormInput
              label="New password"
              name="newPassword1"
              required
              value={formState.newPassword1}
              handleChange={handleChange}
              autoComplete="password"
              placeHolder="Choose a new password"
              type="password"
            />
            <ModalFormInput
              label="Confirm new password"
              name="newPassword2"
              required
              value={formState.newPassword2}
              handleChange={handleChange}
              autoComplete="password"
              placeHolder="Enter the new password again"
              type="password"
            />
            <div className="mt-6">
              <button
                type="button"
                className="group relative w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                onClick={onSubmit}
              >
                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                  <LockClosedIcon
                    className="h-5 w-5 text-blue-400 group-hover:text-blue-400"
                    aria-hidden="true"
                  />
                </span>
                Set new password
              </button>
            </div>
          </form>

        </div>
      </div>
    </div>
  );
};

export default ResetPasswordView;
