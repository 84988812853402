/* Input field component for the account page */
import React, { ChangeEventHandler } from 'react';

import { ProfilePicture } from '../../../components';

interface AccountFormInputInterface {
  type: string;
  name: string;
  id: string;
  value?: string;
  handleChange: ChangeEventHandler<HTMLInputElement>;
  handleRemoveButton?: () => void;
  className?: string;
  autoComplete?: string;
  divClassName?: string;
  label?: string;
  htmlFor?: string;
  isUserName?: boolean;
  isImage?: boolean;
  errors?: string;
  imgName?: string;
  nickname?: string;
  firstName?: string;
  lastName?: string;
  loading?: boolean;
}

const AccountFormInput: React.FC<AccountFormInputInterface> = (
  props: AccountFormInputInterface
) => {
  const {
    type,
    name,
    id,
    value,
    className,
    autoComplete,
    handleChange,
    divClassName,
    label,
    htmlFor,
    isUserName,
    isImage,
    errors,
    imgName,
    handleRemoveButton,
    nickname,
    firstName,
    lastName,
    loading
  } = props;

  if (isUserName) {
    return (
      <div className="sm:col-span-6">
        <label
          htmlFor="username"
          className="block text-sm font-medium text-blue-gray-900"
        >
          Username
        </label>
        <div className="mt-1 flex rounded-md shadow-sm">
          <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-blue-gray-300 bg-blue-gray-50 text-blue-gray-500 sm:text-sm">
            @
          </span>
          <input
            type={type}
            name={name}
            id={id}
            defaultValue={value}
            onChange={handleChange}
            onBlur={handleChange}
            autoComplete={autoComplete}
            className={
              errors ? 'flex-1 block w-full min-w-0 border-red-300 rounded-none rounded-r-md text-red-900 sm:text-sm focus:ring-red-500 focus:border-red-500' :
                (className || 'mt-1 block w-full border-blue-gray-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500')
            }
          />
        </div>
        <p className="input-error-hint" id="nickname-error">
          {errors}
        </p>
      </div>
    );
  }

  if (isImage) {
    return (
      <div className="sm:col-span-3">
        <div
          className="block text-sm font-medium text-blue-gray-900"
        >
          Profile picture
        </div>
        <div className="mt-1 flex items-center">
          <ProfilePicture
            name={{ firstName: firstName || '', lastName: lastName || '', nickname: nickname || '' }}
            profilePicture={value || ''}
            isOnProfilePage
            className="inline-flex items-center justify-center h-12 w-12 rounded-full bg-gray-500"
            fontSize="lg"
          />
          {
            loading && (
            <div className="flex items-center justify-center ">
              <div style={{borderTopColor: "transparent"}} className="ml-4 w-6 h-6 border-2 border-blue-400 border-solid rounded-full animate-spin" />
            </div>
            )
          }
          <div className="ml-4 flex">
            <div>
              <label
                htmlFor="user-photo"
                className="relative bg-white py-2 px-3 border border-blue-gray-300 rounded-md
                shadow-sm flex items-center hover:bg-blue-gray-50 focus-within:outline-none focus-within:ring-2
                focus-within:ring-offset-2 focus-within:ring-offset-blue-gray-50 focus-within:ring-blue-500 cursor-pointer font-medium text-sm"
              >
                <span>Change</span>
                <span className="sr-only"> user photo</span>
              </label>
              <input
                type={type}
                name={name}
                id={id}
                onChange={handleChange}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onClick={(e: any) => {
                  e.target.value = null;
                }}
                className={
                  className ||
                  'mt-1 block w-full border-blue-gray-300 rounded-md shadow-sm text-blue-gray-900 cursor-pointer sm:text-sm focus:ring-blue-500 focus:border-blue-500'
                }
              />
            </div>
            <button
              onClick={handleRemoveButton}
              type="button"
              className="ml-3 bg-transparent py-2 px-3 border border-transparent rounded-md text-sm font-medium text-blue-gray-900 hover:text-blue-gray-700 focus:outline-none focus:border-blue-gray-300 focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue-gray-50 focus:ring-blue-500"
            >
              Remove
            </button>
          </div>
        </div>
        <p>{imgName}</p>
        <p className="input-error-hint" id="profile-picture-error">
          {errors}
        </p>
      </div>
    );
  }

  return (
    <div className={divClassName || 'sm:col-span-3'}>
      <label
        htmlFor={htmlFor}
        className="block text-sm font-medium text-blue-gray-900"
      >
        {label}
      </label>
      <input
        type={type}
        id={id}
        name={name}
        defaultValue={value}
        onChange={handleChange}
        onBlur={handleChange}
        autoComplete={autoComplete}
        className={
          errors ? 'mt-1 block w-full border-red-300 rounded-md shadow-sm text-red-900 sm:text-sm focus:ring-red-500 focus:border-red-500' :
          ( className || 'mt-1 block w-full border-blue-gray-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500')
        }
      />
      <p className="input-error-hint" id="email-error">
        {errors}
      </p>
    </div>
  );
};

export default AccountFormInput;
