/* General textinput component */
import React, { ChangeEventHandler } from 'react';

interface TextInputProps {
  name: string;
  value?: string;
  handleChange: ChangeEventHandler<HTMLInputElement>;
  label: string;
  placeholder?: string;
  type?: string;
}

const TextInput: React.FC<TextInputProps> = (props: TextInputProps) => {
  const { name, value, handleChange, label, placeholder, type } = props;

  return (
    <div className="mb-4">
      <label
        className="block text-grey-darker text-sm font-bold mb-2"
        htmlFor={name}
      >
        {label}
        <input
          className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker"
          id={name}
          type={type || 'text'}
          placeholder={placeholder || label}
          name={name}
          value={value}
          onChange={handleChange}
        />
      </label>
    </div>
  );
};

export default TextInput;
