import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';

import { Avatar, PaymentProgress, PaymentType, PayWallOrSkipMessage } from '../../interfaces';

export interface PaymentState {
  paymentProgress: PaymentProgress,
  paymentType: PaymentType,
  isPaymentModalOpen: boolean,
  activePaymentProductCode?: string,
  paymentIdInProgress?: number,
  avatarInProgress?: Avatar,
  membershipChecked: boolean
  unlockPayWallOrSkipMsg: PayWallOrSkipMessage;
}

const initialState: PaymentState = {
  paymentProgress: PaymentProgress.READY,
  paymentType: PaymentType.SUBSCRIPTION,
  isPaymentModalOpen: false,
  membershipChecked: false,
  unlockPayWallOrSkipMsg: {type: undefined, avatarId: undefined, unlock: false}
};

const paymentInfo = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    setPaymentProgress: (state, action: PayloadAction<PaymentProgress>) => {
      state.paymentProgress = action.payload;
    },
    setPaymentType: (state, action: PayloadAction<PaymentType>) => {
      state.paymentType = action.payload;
    },
    setPaymentModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isPaymentModalOpen = action.payload;
    },
    setUnlockPayWallOrSkipMsg: (state, action: PayloadAction<PayWallOrSkipMessage>) => {
      state.unlockPayWallOrSkipMsg = action.payload;
    },
    setActivePaymentProductCode: (state, action: PayloadAction<string | undefined>) => {
      state.activePaymentProductCode = action.payload;
    },
    setPaymentIdInProgress: (state, action: PayloadAction<number | undefined>) => {
      state.paymentIdInProgress = action.payload;
    },
    setAvatarInProgress: (state, action: PayloadAction<Avatar | undefined>) => {
      state.avatarInProgress = action.payload;
    },
    setMembershipChecked: (state, action: PayloadAction<boolean>) => {
      state.membershipChecked = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState);
  }
});

export const {
  setPaymentProgress,
  setPaymentType,
  setPaymentModalOpen,
  setUnlockPayWallOrSkipMsg,
  setActivePaymentProductCode,
  setPaymentIdInProgress,
  setAvatarInProgress,
  setMembershipChecked,
} = paymentInfo.actions;

export default paymentInfo.reducer;
