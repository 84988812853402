import React from 'react';

import { FeaturedImage, PaymentType, Tag } from '../../interfaces';

import appLogo from '../../assets/fantxt-com.svg';
import { PaymentModal } from '../../containers';

import DiscoverBanner from './_DiscoverBanner';
import Featured from './_Featured';
import Categories from './_Categories';

interface DiscoverViewProps {
    featuredImages?: Array<FeaturedImage>;
    tags: Array<Tag>;
    renderRecommended: () => JSX.Element;
    isMobileView: boolean;
    isPaymentModalOpen?: boolean;
    setPaymentModalOpen: (val:boolean) => void;
    paymentType?: PaymentType;
    setModalType: (val: string) => void;
    setOpen: (val:boolean) => void;
    setShouldOpenPaymentModal: (val:boolean) => void;
  }

const DiscoverView : React.FC<DiscoverViewProps> = (props: DiscoverViewProps) => {
    const {
      featuredImages,
      tags,
      renderRecommended,
      isMobileView,
      isPaymentModalOpen,
      setPaymentModalOpen,
      paymentType,
      setOpen,
      setModalType,
      setShouldOpenPaymentModal
    } = props;

    return (
        <main className={`flex-1 ${!isMobileView && "overflow-y-scroll"}`}>
        <div id="fantxt-logo" className="flex items-center justify-center m-auto mt-6 mb-0 md:mb-4">
          <img src={appLogo} className="w-48 md:w-52" alt="Discover Fantxt" />
        </div>
        <div className="min-h-screen pb-20 bg-gray-100 py-6 flex flex-col justify-center pt-4 px-4">
          <PaymentModal
            open={isPaymentModalOpen || false}
            setOpen={setPaymentModalOpen}
            paymentType={paymentType || PaymentType.SUBSCRIPTION}
            redirectPage="/discover"
            isMobileView={isMobileView}
          />
          {/* <!-- Featured --> */}
          {featuredImages &&
            <Featured
              setShouldOpenPaymentModal={setShouldOpenPaymentModal}
              setOpen={setOpen}
              setModalType={setModalType}
              featuredImages={featuredImages}
            />
          }
          {/* <!-- Recommended --> */}
          {
            renderRecommended()
          }

          {/* <!-- Categories --> */}
          <Categories tags={tags}/>

          {/* <!-- External banner --> */}
          <DiscoverBanner />

          {/* <!-- Footer --> */}
          <div className="text-center text-xs text-gray-400 p-2 mt-4">
            <span>{new Date().getFullYear()} © fantxt</span> | <span><a href="https://fantxt.com/usc2257" target="_blank" rel="noreferrer">18 U.S.C. 2257 Record-Keeping Requirements Compliance Statement</a></span> | <span><a href="https://fantxt.com/terms" target="_blank" rel="noreferrer">Terms of Use &amp; Privacy Policy</a></span> | <span><a href="mailto:help@fantxt.com">Contact Us</a></span>
          </div>

        </div>
      </main>
    );
};

export default DiscoverView;
