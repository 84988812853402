import { Socket, Manager } from 'socket.io-client';
import { DefaultEventsMap } from 'socket.io-client/build/typed-events';
import { scheduleJob, scheduledJobs } from 'node-schedule';
import camelcaseKeys from 'camelcase-keys';
import moment from 'moment';
import { toast } from 'react-toastify';

import { Avatar, MessageNode, MsgChoice, NewAvatarMessages } from '../../interfaces';

import { store, persistor } from '../../reducers/store';
import { setNewMessages, refreshMessages, setIsTyping, setMsgChoicesShown, increaseMsgCount, setFetching } from '../../reducers/base';
import { updateCredits } from '../../reducers/user';
import { setConnectionStatus, setError, ConnectionStatus } from '../../reducers/websocket';
import { avatar as avatarSlice, setFriendAvatars } from '../../reducers/avatar';
import UtilsService from '../utils';

export default class WSApiHandlerService {
  manager: Manager<DefaultEventsMap, DefaultEventsMap> | undefined;

  socket: Socket<DefaultEventsMap, DefaultEventsMap> | undefined;

  connect: () => void = () => {
    store.dispatch(setConnectionStatus(ConnectionStatus.CONNECTING));
    const manager = new Manager(process.env.REACT_APP_WS_URL, {
      reconnectionAttempts: Number(process.env.REACT_APP_RECONNECTION_ATTEMPTS),
      reconnectionDelay: Number(process.env.REACT_APP_RECONNECTION_DELAY),
      reconnectionDelayMax: Number(process.env.REACT_APP_RECONNECTION_DELAY_MAX),
      timeout: Number(process.env.REACT_APP_TIMEOUT),
      transports: ['websocket']
    });

    const { accessToken } = store.getState().user;

    this.socket = manager.socket("/", {auth: {token: accessToken}});

    this.handleConnection(this.socket);

    this.handleNewMessage(this.socket);
  };

  disconnect: () => void = () => {
    if (!this.socket) {
      return;
    }
    this.socket.close();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  newMessage = async (resp: NewAvatarMessages, recalcDisplayTime?: boolean) => {

    // Add the avatar to the friendlist if the message comes from an avatar we have no history with
    const { friendAvatars } = store.getState().avatar;
    const friendAvatarIds = friendAvatars.map(friend => friend.id);
    if (!friendAvatarIds.includes(resp.avatarId)) {
      const avatars = await store.dispatch(avatarSlice.endpoints.getAllAvatarByUser.initiate(null));
      store.dispatch(setFriendAvatars((avatars as { data: Array<Avatar> }).data));
    }
    store.dispatch(setNewMessages({resp: resp as unknown as NewAvatarMessages, recalcDisplayTime: recalcDisplayTime || false}));

    // Schedule jobs to handle time of display
    const newAvatarMessages = (camelcaseKeys(resp as unknown as NewAvatarMessages, {deep: true}));
    newAvatarMessages.messageHistory.forEach(m => {
      if (m.displayTime && UtilsService.processDisplayTime(m.displayTime) > moment().local()) {
        scheduleJob(UtilsService.processDisplayTime(m.displayTime).format(), () => {
          store.dispatch(refreshMessages({cs: store.getState().websocket.connectionStatus, avatarId: newAvatarMessages.avatarId}));
        });
      }
      if (m.typingIndicatorTime && UtilsService.processDisplayTime(m.typingIndicatorTime) > moment().local()) {
        scheduleJob(`${m.nodeId}_${newAvatarMessages.avatarId}_ti_job`,UtilsService.processDisplayTime(m.typingIndicatorTime).format(), () => {
          store.dispatch(setIsTyping({avatarId: resp.avatarId, isTyping: true}));
        });
      }
    });
    if (newAvatarMessages.msgChoices &&
        newAvatarMessages.msgChoices[0] &&
        UtilsService.processDisplayTime(newAvatarMessages.msgChoices[0].displayTime) > moment().local()) {
      scheduleJob(UtilsService.processDisplayTime(newAvatarMessages.msgChoices[0].displayTime).format(), () => {
        store.dispatch(setMsgChoicesShown({avatarId: resp.avatarId, isShown: true}));
      });
    }
  }

  handleNewMessage: (socket: Socket<DefaultEventsMap, DefaultEventsMap>) => void =
      (socket: Socket<DefaultEventsMap, DefaultEventsMap>) => {
    socket.on("new_message", (resp: NewAvatarMessages) => {
      this.newMessage(resp);
    });

    socket.on("change_topic", (resp: NewAvatarMessages) => {
      store.dispatch(
        setNewMessages(
          {resp: resp as unknown as NewAvatarMessages}
        )
      );
    });

    socket.on("skip_message_wait", (resp: NewAvatarMessages) => {
      if (resp.updatedCredits || resp.updatedCredits === 0) {
        store.dispatch(updateCredits(resp.updatedCredits));
      }
      // Cancelling scheduled typing indicator jobs
      const newAvatarMessages = (camelcaseKeys(resp as unknown as NewAvatarMessages, {deep: true}));
      newAvatarMessages.messageHistory.forEach(m => {
        const job = scheduledJobs[`${m.nodeId}_${newAvatarMessages.avatarId}_ti_job`];
        if(job)
          job.cancel();
      });
      this.newMessage(resp, true);
    });
  }

  handleConnection: (socket: Socket<DefaultEventsMap, DefaultEventsMap>) => void =
      (socket: Socket<DefaultEventsMap, DefaultEventsMap>) => {
    socket.io.on("reconnect_attempt", () => {
      store.dispatch(setConnectionStatus(ConnectionStatus.RETRY));
    });

    socket.io.on("reconnect", () => {
      store.dispatch(setConnectionStatus(ConnectionStatus.CONNECTED));
    });

    socket.io.on("reconnect_error", () => {
      store.dispatch(setError('Can not connect to server'));
    });

    socket.io.on("reconnect_failed", () => {
      store.dispatch(setConnectionStatus(ConnectionStatus.DISCONNECTED));
      toast.error('Connection lost.', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    });

    socket.on("connect_confirm", () => {
      store.dispatch(setConnectionStatus(ConnectionStatus.CONNECTED));
    });

    socket.on("disconnect", () => {
      store.dispatch(setConnectionStatus(ConnectionStatus.DISCONNECTED));
    });

    socket.on("force_disconnect", (msg) => {
      store.dispatch(setConnectionStatus(ConnectionStatus.DISCONNECTED));
      console.log(`forcefully disconnected user with code: ${msg.code}, msg: ${msg.reason}`);
      persistor.purge();
      try {
        localStorage.setItem('isUserSignedIn', 'false');
      } catch {
        console.warn('Cookies are blocked!');
      }
      window.location.href = `${process.env.REACT_APP_LANDINGPAGE_URL}`;
    });
  }

  sendMessage(token: string, message: MsgChoice): void {
    store.dispatch(increaseMsgCount());
    if (!this.socket) {
      return;
    }
    const { activeAvatar } = store.getState().avatar;
    const { user } = store.getState().user;
    store.dispatch(
      setFetching(
        true
      )
    );
    const messageSnake = UtilsService.keysToSnakeCase(message as unknown as  {[key: string]: string | number });
    this.socket.emit("message", {"token": token, "tz_offset": moment().utcOffset(), "is_registered": user?.isRegistered, "avatar_id": activeAvatar?.id, "body": messageSnake, "timestamp": new Date()});
  }

  sendMessageInit(token: string, message?: MessageNode, chatId?: number): void {
    if (!this.socket) {
      return;
    }
    const { activeAvatar } = store.getState().avatar;
    const { user } = store.getState().user;
    const messageSnake = message && UtilsService.keysToSnakeCase(message as unknown as  {[key: string]: string | number });
    this.socket.emit("message_init", {
      "token": token,
      "tz_offset": moment().utcOffset(),
      "is_registered": user?.isRegistered,
      "avatar_id": activeAvatar?.id,
      "chat_id": chatId || 0,
      "body": messageSnake || null,
      "timestamp": new Date()
    });
  }

  changeTopic(token: string, nodeId: number | undefined): void {
    if (!this.socket) {
      return;
    }
    const { activeAvatar } = store.getState().avatar;
    const { user } = store.getState().user;
    this.socket.emit("change_topic", {"token": token, "is_registered": user?.isRegistered, "avatar_id": activeAvatar?.id, "body": {node_id: nodeId}, "timestamp": new Date()});
  }

  skipMessageWait(token: string, nodeId: number | undefined): void {
    if (!this.socket) {
      return;
    }
    const { activeAvatar } = store.getState().avatar;
    const { user } = store.getState().user;

    this.socket.emit("skip_message_wait", {"token": token, "is_registered": user?.isRegistered, "tz_offset": moment().utcOffset(), "avatar_id": activeAvatar?.id, "body": {node_id: nodeId}, "timestamp": new Date()});
  }

  pushChat(token: string): void {
    if (!this.socket) {
      return;
    }
    const { user } = store.getState().user;

    this.socket.emit("push_chat", {"token": token, "is_registered": user?.isRegistered, "tz_offset": moment().utcOffset(), "body": {}, "timestamp": new Date()});
  }

}
