import React from 'react';

import { Avatar, FeaturedImage } from '../../../interfaces';

interface DiscoverAvatarImageViewProps {
  featured: boolean | undefined;
  featuredImages?: Array<FeaturedImage>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleClick: (e: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleKeyDown: (e: any) => void;
  avatar?: Avatar;
}

const DiscoverAvatarImageView: React.FC<DiscoverAvatarImageViewProps> = (
  props: DiscoverAvatarImageViewProps
) => {
  const {
    featured,
    handleClick,
    handleKeyDown,
    avatar,
    featuredImages,
  } = props;

  let featuredImg;
  if(featuredImages) {
    // eslint-disable-next-line prefer-destructuring
    featuredImg = featuredImages[0];
  }
 

  return (
    <div
      className={`${
        featured
          ? 'mb-4 discover-frame sm:h-52 lg:h-96 w-full 2xl:w-4/5 lg:self-center'
          : 'recommendations group'
      }`}
    >
      <div
        onClick={handleClick}
        id="discoverAvatarButton"
        onKeyDown={handleKeyDown}
        className="w-full h-full"
        role="button"
        tabIndex={0}
      >
        <div className={`${featured ? 'featured' : 'rec-frame'}`}>
          <h3 className={`${featured ? 'text-3xl block text-shadow' : 'text-shadow'}`}>
            {(featured &&  featuredImg?.title) ? featuredImg.title : (avatar && avatar.avatarName)}
          </h3>
          {featured ? (
            <p>{featuredImg?.description ? featuredImg.description : ""}</p>
          ) : (
            <div style={{ animationDelay: "2000ms"}} className="bg-green-400 rounded-full w-3 h-3 float-right absolute bottom-4 right-4 animate-pulse" />
          )}
        </div>
        {avatar?.profileVideo ? (
          <video loop muted autoPlay className="rec-image" playsInline>
            <source src={avatar.profileVideo} type="video/mp4" />
          </video>
        ) : (
          <img
            alt={(featured && featuredImg?.title) ?  featuredImg.title : (avatar && avatar.avatarName)}
            src={(featured && featuredImg?.imageUrl) ? featuredImg.imageUrl : (avatar && avatar.avatarPicture)}
            className={`${featured ? 'w-full h-full object-cover rounded-md' : 'rec-image'}`}
          />
        )}
      </div>
    </div>
  );
};

export default DiscoverAvatarImageView;
