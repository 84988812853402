import React from 'react';
import Swipe from "react-easy-swipe";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import { MediaNode } from '../../interfaces';

import { Breadcrumb } from '../../components';

import VideoPlayer from './VideoPlayer';

interface MediaViewerViewViewProps {
    handleBackClick: () => Promise<void>;
    handleTextClick: () => void;
    mediaNodes: Array<MediaNode>;
    mediaIdx: number;
    clickedMedia: string | undefined;
    openBreadcrumb: boolean;
    handleSectionClick: () => void;
    rightSwipe: () => void;
    leftSwipe: () => void;
    isMobileView: boolean;
    isIOS: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    history: any;
    fromAvatarPost?: boolean;
    handle?: string;
}

const MediaViewerView : React.FC<MediaViewerViewViewProps> = (props: MediaViewerViewViewProps) => {
    const {handleBackClick, handleTextClick, mediaNodes, mediaIdx, clickedMedia, openBreadcrumb, handleSectionClick,
      rightSwipe, leftSwipe, isMobileView, isIOS, history, fromAvatarPost, handle} = props;

    const containStyle: React.CSSProperties  = {
          objectFit: "contain",
          width: "100%",
          height: "100%"
    };
    const avatarFirstName =  mediaNodes[mediaIdx].title?.split(" ")[0] || "";
    const isVideo = clickedMedia?.includes('.m3u8');
    return (
        <section
        onClick={handleSectionClick}
        role="presentation"
        className={`flex flex-1 flex-col ${isMobileView ? "h-screen justify-center content-center" : "h-full"} pl-2 pr-2 bg-black text-white group`}
      >
        <Breadcrumb
          text={mediaNodes[mediaIdx].title}
          customTextFunction={handleTextClick}
          customBackFunction={handleBackClick}
          buttonClass="pr-8 w-full text-center"
          navClass={`${!openBreadcrumb ? "opacity-0" : "opacity-100"} ${isMobileView && "fixed top-0 "} -ml-2 -mr-2  z-10 group-hover:z-50 fixed flex flex-row bg-black bg-opacity-80 w-full border-b border-gray-700 items-center space-x-2 lg:space-x-4 py-3 transition duration-200`}
        />
         {/* If the clicked media is a video remove the place-self-center tag, which prevents the video from fitting to the container the full screen.
            If the media is an image leave the place-self-center tag.
            NOTE: The id parameter in the VideoPlayer is necessary for the videojs package to distinguish the videos. The video id's cannot match
            otherwise there will be errors in the video playing. I gave a random id (111), currently, maybe change this later.
        */}

          <Swipe tolerance={100} onSwipeLeft={leftSwipe} onSwipeRight={rightSwipe} className={`pb-14 md:pb-0 -ml-2 -mr-2 flex flex-1 flex-grow h-full ${!clickedMedia?.includes('.m3u8') ? "place-self-center" : ""} content-center`}>
              {!isVideo ? (
                <TransformWrapper
                  centerZoomedOut
                  centerOnInit
                  limitToBounds
                  alignmentAnimation={{ sizeX: 0, sizeY: 0 }}
                  wheel={{wheelDisabled: true}}
                  >
                  <TransformComponent
                    wrapperStyle={containStyle}
                    contentStyle={containStyle}
                    >
                    <img
                      className="object-contain w-full h-full zoomable mx-auto"
                      src={clickedMedia}
                      alt={mediaNodes[mediaIdx].title}
                    />
                  </TransformComponent>
                </TransformWrapper>
              ) : (
                <VideoPlayer video={clickedMedia || null} handleSectionClick={handleSectionClick} isIOS={isIOS}/>
              )}
          </Swipe>
          {fromAvatarPost && handle ?
          <button type="button" onClick={() => history.push(`/add-friend?q=${handle}`, {from: 'discover'})}
            className={`block relative md:absolute w-full md:w-auto mb-10 mt-8 lg:m-0 md:right-8 bottom-8 ${isVideo && "lg:bottom-10"} bg-transparent text-white hover:bg-gray-100
            hover:text-black border-2 border-white rounded-lg pt-2 pb-2 pr-6 pl-6`}><b>Chat with {avatarFirstName}</b></button>
          : null }
      </section>
    );
};

export default MediaViewerView;