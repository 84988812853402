/* Avatar feed post component for the avatar profile page */
import React from 'react';

import { Post } from '../../../interfaces';

import { MediaHandler } from '../../../components';

interface AvatarPostProps {
  post: Post;
  avatarName: string;
  avatarHandle: string;
  profileImageUrl: string;
  handleMediaClick: (url: string) => void;
}

const AvatarPost: React.FC<AvatarPostProps> = (props: AvatarPostProps) => {
  const {
    post,
    avatarName,
    avatarHandle,
    profileImageUrl,
    handleMediaClick
  } = props;

  return (
    <div className="text-left max-w-3xl rounded-lg m-auto border mb-4">
      <div className="flex p-4 text-sm">
        <img className="h-10 w-10 rounded-full flex-shrink-0 mr-3" src={profileImageUrl} alt="Loading..." />
        <div className="font-bold">
          {avatarName}
          <span className="block font-normal text-xs">@{avatarHandle}</span>
        </div>
      </div>
      <div className="x-postbody">
        <div className="empty:hidden px-4 pb-4">{post.postText}</div>
        {post.mediaUrl &&
          <MediaHandler
            mediaFile={post.mediaUrl}
            handleMediaClick={handleMediaClick}
          />
        }
      </div>
    </div>
  );
};

export default AvatarPost;
