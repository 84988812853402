/* Higher order component to detect if the user is on mobile or web */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { isIOS, isIOS13, isIPhone13, isMobile, isSafari } from 'react-device-detect';

export interface WithMobileDetectionProps {
  screenViewType: string;
  isBarOpen: boolean;
  setIsBarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleIsBarOpen: (val: boolean) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transformStyle: () => any;
  transformStyleDiv: () => void;
  openContactList: boolean;
  handleIsContactlistOpen: (val: boolean) => void;
  isMobileView: boolean;
  isIOS: boolean;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const withMobileDetection = <P extends WithMobileDetectionProps>(Component: React.ComponentType<P>) => {
  const WithMobileDetection: React.FC<WithMobileDetectionProps> = (props: WithMobileDetectionProps) => {
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const {
      screenViewType,
      isMobileView,
      isBarOpen,
      setIsBarOpen,
      handleIsBarOpen,
      transformStyle,
      transformStyleDiv,
      openContactList,
      handleIsContactlistOpen,
      ...componentProps
    } = props;
    /* eslint-enable @typescript-eslint/no-unused-vars */

    const history = useHistory();

    const [screenViewTypeState, setScreenViewTypeState] = useState(
      window.innerWidth < 1024 ? 'mobile' : 'web'
    );
    const [isBarOpenState, setIsBarOpenState] = useState<boolean>(true);
    const [openContactListState, setOpenContactListState] = useState<boolean>(true);

    useEffect(() => {
      // Open the contactlist when the contactlist is closed and we tap on the Chat button in the bottom bar
      if (!history.location.pathname.includes('/chat/')) {
        if(!isBarOpenState) {
          setIsBarOpenState(true);
        }
        if(!openContactListState) {
          setOpenContactListState(true);
        }
      }
    },[history.location]);

    useEffect(() => {
      window.addEventListener('resize', handleScreenResize);
  
      return () => window.removeEventListener('resize', handleScreenResize);
    }, []);

    const handleScreenResize = (): void => {
      if (window.innerWidth < 1024) {
        setScreenViewTypeState('mobile');
      } else {
        setScreenViewTypeState('web');
      }
    };

    const handleIsBarOpenLocal = (val: boolean): void => {
      setIsBarOpenState(val);
    };

    const handleIsContactlistOpenLocal = (val: boolean): void => {
      setOpenContactListState(val);
    };
    const transformStyleLocal = () => {
      let str = "";
  
      if( screenViewTypeState === 'web') {
        str = 'translate(0px, 0px)';
      } else {
        // eslint-disable-next-line no-lonely-if
        if(openContactListState) {
          if(isBarOpenState) {
            str = 'translate(0px, 0px)';
          } else {
            str = 'translate(-125%, 0px)';
          }
        } else {
          str = 'translate(-125%, 0px)';
        }
      }
      return str;
    };
  
    const transformStyleDivLocal = () => {
      let str = "";
  
      if(screenViewTypeState === 'web') {
        str = 'w-96';
       } else {
        if(isBarOpenState) {
          str = 'w-full';
        }
        if(!isBarOpenState && !openContactListState) {
          str = 'w-0';
        }
      }
  
      return str;
    };

    return <Component
      {...componentProps as P}
      screenViewType={screenViewTypeState}
      isBarOpen={isBarOpenState}
      handleIsBarOpen={handleIsBarOpenLocal}
      transformStyle={transformStyleLocal}
      transformStyleDiv={transformStyleDivLocal}
      setIsBarOpen={setIsBarOpenState}
      openContactList={openContactListState}
      isMobileView={isMobile}
      isIOS={isIOS || isIOS13 || isIPhone13 || isSafari}
      handleIsContactlistOpen={handleIsContactlistOpenLocal}
    />;
  };

  return WithMobileDetection;
};

export default withMobileDetection;