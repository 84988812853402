/* Radiobutton-like element to display information about a product / membership */
import React from 'react';

interface PaymentElementProps {
  isActive: boolean;
  leftText: string;
  rightText: React.ReactElement;
  tag?: React.ReactElement;
  id?: string;
}

const PaymentElement: React.FC<PaymentElementProps> = (props: PaymentElementProps) => {
  const { isActive, leftText, rightText, tag, id} = props;

  const activeClassBorder = () => {
    let className = 'relative rounded-lg border border-gray-300 bg-white shadow-sm px-6 py-4 cursor-pointer hover:border-gray-400 flex justify-between focus:outline-none';
    if (isActive) {
      className += ' ring-2 ring-blue-500 bg-blue-50';
    }

    return className;
  };

  const activeClassFontColor = () => {
    let className = 'font-medium text-lg';
    if (isActive) {
      className += ' text-blue-600';
    }

    return className;
  };

  return (
    <label id="option-label-name" className={activeClassBorder()}>
      <input type="radio" id={id || ""} name="product-selection" value="TODO" className="sr-only" aria-labelledby="option-label-name" />
      <div className="flex items-center">
        <div>
          {/* Active: "text-blue-600" DEMONSTRATED WITH THIS ELEMENT */}
          <p className={activeClassFontColor()}>
            <span className="mr-2">{leftText}</span>
            {tag}
          </p>
        </div>
      </div>
      {rightText}
      {/* Checked: "border-blue-500", Not Checked: "border-transparent" */}
      <div className="absolute -inset-px rounded-lg border-2 pointer-events-none" aria-hidden="true"/>
    </label>
  );
};

export default PaymentElement;
