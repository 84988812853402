import React from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import type { RegisterRequest } from '../../interfaces';

import { useAppDispatch, useAppSelector } from "../../reducers/hooks";
import { useRegisterMutation } from '../../reducers/auth';
import { setCredentials, setModifiedUserData, useUpdateUserInfoMutation } from '../../reducers/user';
import { setFriendAvatars, useGetAllAvatarByUserMutation } from '../../reducers/avatar';
import WSApi from '../../services/ws-api-handler/ws-api';

import RegisterView from './RegisterView';

const Register: React.FC = () => {
  const { push } = useHistory();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.user);

  const [formState, setFormState] = React.useState<RegisterRequest>({
    email: '',
    nickname: '',
    password: ''
  });

  const [register] = useRegisterMutation();
  const [update] = useUpdateUserInfoMutation();
  const [getAvatars] = useGetAllAvatarByUserMutation();

  const handleChange = ({
    target: { name, value }
  }: React.ChangeEvent<HTMLInputElement>) =>
    setFormState(prev => ({ ...prev, [name]: value }));
  
  const validation = () => {
    if (formState.email === '') {
      return 'Email is required.';
    }
    if (formState.nickname === '') {
      return 'Username is required.';
    }
    if (formState.password === '') {
      return 'Password is required.';
    }
    if (formState.password.length < 6) {
      return 'The password is too short!';
    }

    return null;
  };

  const onSubmit = async () => {
    try {
      WSApi.ws.disconnect();
      const result = validation();
      if (result) {
        toast.error(result, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
        return;
      }

      if (
        user &&
        !user.isRegistered
      ) {
        const requestUser = {
          email: formState.email.toLowerCase(),
          nickname: formState.nickname,
          currentPassword: null,
          newPassword: formState.password,
        };
        const responseUser = await update(requestUser).unwrap();
        dispatch(setModifiedUserData(responseUser));
      } else {
        const requestUser = {
          email: formState.email.toLowerCase(),
          nickname: formState.nickname,
          password: formState.password,
        };
        const responseUser = await register(requestUser).unwrap();
        dispatch(setCredentials(responseUser));
      }
      redirect();
    } catch (err) {
      setFormState(prev => ({ ...prev, password: ''}));
    }
  };

  const redirect = async () => {
    const avatars = await getAvatars(null).unwrap();

    push('/discover');

    dispatch(setFriendAvatars(avatars));
  };

  return (
    <RegisterView
      formState={formState}
      handleChange={handleChange}
      onSubmit={onSubmit}
    />
  );
};

export default Register;
