import React from 'react';

import { RegisterRequest } from '../../interfaces';

import { TextInput } from '../../components';

interface RegisterViewProps {
  formState: RegisterRequest;
  handleChange: ({
    target: { name, value }
  }: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: () => Promise<void>;
}

const RegisterView: React.FC<RegisterViewProps> = (
  props: RegisterViewProps
) => {
  const { formState, handleChange, onSubmit } = props;

  return (
    <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col">
      <div className="mb-4">
        <TextInput
          name="email"
          value={formState.email}
          handleChange={handleChange}
          label="E-mail"
        />
      </div>
      <div className="mb-4">
        <TextInput
          name="nickname"
          value={formState.nickname}
          handleChange={handleChange}
          label="Username"
        />
      </div>
      <div className="mb-6">
        <TextInput
          name="password"
          value={formState.password}
          handleChange={handleChange}
          label="Password"
          type="password"
          placeholder="******************"
        />
      </div>
      <div className="flex items-center justify-between">
        <button type="button" onClick={onSubmit}>
          Sign Up
        </button>
      </div>
    </div>
  );
};

export default RegisterView;
