/* Avatar link component displayed in the recommended section of the discover page */
import React from 'react';

import { Avatar } from '../../../interfaces';

import RecommendedView from './RecommendedView';

interface RecomendedProps {
  recommendedImages: Array<Avatar>
}

const Recommended: React.FC<RecomendedProps> = (props: RecomendedProps) => {
  const {recommendedImages } = props;

  return (
    <RecommendedView
      recommendedImages={recommendedImages}
    />
  );
};

export default Recommended;
