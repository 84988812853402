import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import { PURGE } from 'redux-persist';

import {
  NewAvatarMessages,
  MessageHistoryResponse,
  MessageNode,
  NextNodeType,
} from '../../interfaces';

import { ConnectionStatus } from '../websocket';
import UtilsService from '../../services/utils';

export interface stateType {
  msgHistory?: MessageHistoryResponse,
  currentReferenceId?: string,
  isFetching: boolean,
  error: string,
  msgCount: number,
}

const initialState: stateType = {
  isFetching: false,
  error: '',
  msgCount: 0,
};

const baseInfo = createSlice({
  name: 'base',
  initialState,
  reducers: {
    setFetching: (state, action: PayloadAction<boolean>) => {
      state.isFetching = action.payload;
    },
    setCurrentReference: (state, action: PayloadAction<string | undefined>) => {
      state.currentReferenceId = action.payload;
    },
    setMessagesRead: (state, action: PayloadAction<{avatarId: number}>) => {
      if (
        state.msgHistory &&
        state.msgHistory[String(action.payload.avatarId)] &&
        state.msgHistory[String(action.payload.avatarId)].messageHistory
      ) {
        state.msgHistory![action.payload.avatarId].messageHistory =
          state.msgHistory![action.payload.avatarId].messageHistory.map(
            msg => (msg.isRead === false && msg.text !== "Skip the wait" && !msg.isLockedNode) ? { ...msg, isRead: true } : msg
          );
      }
    },

    setNewMessages: (state, action: PayloadAction<{resp: NewAvatarMessages, recalcDisplayTime?: boolean}>) => {

      if (action.payload?.resp.messageHistory) {
        const msgFutures : Array<MessageNode>= [];
        let tmpHistory = [];
        if(action.payload?.recalcDisplayTime && state.msgHistory && state.msgHistory[String(action.payload.resp.avatarId)].messageHistory) {
          tmpHistory = state.msgHistory[String(action.payload.resp.avatarId)].messageHistory;
          if(tmpHistory && tmpHistory[tmpHistory.length - 1].text === "Skip the wait") {
            // Removing the last skip the wait message from message history when new messages are received
            state.msgHistory[String(action.payload.resp.avatarId)].messageHistory.pop();
          }
        }
        action.payload.resp.messageHistory.forEach(msg => {
          if (msg.text.includes("<<date>>")) {
            msg.text = msg.text.replace(
              "<<date>>",
              UtilsService.processDisplayTime(msg.displayTime).format('DD/MM/YYYY HH:mm')
            );
          }
          if (state.msgHistory && (!(String(action.payload.resp.avatarId) in state.msgHistory)))
            state.msgHistory[String(action.payload.resp.avatarId)] = {
              messageHistory: [],
              msgChoices: []
            };
          if (state.msgHistory && UtilsService.processDisplayTime(msg.displayTime) <= moment()) {
            state.msgHistory[String(action.payload.resp.avatarId)].messageHistory.push(msg);
          } else if (state.msgHistory) {
            if (!state.msgHistory[String(action.payload.resp.avatarId)].messageFuture) {
              state.msgHistory[String(action.payload.resp.avatarId)].messageFuture = [];
            }
            if(!action.payload.recalcDisplayTime) {
              state.msgHistory[String(action.payload.resp.avatarId)].messageFuture!.push(msg);
            } else {
              msgFutures.push(msg);
            }
          }
        });
        if(action.payload.recalcDisplayTime && state.msgHistory) {
          state.msgHistory[String(action.payload.resp.avatarId)].messageFuture = msgFutures;
        }
        if (
          state.msgHistory &&
          state.msgHistory[String(action.payload.resp.avatarId)] &&
          state.msgHistory[String(action.payload.resp.avatarId)].messageFuture &&
          state.msgHistory[String(action.payload.resp.avatarId)].messageFuture?.filter(
            msg => msg.typingIndicatorTime && UtilsService.processDisplayTime(msg.typingIndicatorTime) <= moment() &&
            UtilsService.processDisplayTime(msg.displayTime) > moment()
          ).length !== 0
        ){
          state.msgHistory[String(action.payload.resp.avatarId)].isTyping = true;
        }
      }
      if (state.msgHistory && action.payload?.resp.msgChoices) {
        if (state.msgHistory[action.payload.resp.avatarId]) {
          state.msgHistory[action.payload.resp.avatarId].msgChoices = action.payload.resp.msgChoices;
          if(!state.msgHistory[action.payload.resp.avatarId].messageFuture)
            state.msgHistory[action.payload.resp.avatarId].messageFuture = [];
        } else {
          state.msgHistory[action.payload.resp.avatarId] = {
            messageHistory: [],
            messageFuture: [],
            msgChoices: action.payload.resp.msgChoices
          };
        }

        if (state.msgHistory &&
            state.msgHistory[action.payload.resp.avatarId] &&
            state.msgHistory[action.payload.resp.avatarId].msgChoices[0] &&
            UtilsService.processDisplayTime(state.msgHistory[action.payload.resp.avatarId].msgChoices[0].displayTime) > moment()
        ) {
          state.msgHistory[action.payload.resp.avatarId].isMsgChoicesShown = false;
        } else {
          state.msgHistory[action.payload.resp.avatarId].isMsgChoicesShown = true;
        }
        
      }
      state.isFetching = false;
    },
    setMsgChoicesShown: (state, action: PayloadAction<{avatarId: number, isShown: boolean}>) => {
      if (state.msgHistory && state.msgHistory[action.payload.avatarId]) {
        state.msgHistory[action.payload.avatarId].isMsgChoicesShown = true;
      }
    },
    setIsTyping: (state, action: PayloadAction<{avatarId: number, isTyping: boolean}>) => {
      if (state.msgHistory && state.msgHistory[String(action.payload.avatarId)]) {
        state.msgHistory[String(action.payload.avatarId)].isTyping = action.payload.isTyping;
      }
    },
    setTopicChanged: (state, action: PayloadAction<{avatarId: number, future: boolean, idx: number}>) => {
      if (action.payload.future) {
        state.msgHistory![String(action.payload.avatarId)].messageFuture![action.payload.idx].nextNodeType = NextNodeType.FINISHED;
      } else {
        state.msgHistory![String(action.payload.avatarId)].messageHistory![action.payload.idx].nextNodeType = NextNodeType.FINISHED;
      }
    },
    setMessageHistory: (state, action: PayloadAction<MessageHistoryResponse>) => {
      const tempHistory = action.payload;
      
      Object.keys(action.payload).forEach(key => {
        tempHistory[key].messageHistory = tempHistory[key].messageHistory.map(
          msg => ({...msg, text: msg.text.replace(
            "<<date>>",
            UtilsService.processDisplayTime(msg.displayTime).format('DD/MM/YYYY HH:mm')
          )})
        );
        tempHistory[key].messageFuture = tempHistory[key].messageHistory.filter((msg: MessageNode) => UtilsService.processDisplayTime(msg.displayTime) > moment());
        tempHistory[key].messageHistory = tempHistory[key].messageHistory.filter((msg: MessageNode) => UtilsService.processDisplayTime(msg.displayTime) <= moment());

        // Removing the previous "Skip the wait messages" which were already processed
        const lastMessage = tempHistory[key].messageHistory[tempHistory[key].messageHistory.length - 1];
        tempHistory[key].messageHistory = tempHistory[key].messageHistory.filter((msg: MessageNode) => UtilsService.processDisplayTime(msg.displayTime) <= moment() && !(msg.text === "Skip the wait" && msg !== lastMessage && UtilsService.processDisplayTime(msg.displayTime) <= moment()));

        if (state.msgHistory && (!state.msgHistory[key] || !state.msgHistory[key].msgChoices)) {
          tempHistory[key].msgChoices = [];
        } else if (state.msgHistory) {
          tempHistory[key].msgChoices = state.msgHistory[key].msgChoices;
          tempHistory[key].isMsgChoicesShown = state.msgHistory[key].isMsgChoicesShown;
        }

        if (
          tempHistory[key] &&
          tempHistory[key].messageFuture &&
          tempHistory[key].messageFuture?.filter(
            msg => msg.typingIndicatorTime && UtilsService.processDisplayTime(msg.typingIndicatorTime) <= moment() &&
            UtilsService.processDisplayTime(msg.displayTime) > moment()
          ).length !== 0
        ){
          tempHistory[key].isTyping = true;
        }
      });
      if (state.msgHistory) {
        Object.keys(state.msgHistory).forEach(mKey => {
          if (!(mKey in tempHistory)) {
            tempHistory[mKey] = state.msgHistory![mKey];
          }
        });
      }
      
      state.msgHistory = tempHistory;
    },
    refreshMessages: (state, action: PayloadAction<{cs: ConnectionStatus, avatarId: number}>) => {
      if (action.payload.cs === ConnectionStatus.DISCONNECTED) {
        return;
      }
      if (state.msgHistory) {
        const moveToHistory = state.msgHistory![action.payload.avatarId].messageFuture ?
        state.msgHistory![action.payload.avatarId].messageFuture!.filter(
          msg => UtilsService.processDisplayTime(msg.displayTime) <= moment()
        )
        :
        [];

        const processedSkipWaitNodes = state.msgHistory![action.payload.avatarId].messageHistory.filter(msg =>
          UtilsService.processDisplayTime(msg.displayTime) <= moment() && msg.text !== "Skip the wait");
        state.msgHistory![action.payload.avatarId].messageHistory = processedSkipWaitNodes.concat(
          moveToHistory
        );
        state.msgHistory![action.payload.avatarId].messageFuture =
          state.msgHistory![action.payload.avatarId].messageFuture ?
          state.msgHistory![action.payload.avatarId].messageFuture!.filter(
            msg => !moveToHistory.includes(msg)
          )
          :
          [];
        state.msgHistory![action.payload.avatarId].isTyping = false;
      }
    },
    unlock:(state, action: PayloadAction<number>) => {
      if (state.msgHistory) {
        Object.keys(state.msgHistory).forEach(avatar => {
          state.msgHistory![avatar].messageHistory.forEach((msg, index) => {
            if (msg.nodeId === action.payload)
              state.msgHistory![avatar].messageHistory[index].isLocked = false;
          });
        });
      }
    },
    unlockLockedNode:(state, action: PayloadAction<{avatarId: number, nodeId?: number}>) => {
      if (state.msgHistory && state.msgHistory![action.payload.avatarId].messageHistory) {
        const lastItem = state.msgHistory![action.payload.avatarId].messageHistory!.slice(-1)[0];
        const tmpHistory = state.msgHistory![action.payload.avatarId].messageHistory!.slice(0, -1);
        state.msgHistory![action.payload.avatarId].messageHistory = [...tmpHistory, {...lastItem, isLockedNode: false}];
      }
    },
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    increaseMsgCount: (state) => {
      state.msgCount += 1;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState);
  }
});

export const {
  setFetching,
  setCurrentReference,
  setMessagesRead,
  setNewMessages,
  setMsgChoicesShown,
  setIsTyping,
  setTopicChanged,
  setMessageHistory,
  refreshMessages,
  unlock,
  unlockLockedNode,
  setError,
  increaseMsgCount,
} = baseInfo.actions;

export default baseInfo.reducer;
