import React from 'react';

import { Avatar } from '../../../interfaces';

import DiscoverAvatarImage from '../_DiscoverAvatarImage';

interface RecommendedViewProps {
  recommendedImages: Array<Avatar>;
}

const RecommendedView: React.FC<RecommendedViewProps> = (
  props: RecommendedViewProps
) => {
  const { recommendedImages } = props;

  return (
    <div>
      <h5 className="my-2 border-gray-500 uppercase text-xs font-medium text-gray-500 mt-4">Chat now</h5>
        <div>
          <div className="mb-4 gap-4 grid grid-cols-2 md:grid-cols-4 2xl:grid-cols-6">
            {recommendedImages?.map(el => (
                <DiscoverAvatarImage
                  key={el.id}
                  avatar={el}
                />
              ))}
          </div>
        </div>
    </div>
  );
};

export default RecommendedView;
