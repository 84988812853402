import React, { useContext } from "react";

import { MainContext } from '../../Main/Main';
import { useAppSelector } from "../../../reducers/hooks";

const MoreDeals: React.FC = () => {
  const user = useAppSelector((state) => state.user.user);

  const {
    setModalTypeMain: setModalType,
    setOpenMain: setOpen,
  } = useContext(MainContext);

  const onClick = (e: React.MouseEvent<HTMLElement>) => {
    if (!user?.isRegistered) {
      e.preventDefault();
      setModalType('register');
      setOpen(true);
    }
  };

  return (
    <div className="w-full text-center">
      <a
        onClick={onClick}
        href="/profile/offers"
        className="text-sm text-indigo-800 border border-gray-300  400 p-2 px-4 rounded-md hover:text-white hover:bg-indigo-700 hover:border-indigo-900 transition"
      >
        See more deals…
      </a>
    </div>
  );
};

export default MoreDeals;
