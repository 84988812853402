/* Component to display message time in local timezone */
import React from 'react';

import UtilsService from '../../../services/utils';

interface MessageDisplayTimeProps {
  displayTime: string;
}

const MessageDisplayTime: React.FC<MessageDisplayTimeProps> = (
  props: MessageDisplayTimeProps
) => {
  const { displayTime } = props;

  return (
    <span className="h-0 opacity-0 group-focus:h-5 group-focus:opacity-50 transition-all duration-100 ease-out text-xs">
      {UtilsService.processDisplayTime(displayTime).format(
        'D MMM YYYY, HH:mm'
      )}
    </span>
  );
};

export default MessageDisplayTime;
