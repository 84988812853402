/* Button component for the unlock content modal */
import React from 'react';
import { LockClosedIcon } from '@heroicons/react/solid';

interface UnlockContentModalBtnProps {
  btnClass: string;
  divClass?: string;
  btnText: string;
  hideIcon?: boolean;
  handleClick: (action: string) => void;
  id?: string;
  disabled?: boolean;
}

const UnlockContentModalBtn: React.FC<UnlockContentModalBtnProps> = (
  props: UnlockContentModalBtnProps
) => {
  const {btnText, divClass, hideIcon, btnClass, handleClick, id, disabled} = props;

  return (
    <div className={divClass || "mt-6 text-center"}>
      <button
        id={id || ""}
        type="button"
        className={btnClass}
        onClick={() => handleClick(btnText)}
        disabled={disabled}
      >
        {!hideIcon && (
          <span className="absolute left-0 inset-y-0 flex items-center pl-3">
            <LockClosedIcon
              className="h-5 w-5 text-blue-400 group-hover:text-blue-400"
              aria-hidden="true"
            />
          </span>
        )}
        {btnText}
      </button>
    </div>
  );
};

export default UnlockContentModalBtn;
