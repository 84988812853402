/* eslint-disable @typescript-eslint/no-empty-function */
import React, { ReactElement, useEffect, useState } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import moment from 'moment';
import { scheduleJob } from 'node-schedule';
import { ExclamationIcon } from '@heroicons/react/outline';
import { isMobile } from 'react-device-detect';
import 'react-toastify/dist/ReactToastify.css';

import { MessageHistoryResponse, User, PaymentProgress, PaymentType } from '../../interfaces';

import { CustomModalLayout } from '../../containers';
import { useAppDispatch, useAppSelector } from "../../reducers/hooks";
import { persistor } from '../../reducers/store';
import { setUserProfileInfo, updateToken, useGetUserMutation, useUpdateUserInfoMutation, setModifiedUserData } from '../../reducers/user';
import { setFriendAvatars, setUnreads, useGetAllAvatarByUserMutation } from '../../reducers/avatar';
import { refreshMessages, setMessageHistory, useGetMsgHistoryByAvatarMutation } from '../../reducers/base';
import { setPaymentType, setPaymentModalOpen } from '../../reducers/payment';
import { ConnectionStatus } from '../../reducers/websocket';
import UtilsService from '../../services/utils';
import WSApi from '../../services/ws-api-handler/ws-api';

import MainView from './MainView';

const sidebarNavigation = [
  { id: 1,name: 'Discover', href: '/discover/browse', icon: 'SearchIcon', current: false },
  { id: 2,name: 'Chats', href: '/chat', icon: 'ChatIcon', current: true },
  { id: 3,name: 'Saved', href: '/saved/all', icon: 'PhotographIcon', current: false },
  {
    id: 4,
    name: 'My profile',
    href: '/profile',
    icon: 'UserCircleIcon',
    current: false
  }
];

const bottomBarNavigation = [sidebarNavigation[1], sidebarNavigation[0],
  {id: 5, name: "MainIcon", href:'/discover', icon: 'MainIcon', current: false},
  sidebarNavigation[2], sidebarNavigation[3]];

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    openModalFromGTM:any;
  }
}

interface MainProps {
  children: ReactElement,
}

export const MainContext = React.createContext({
  openMain: false,
  setOpenMain: (val: boolean) => {console.log(val);},
  modalTypeMain: "",
  setModalTypeMain: (val: string) => {console.log(val);},
  setInitModalEmail: (val: string) => {console.log(val);},
  directToPayment: false,
  setDirectToPayment: (val: boolean) => {console.log(val);},
});

const RESOURCE_API_URL = process.env.REACT_APP_API_RESOURCE_URL ? process.env.REACT_APP_API_RESOURCE_URL : 'https://cdn.beta.chatmate.me';
const MESSAGE_POP_AUDIO_URL = `${RESOURCE_API_URL}/message-pop_free.mp3`;

const Main: React.FC<MainProps> = (props: MainProps) => {
  const { children } = props;
  const {
    user,
    msgHistory,
    unreadsByAvatar,
    activeAvatar,
    friendAvatars,
    connectionStatus,
    paymentProgress
  } = useAppSelector((state) => ({
    user: state.user.user,
    msgHistory: state.base.msgHistory,
    unreadsByAvatar: state.avatar.unreads,
    activeAvatar: state.avatar.activeAvatar,
    friendAvatars: state.avatar.friendAvatars,
    connectionStatus: state.websocket.connectionStatus,
    paymentProgress: state.payment.paymentProgress
  }));

  const history = useHistory();
  const { push } = useHistory();
  const [getUser] = useGetUserMutation();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [getFriendAvatars] = useGetAllAvatarByUserMutation();
  const [unreadsSum, setUnreadsSum] = useState(0);
  const [getMsgHistory] = useGetMsgHistoryByAvatarMutation();

  const [modalTypeMain, setModalTypeMain] = useState<string>("");
  const [openMain, setOpenMain] = useState<boolean>(false);
  const [permissionOpen, setPermissionOpen] = useState<boolean>(false);
  const [initModalEmail, setInitModalEmail] = useState<string>("");
  const [directToPayment, setDirectToPayment] = useState<boolean>(false);

  const PermissionModalProps = {
    icon: (
      <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-yellow-100 sm:h-10 sm:w-10">
        <ExclamationIcon className="h-6 w-6 text-yellow-600" aria-hidden="true" />
      </div>
    ),
    title: 'Check your settings',
    description:<p>In order to use Fantxt, you must <b>allow cookies and local storage</b> in your web browser. Please enable them for <b>fantxt.com</b> on your device for Fantxt to function.</p>,
    button1Text: 'OK',
    layout: CustomModalLayout.VERTICAL
  };


  const messagePopAudio = new Audio(MESSAGE_POP_AUDIO_URL);

  
  const [updateUser] = useUpdateUserInfoMutation();
  const [translateModalOpen, setTranslateModalOpen] = useState<boolean>(false);

  const updateUserLanguage = async (language: string) => {
    const modifiedUser = await updateUser({language}).unwrap();
    if (modifiedUser && modifiedUser.status === 'success')
      dispatch(setModifiedUserData(modifiedUser));
  };

  useEffect(() => {
    const language = window.navigator.language?.slice(0, 2);
    if (user && !user.language && language) {
      updateUserLanguage(language);
      if (language !== 'en')
        setTranslateModalOpen(true);
    }
  }, [user]);

  useEffect(() => {
    // If no user logged in on init, set storage item for landing to false
    if (!user) {
      try {
        localStorage.setItem('isUserSignedIn', 'false');
      } catch {
        console.warn('Cookies are blocked!');
      }
    }

    (async () => {
      // If the token was sent in the query string
      const queryParams = history.location.search;
      if(queryParams !== '') {
          // The token param format is this: ?token=[token]
          // Remove "?" and split the token by the "="
          // Loop through array splitted by "&", and check if token exists
          let token = "";
          queryParams.substring(1).split("&").forEach(el => {
            const [key, value] = el.split("=");
            if(key === "token" && value !== undefined) {
                token = value;
            }
        });

        if(token !== "") {
          // Set the token to the store
          dispatch(updateToken(token));
          // Get the user data by the token
          try {
            const userResp = await getUser().unwrap();
            // Set the user to the store
            dispatch(setUserProfileInfo(userResp));
            redirectAfterLogin();
          } catch (err) {
            console.warn(err);
            persistor.purge();
            // Redirect back to landing page when error occurs
            // window.location.href = `${process.env."REACT_APP_LANDINGPAGE_URL"}`;
          }
        }
      } else if (!user) {
        // eslint-disable-next-line no-lonely-if
        if(process.env.REACT_APP_ENVIRONMENT === "development") {
          // In development mode enable login and signup endpoints for testing
          const path = history.location.pathname.split("/");
          if(path[1] === "login") {
            history.push("/login");
          } else if(path[1] === "signup") {
            history.push("/signup");
          } else {
            // On add-friend url call there is no user, but we should not redirect to landing
            if (
              history.location.pathname.includes('add-friend') ||
              history.location.pathname.includes('reset-password')
            ) {
              return;
            }
            console.log('test');
            // window.location.href = `${process.env.REACT_APP_LANDINGPAGE_URL}`;
          }
        } else {
          // On add-friend url call there is no user, but we should not redirect to landing
          if (
            history.location.pathname.includes('add-friend') ||
            history.location.pathname.includes('reset-password')
          ) {
            return;
          }
          // In staging or production redirect back to landingpage when token is missing
          // window.location.href = `${process.env.REACT_APP_LANDINGPAGE_URL}`;
          console.log('test2');
        }
      } else {
        if (history.location.pathname === '/landing') {
          history.push('/chat');
        }
        getAvatarFriends();
      }
    })();
  },[]);

  const mainInit = async () => {
    if (
      user &&
      connectionStatus === ConnectionStatus.DISCONNECTED &&
      history.location.pathname !== '/login' &&
      history.location.pathname !== '/signup' &&
      !location.pathname.includes('/add-friend') &&
      !location.pathname.includes('/landing') &&
      !location.pathname.includes('/payment-redirect') &&
      !location.pathname.includes('/404') &&
      location.pathname !== "/"
    ) {
      // On mount of Main container connect to websocket
      WSApi.ws.connect();
    }
  };

  useEffect(() => {
      mainInit();
  }, [history.location.pathname]);

  useEffect(() => {
    if (friendAvatars && friendAvatars.length > 0) {
      let tempUnreads = {};
      friendAvatars.forEach(avatar => {
        const tempUnread = calculateUnreadMessageCount(avatar.id);
        if (tempUnread > unreadsByAvatar[avatar.id] && (!activeAvatar || activeAvatar.id !== avatar.id)) {
          try {
            messagePopAudio.play();
          } catch (e) {
            console.warn(e);
          }
        }
        tempUnreads = {...tempUnreads, [avatar.id]: tempUnread};
      });
      if (JSON.stringify(tempUnreads) !== JSON.stringify(unreadsByAvatar)) {
        dispatch(setUnreads(tempUnreads));
      }
    }
  }, [msgHistory]);

  useEffect(() => {
    calculateUnreadSum();
  }, [unreadsByAvatar]);

  useEffect(() => {
    checkPermissions();
  }, []);

  const checkPermissions = async () => {
    let  permissionStatus = null;
    try {
      const queryOpts = { name: 'persistent-storage' as PermissionName, allowWithoutGesture: false };
      permissionStatus = await navigator.permissions.query(queryOpts);
    } catch (error) {
      console.warn('Permission cannot be checked.');
    }

    if (permissionStatus && permissionStatus.state === 'denied' && !location.pathname.includes('/payment-redirect')) {
      setPermissionOpen(true);
    }
  };

  const calculateUnreadMessageCount = (avatarId: number) => {
    let unreads = 0;

    if (
      msgHistory &&
      msgHistory[avatarId] &&
      msgHistory[avatarId].messageHistory
    ) {
      unreads = msgHistory[avatarId].messageHistory.filter(msg => msg.isRead === false && msg.text !== "Skip the wait" && !msg.isLockedNode).length;
    }

    return unreads;
  };

  const calculateUnreadSum = () => {
    if (!unreadsByAvatar) {
      return;
    }

    let sum = 0;
    Object.keys(unreadsByAvatar).forEach(key => {
      if (unreadsByAvatar[key] > 0) {
        sum += 1;
      }
    });

    setUnreadsSum(sum);
  };

  const getAvatarFriends = async () => {
    const avatars = await getFriendAvatars(null).unwrap();
    dispatch(setFriendAvatars(avatars));
  };

  const redirectAfterLogin = async () => {
      const avatars = await getFriendAvatars(null).unwrap();
  
      if (!avatars || avatars.length === 0) {
        push('/discover');
      } else {
        push('/chat');
      }
  
      dispatch(setFriendAvatars(avatars));
  };

  useEffect(() => {
    if ((!activeAvatar && user) || (paymentProgress !== PaymentProgress.READY && activeAvatar && user) || (activeAvatar && !user?.isRegistered)) {
      getMessageHistory();
    }
  }, [friendAvatars, activeAvatar]);

  const getMessageHistory = async () => {
    const tempHistory = await getMsgHistory(null).unwrap();
    dispatch(setMessageHistory(tempHistory));

    startJobs(tempHistory);

    return history;
  };

  const startJobs = (tempHistory: MessageHistoryResponse) => {
    Object.keys(tempHistory).forEach(key => {
      if (!tempHistory[key] || !tempHistory[key].messageFuture) {
        return;
      }
      tempHistory[key].messageFuture!.forEach(m => {
        if (
          m.displayTime &&
          UtilsService.processDisplayTime(m.displayTime) > moment()
        ) {
          scheduleJob(
            UtilsService.processDisplayTime(m.displayTime).format(),
            () => {
              dispatch(refreshMessages({cs: ConnectionStatus.CONNECTED, avatarId: Number(key)}));
            }
          );
        }
      });
    });
  };

  const childrenWithProvider = () =>
    <MainContext.Provider value={
      { openMain, setOpenMain, modalTypeMain, setModalTypeMain, setInitModalEmail, setDirectToPayment, directToPayment }
    }>
      {children}
    </MainContext.Provider>;

  const setPaymentModalOpenState = (isOpen: boolean) => {
    dispatch(setPaymentModalOpen(isOpen));
  };

  const setPaymentTypeState = (newPaymentType: PaymentType) => {
    dispatch(setPaymentType(newPaymentType));
  };

  // Function to be triggered from Google tag manager
  const openModalFromGTM = (type: string) => {
    switch (type) {
      case 'login':
        setModalTypeMain("login");
        setOpenMain(true);
        break;
      case 'register':
        setModalTypeMain("register");
        setOpenMain(true);
        break;
      case PaymentType.SUBSCRIPTION:
        console.log("asdasdsadasas");
        setPaymentModalOpenState(true);
        setPaymentTypeState(PaymentType.SUBSCRIPTION);
        break;
      case PaymentType.CREDIT:
        setPaymentModalOpenState(true);
        setPaymentTypeState(PaymentType.CREDIT);
        break;
      default:
        break;
    }
  };

  window.openModalFromGTM = openModalFromGTM;


  return (
    <MainView
      user={user as User}
      unreadsSum={unreadsSum}
      sidebarNavigation={sidebarNavigation}
      bottomBarNavigation={bottomBarNavigation}
      isMobileView={isMobile}
      open={openMain}
      setOpen={setOpenMain}
      permissionOpen={permissionOpen}
      setPermissionOpen={setPermissionOpen}
      permissionModalProps={PermissionModalProps}
      modalType={modalTypeMain}
      setModalType={setModalTypeMain}
      initModalEmail={initModalEmail}
      setDirectToPayment={setDirectToPayment}
      translateModalOpen={translateModalOpen}
      setTranslateModalOpen={setTranslateModalOpen}
      // eslint-disable-next-line react/no-children-prop
      children={childrenWithProvider()}
    />
  );
};

export default Main;
