/* Horizontal line component with text in the middle */
import React from 'react';

interface HrWithTitleProps {
    title: string;
    marginTop?: string;
}

const HrWithTitle: React.FC<HrWithTitleProps> = (props: HrWithTitleProps) => {
    const {title, marginTop} = props;
    return(
        <div className={`mt-${marginTop || "6"} relative`}>
        <div className="absolute inset-0 flex items-center">
          <div className="w-full border-t border-gray-300" />
        </div>
        <div className="relative flex justify-center text-sm">
          <span className="px-2 bg-white text-gray-500">{title}</span>
        </div>
      </div>
    );
};

export default HrWithTitle;