/* Chat window appearance and logic component */
import React, { useEffect, useState } from 'react';

import { Avatar, MessageHistoryResponse, User, PayWallOrSkipMessageType } from '../../../interfaces';

import { useAppDispatch, useAppSelector } from "../../../reducers/hooks";
import {
  setCurrentReference,
  setMessagesRead,
  setTopicChanged,
  unlockLockedNode,
  useSetMessagesReadMutation
} from '../../../reducers/base';
import { setNewChatNotifications} from '../../../reducers/avatar';
import { setUnlockPayWallOrSkipMsg } from '../../../reducers/payment';
import WSApi from '../../../services/ws-api-handler/ws-api';

import ChatformView from './ChatformView';

interface ChatFormProps {
  slideInContactList: boolean;
  screenViewType: string;
  setContentModalOpen: (val:boolean) => void;
  setSlideInContactList: (val:boolean) => void;
  setModalType: (val: string) => void;
  setOpen: (val: boolean) => void;
  setContentCreditCost: (val:number) => void;
  setContentNodeId: (val:number) => void;
  handleMediaClick: (url: string, setCurrentReference?: (refId:string) => void, refId?:string) => void;
  isMobileView: boolean;
  open: boolean;
  directToPayment: boolean;
  setDirectToPayment: (val:boolean) => void;
  setPaymentModalOpen: (val: boolean) => void;
  openMediaViewer: boolean;
}

const ChatForm: React.FC<ChatFormProps | null> = (props: ChatFormProps) => {
  const {
    slideInContactList,
    screenViewType,
    setModalType,
    setOpen,
    setContentModalOpen,
    setSlideInContactList,
    setContentCreditCost,
    setContentNodeId,
    handleMediaClick,
    isMobileView,
    open,
    directToPayment,
    setDirectToPayment,
    setPaymentModalOpen,
    openMediaViewer
  } = props;
  const scrollRef = React.createRef<HTMLInputElement>();
  const dispatch = useAppDispatch();
  const {
    msgHistory,
    currentReferenceId,
    activeAvatar,
    user,
    connectionStatus,
    accessToken,
    isFetching,
    nodeInteractionCounter,
    unlockPayWallOrSkipMsg,
    newChatNotifications,
  } = useAppSelector((state) => ({
    msgHistory: state.base.msgHistory,
    currentReferenceId: state.base.currentReferenceId,
    activeAvatar: state.avatar.activeAvatar,
    user: state.user.user,
    connectionStatus: state.websocket.connectionStatus,
    accessToken: state.user?.accessToken,
    isFetching: state.base.isFetching,
    nodeInteractionCounter: state.user.nodeInteractionCounter,
    unlockPayWallOrSkipMsg: state.payment.unlockPayWallOrSkipMsg,
    newChatNotifications: state.avatar.newChatNotifications,
  }));

  const [fetchMessagesRead] = useSetMessagesReadMutation();
  const [loadedMediaCount, setLoadedMediaCount] = useState(0);
  const [mediaCount, setMediaCount] = useState(0);
  const [subscribed, setSubscribed] = useState<boolean>(false);
  const [isBailoutConfirmationOpen, setIsBailoutConfirmationOpen] = useState(false);
  const { ws } = WSApi;

  // Scroll to bottom on arriving, scroll to given image on navigating back from MediaViewer
  React.useEffect(() => {
    if (scrollRef && scrollRef?.current && !currentReferenceId && isNearScrollBottom()) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [activeAvatar && msgHistory && msgHistory[activeAvatar.id]]);

  React.useEffect(() => {
    if (scrollRef && scrollRef?.current && !currentReferenceId) {
      scrollRef.current.scrollIntoView({ behavior: 'auto' });
    }
  }, [activeAvatar]);

  const isNearScrollBottom = () => {
    const threshold = isMobileView ? 300 : 500;
    const scrollTop = isMobileView ? window.pageYOffset : document.getElementById('messages')?.scrollTop;
    const height = scrollTop || undefined;
    const offsetHeight = document.getElementById('messages')?.offsetHeight ? document.getElementById('messages')?.offsetHeight : 0;
    const scrollHeight = document.getElementById('messages')?.scrollHeight;

    return ((offsetHeight || 0) + (height || 0)) >= ((scrollHeight || 0) - threshold);
  };

  React.useEffect(() => {
    if (
      loadedMediaCount === mediaCount &&
      mediaCount > 0 &&
      loadedMediaCount > 0
    ) {
      if (scrollRef && scrollRef?.current && !currentReferenceId) {
        scrollRef.current.scrollIntoView({ behavior: 'auto' });
      }
      dispatchSetCurrentReference(undefined);
    }
  }, [activeAvatar, mediaCount, loadedMediaCount]);

  const dispatchSetCurrentReference = async (refId?: string) => {
    dispatch(setCurrentReference(refId));
  };

  // Count all media in current chat
  React.useEffect(() => {
    setMediaCount(0);
    if (
      activeAvatar &&
      msgHistory &&
      msgHistory[activeAvatar.id] &&
      msgHistory[activeAvatar.id].messageHistory
    ) {
      const mc = msgHistory[activeAvatar.id].messageHistory.filter(
        m => m.mediaFile
      ).length;
      setMediaCount(mc);
    }
  }, [msgHistory, activeAvatar]);

  // Open register modal if neccessary
  React.useEffect(() => {
    if (
      activeAvatar &&
      msgHistory &&
      msgHistory[activeAvatar.id] &&
      msgHistory[activeAvatar.id].msgChoices &&
      user &&
      !user.isRegistered &&
      msgHistory[activeAvatar.id].msgChoices.length === 0 &&
      msgHistory[activeAvatar.id].isMsgChoicesShown
    ) {
      // Open Register modal at the and of the chat for temp user
      openRegisterModal();
    }
  }, [msgHistory, activeAvatar]);

  React.useEffect(() => {
    if (activeAvatar) {
      setLoadedMediaCount(0);
    }
  }, [activeAvatar]);

  React.useEffect(() => {
    if (!slideInContactList || screenViewType === 'web') {
      setMessagesToRead();
    }
  }, [
    activeAvatar &&
    msgHistory &&
    msgHistory[activeAvatar.id] &&
    msgHistory[activeAvatar.id].messageHistory
      ? msgHistory[activeAvatar.id].messageHistory
      : activeAvatar,
    slideInContactList
  ]);

  const handleRegisterClick = () => {
    setDirectToPayment(true);
    setOpen(true);
    setModalType('register');
  };

  React.useEffect(() => {
    if (
      !open &&
      setContentModalOpen &&
      directToPayment
    ) {
      setTimeout(() => {
        setContentModalOpen(true);
      }, 500);
    }
  }, [open, directToPayment]);

  const openRegisterModal = () => {
    setOpen(true);
    setModalType('register');
  };

  const setMessagesToRead = () => {
    if (
      activeAvatar &&
      msgHistory &&
      msgHistory[activeAvatar.id] &&
      msgHistory[activeAvatar.id].messageHistory
    ) {
      const unread = msgHistory[activeAvatar.id].messageHistory.filter(
      msg => (((msg.isRead === false && msg.sender !== 'SYSTEM' && msg.text !== "Skip the wait" && !msg.isLockedNode) ||
      (msg.isRead === false && msg.sender === 'SYSTEM' && !subscribed)))
      );
      const unreadIds = unread.map(msg => (msg.nodeId ? msg.nodeId : -1));
      if (unreadIds && unreadIds.length > 0) {
        dispatch(setMessagesRead({ avatarId: activeAvatar.id }));
        fetchMessagesRead({ avatarId: activeAvatar.id, messageIds: unreadIds });
      }
    }
  };

  const isLastNodeLocked = () => {
    let containsLockedNode = false;
    if(msgHistory && activeAvatar && msgHistory[activeAvatar.id]) {
      const historyLength = msgHistory[activeAvatar.id].messageHistory?.length;
      if(historyLength) {
        const lastNode = msgHistory[activeAvatar.id].messageHistory![historyLength - 1];
        containsLockedNode = (lastNode.isLockedNode || false ) && lastNode.nextNodeType !== "FINISHED";
      }
    }
    return containsLockedNode;
  };

  const renderTypingIndicator = () => (
    <div className="chat-typing">
      <div className="message-container overflow-y-hidden">
        <div className="group message-area">
          <span className="message-text">
            <div className="ti-ellipsis">
              <div className="ti-dot" />
              <div className="ti-dot" />
              <div className="ti-dot" />
            </div>
          </span>
        </div>
        <img
          src={activeAvatar?.avatarPicture}
          alt={activeAvatar?.avatarName}
          className="message-avatar"
        />
      </div>
    </div>
  );

  const addToLoadedMediaCount = () => {
    setLoadedMediaCount(loadedMediaCount + 1);
  };

  const [divHeight, setDivHeight] = React.useState(0);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const chatDivRef = React.useRef<any>();

  useEffect(() => {
    if(chatDivRef.current && chatDivRef.current.clientHeight) {
      setDivHeight(chatDivRef.current.clientHeight);
    }
  });

  const renderChatformPadding = () => {
    let padding = "";
    if(!isMobileView) {
      padding = "pb-16";
    }
    return padding;
  };


  const handleSkipPillButtonClick = (nodeId?: number, skipPrice?: number) => {
    if(nodeId) {
      dispatch(setUnlockPayWallOrSkipMsg({type: PayWallOrSkipMessageType.SKIP_THE_WAIT, avatarId: activeAvatar?.id, unlock: true}));
      setContentNodeId(nodeId);
      setContentCreditCost(skipPrice || 10);
      setContentModalOpen(true);
    }
  };

  const unlockPayWall = async () => {
    if(msgHistory && activeAvatar && msgHistory[activeAvatar.id]) {
      const historyLength = msgHistory[activeAvatar.id].messageHistory?.length;
      if(isLastNodeLocked() && historyLength >= 1) {
        const currLockedNode = msgHistory[activeAvatar.id].messageHistory![historyLength - 1];
        if(currLockedNode && currLockedNode.nodeId) {
          setContentNodeId(currLockedNode.nodeId);
          setContentCreditCost(currLockedNode.skipPrice || 10);
          dispatch(setUnlockPayWallOrSkipMsg({type: PayWallOrSkipMessageType.PAY_WALL, avatarId: activeAvatar.id, unlock: true}));
          setContentModalOpen(true);
        }
      }
    }
  };

  useEffect(() => {
    if(msgHistory && activeAvatar && msgHistory[activeAvatar.id] &&
      msgHistory[activeAvatar.id].msgChoices && msgHistory[activeAvatar.id].msgChoices[0]) {
        if(msgHistory[activeAvatar.id].msgChoices[0]?.icebreaker) {
          if(!newChatNotifications.includes(activeAvatar.id))
            dispatch(setNewChatNotifications([...newChatNotifications, activeAvatar.id]));
        } else {
          // eslint-disable-next-line no-lonely-if
          if(newChatNotifications.includes(activeAvatar.id)) {
            dispatch(setNewChatNotifications(newChatNotifications.filter((n: number) => n !== activeAvatar.id)));
          }
        }
    }
  }, [msgHistory]);

  const changeTopic = () => {
    let node;
    if (activeAvatar) {
      const futureLength = msgHistory?.[activeAvatar.id].messageFuture?.length;
      const historyLength = msgHistory?.[activeAvatar.id].messageHistory?.length;
      if (futureLength) {
        dispatch(setTopicChanged({avatarId: activeAvatar.id, future: true, idx: futureLength - 1}));
        node = msgHistory?.[activeAvatar.id].messageFuture![futureLength - 1];
      }
      else if (historyLength) {
        dispatch(setTopicChanged({avatarId: activeAvatar.id, future: false, idx: historyLength - 1}));
        node = msgHistory?.[activeAvatar.id].messageHistory![historyLength - 1];
      }
      if(node?.isLockedNode)
        dispatch(unlockLockedNode({avatarId: activeAvatar.id}));
    }
    if(node) {
      ws.changeTopic(accessToken as string, node.nodeId);
    setSlideInContactList(false);
    setIsBailoutConfirmationOpen(false);
    }
  };

  const initialBailoutProps = {
    close: () => setIsBailoutConfirmationOpen(false),
    open: () => setIsBailoutConfirmationOpen(true),
    handleModalAction: changeTopic,
    handleModalActionParameter: '',
    modalText: <p className="text-sm text-gray-500">You can not return to this chat after bailing out.</p>,
    leftBtnText: 'Keep going',
    rightBtnText: 'Skip this chat',
    modalTitle: 'Are you sure?',
    avatarHandle: '',
  };

  useEffect(() => {
    if(unlockPayWallOrSkipMsg.avatarId === activeAvatar?.id && unlockPayWallOrSkipMsg.unlock) {
      dispatch(setUnlockPayWallOrSkipMsg({type: undefined, avatarId: activeAvatar?.id, unlock: false}));
      setContentModalOpen(false);
      setPaymentModalOpen(false);
    }
  }, [msgHistory && activeAvatar && msgHistory[activeAvatar?.id] && msgHistory[activeAvatar?.id].isTyping]);

  return (
    <ChatformView
      msgHistory={msgHistory as MessageHistoryResponse}
      activeAvatar={activeAvatar as Avatar}
      user={user as User}
      currentReferenceId={currentReferenceId as string}
      addToLoadedMediaCount={addToLoadedMediaCount}
      setContentModalOpen={setContentModalOpen}
      setContentNodeId={setContentNodeId}
      setContentCreditCost={setContentCreditCost}
      setCurrentReference={dispatchSetCurrentReference}
      setModalType={setModalType}
      setOpen={setOpen}
      handleMediaClick={handleMediaClick}
      renderTypingIndicator={renderTypingIndicator}
      scrollRef={scrollRef}
      isMobileView={isMobileView}
      divHeight={divHeight}
      renderChatformPadding={renderChatformPadding}
      chatDivRef={chatDivRef}
      connectionStatus={connectionStatus}
      accessToken={accessToken as string}
      isFetching={isFetching}
      subscribed={subscribed}
      setSubscribed={setSubscribed}
      handleRegisterClick={handleRegisterClick}
      nodeInteractionCounter={nodeInteractionCounter}
      handleSkipPillButtonClick={handleSkipPillButtonClick}
      isLastNodeLocked={isLastNodeLocked}
      unlockPayWall={unlockPayWall}
      isBailoutConfirmationOpen={isBailoutConfirmationOpen}
      initialBailoutProps={initialBailoutProps}
      openMediaViewer={openMediaViewer}
    />
  );
};

export default ChatForm;
