/* Avatar list item component displayed on the discover page */
import React from 'react';
import { useHistory } from 'react-router-dom';

import { PaymentType, Avatar, FeaturedImage } from '../../../interfaces';

import { useAppDispatch, useAppSelector } from "../../../reducers/hooks";
import { setActiveAvatar } from '../../../reducers/avatar';
import { setPaymentType, setPaymentModalOpen } from '../../../reducers/payment';

import DiscoverAvatarImageView from './DiscoverAvatarImageView';

interface DiscoverAvatarImageProps {
  featuredImages?: Array<FeaturedImage>;
  avatar?: Avatar;
  featured?: boolean;
  setModalType?: (val:string) => void;
  setOpen?: (val: boolean) => void;
  setShouldOpenPaymentModal?: (val: boolean) => void;
}

const DiscoverAvatarImage: React.FC<DiscoverAvatarImageProps> = (props: DiscoverAvatarImageProps) => {
  const { avatar, featured, featuredImages, setModalType, setOpen, setShouldOpenPaymentModal } = props;
  const history = useHistory();
  const user = useAppSelector(state => state.user.user);

  const dispatch = useAppDispatch();
  const setPaymentModalOpenState = (isOpen: boolean) => {
    dispatch(setPaymentModalOpen(isOpen));
  };
  const setPaymentTypeState = (newPaymentType: PaymentType) => {
    dispatch(setPaymentType(newPaymentType));
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleKeyDown = (e: any) => {
    if (e.keyCode === 13) {
      handleClick(e);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClick = (e: any) =>  {
    e.stopPropagation();
    if(featured) {
      if(featuredImages) {
        const img = featuredImages[0];
        if(img.navigateTo) {
          const [type, to] = img.navigateTo.split("*@*");
          switch(type) {
            case 'modal': renderModalType(to); break;
            case 'app': history.push(to); break;
            default:
              history.push(`/${to}`, {from: 'discover'});
          }
        }
      }
    }

    if(avatar && avatar.handle) {
      history.push(`/${avatar.handle}`, {from: 'discover'});
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleRecommendedClick = async (e: any) =>  {
    e.stopPropagation();
    if(avatar && avatar.handle) {
      dispatch(setActiveAvatar(avatar));
      history.push(`/add-friend?q=${avatar.handle}`, {
        openChat: true
      });
    }
  };

  const renderModalType = (type: string) => {
    if(!user?.isRegistered) {
      if(setModalType) setModalType("register");
      if(setOpen) setOpen(true);
      if(setShouldOpenPaymentModal) setShouldOpenPaymentModal(true);
    } else {
      if(type === "SubscriptionModal") setPaymentTypeState(PaymentType.SUBSCRIPTION);
      if(type === "CreditsModal") setPaymentTypeState(PaymentType.CREDIT);
  
      if(setPaymentModalOpenState) setPaymentModalOpenState(true);
    }
  };

  return (
    <DiscoverAvatarImageView
      featuredImages={featuredImages}
      featured={featured}
      handleClick={featured ? handleClick : handleRecommendedClick}
      handleKeyDown={handleKeyDown}
      avatar={avatar}
    />
  );
};

export default DiscoverAvatarImage;
