import React from 'react';

import { GoogleTranslator } from '../../../components';

export interface TranslateModalProps {
    close: () => void;
}

const TranslateModal: React.FC<TranslateModalProps> = (props: TranslateModalProps) => {

  const { close } = props;

  return (
    <div className="z-10 fixed justify-center inset-0 h-full w-full bg-gray-800 bg-opacity-60 backdrop-blur-sm" role="dialog" aria-modal="true">
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:p-0">
        <div className="inline-block bg-white rounded-lg p-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full sm:p-6">
          <div className="">
            <h2 className="mt-2 text-center text-xl sm:text-2xl font-bold text-gray-900">
              <span><svg xmlns="http://www.w3.org/2000/svg" className="inline h-7 text-blue-600" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M3 5h12M9 3v2m1.048 9.5A18.022 18.022 0 016.412 9m6.088 9h7M11 21l5-10 5 10M12.751 5C11.783 10.77 8.07 15.61 3 18.129" />
              </svg></span>
              Chat in your language</h2>
            <p className="mt-2 text-center text-lg text-gray-600">
            with automatic translations by
            <span className="font-medium text-blue-600"> Google Translate</span>
          </p>
          </div>
          <div className="mt-8 space-y-6 text-center" >
            
            <div className="w-3/6 text-center m-auto" >
              <GoogleTranslator />
            </div>
  
            <div className="mt-6">
            <button onClick={close} type="button" className="group relative w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
              Close
            </button>
            <p className="pt-2 text-xs text-gray-600">You can always change your settings later.</p>
          </div>
          </div>
        </div>
      </div>
    </div>
  );

};

export default TranslateModal;
