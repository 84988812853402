/* Component to handle navigation on the settings page */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { useHistory } from 'react-router-dom';

import { SubNavigationObject } from '../../interfaces';

interface SettingsHeader {
  classNames: (...classes: Array<string>) => string;
  subNavigation: Array<SubNavigationObject>;
  screenViewType: string;
  mobileMenuOpen: boolean;
  currentPage: string;
  setOpenPurchaseHistory: (value: boolean) => void;
}

const SettingsHeader: React.FC<SettingsHeader> = (props: SettingsHeader) => {
  const {
    classNames,
    subNavigation,
    screenViewType,
    mobileMenuOpen,
    currentPage,
    setOpenPurchaseHistory
  } = props;

  const history = useHistory();

  const handleClick = (name: string) => {
    history.push(`/profile/${name ? name.toLowerCase() : ''}`);
    setOpenPurchaseHistory(false);
  };

  return (
    <aside
      style={{
        transform:
          // eslint-disable-next-line no-nested-ternary
          screenViewType === 'web'
            ? 'translate(0px, 0px)'
            : mobileMenuOpen
            ? 'translate(0px, 0px)'
            : 'translate(-125%, 0px)'
      }}
      aria-label="Sections"
      className={`${screenViewType === 'mobile' && 'absolute'}  ${
        screenViewType === 'mobile' && mobileMenuOpen && 'w-full'
      } flex-shrink-0 w-96  inset-y-0 transform transition duration-200 ease-in-out bg-white border-r border-blue-gray-200 lg:flex lg:flex-col lg:flex-shrink-0 lg:order-first  sm:order-first md:order-first h-full˙`}
    >
      <div className="flex-shrink-0 h-16 px-6 border-b border-blue-gray-200 flex items-center">
        <p className="text-lg font-medium text-blue-gray-900">Settings</p>
      </div>
      <div className="flex-1 min-h-0 overflow-y-auto">
        {subNavigation && subNavigation.length !== 0 && subNavigation.map(item => (
          <div
            onClick={() => handleClick(item.href)}
            key={item.name}
            className={classNames(
              currentPage === item.name
                ? 'bg-blue-50 bg-opacity-50'
                : 'hover:bg-blue-50 hover:bg-opacity-50',
              'flex p-6 border-b border-blue-gray-200 cursor-pointer'
            )}
            aria-current={item.current ? 'page' : undefined}
          >
            <item.icon
              className="flex-shrink-0 -mt-0.5 h-6 w-6 text-blue-gray-400"
              aria-hidden="true"
            />
            <div className="ml-3 text-sm">
              <p className="font-medium text-blue-gray-900">{item.name}</p>
              <p className="mt-1 text-blue-gray-500">{item.description}</p>
            </div>
          </div>
        ))}
      </div>
    </aside>
  );
};

export default SettingsHeader;
