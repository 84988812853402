import { configureStore, isRejected, Middleware } from "@reduxjs/toolkit";
import camelcaseKeys from 'camelcase-keys';
import {
  persistStore,
  Persistor,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import { createLogger } from 'redux-logger';
import { toast } from 'react-toastify';

import rootReducer from '.';
import api from './api';

import { setPaymentModalOpen } from './payment';

const setMiddlewares = () => {
  const middlewares = [];
  middlewares.push(api.middleware);
  
  if (process.env.NODE_ENV === 'development') {
    const loggerOptions = {
      collapsed: true,
      // eslint-disable-next-line
      titleFormatter: (action: any, time: string, took: number) => {
        return `${action.type} -> ${time} --> ${took.toFixed(2)} ms`;
      }
    };
    const logger = createLogger(loggerOptions);
    middlewares.push(logger);
  }

  const rtkQueryErrorLogger: Middleware = () => (next) => (action) => {
    if (isRejected(action) && !(action?.error?.name === "ConditionError")) {
      console.warn(action);
      const isServerError = Math.floor(action.payload?.status / 100) === 5;
      const isFieldError = action.payload?.status === 400 && action.payload?.data?.fieldErrors;
      if (!isServerError && !isFieldError) {
        toast.error(action.payload?.data?.message || 'Something went wrong!', {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      if (isServerError && window.location.pathname !== '/500') {
        store.dispatch(setPaymentModalOpen(false));
        window.location.href = '/500';
      }
    }
    return next(action);
  };
  middlewares.push(rtkQueryErrorLogger);

  const camelcaseKeysMiddleware: Middleware = () => (next) => (action) => {
    if(action.payload && (action.type.startsWith('api/') || action.type.startsWith('base/'))) {
      const result = camelcaseKeys(action.payload, {
        deep: true
      });
      action.payload = result;
    }
    return next(action);
  };
  middlewares.push(camelcaseKeysMiddleware);

  return middlewares;
};

export const store = configureStore({
  reducer: rootReducer,
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      }
    }).concat(setMiddlewares()),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch

export const persistor: Persistor = persistStore(store);
