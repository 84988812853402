/* Vertical navigation bar component used in desktop view */
import React from 'react';
import { useHistory } from 'react-router-dom';

import { User, SidebarNavigationObject } from '../../../interfaces';

import NavBar from './NavBar';
import MainIcon from './MainIcon';

export interface SidebarProps {
  sidebarNavigation: Array<SidebarNavigationObject>;
  unreadsSum: number;
  user?: User;
  isMobileView: boolean;
  setOpenMain: (val:boolean) => void;
  setModalTypeMain: (val:string) => void;
}

const disabledPagesForNoUser = ['Chats', 'Discover'];
const disabledPagesForTempUser = ['Saved', 'My profile'];

const Sidebar: React.FC<SidebarProps> = (props: SidebarProps) => {
  const { sidebarNavigation, user, unreadsSum, isMobileView, setOpenMain, setModalTypeMain} = props;
  const history = useHistory();

  return (
    <nav
      aria-label="Sidebar"
      className={`hidden md:block ${isMobileView && "h-screen"} flex-shrink-0 bg-gray-800 overflow-y-auto`}
    >
      <div className="absolute inset-y-0 left-0 md:static md:flex-shrink-0">
        <button
          type='button'
          onClick={() => history.push("/discover")}
          className="flex items-center justify-center h-16 w-16 bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-600 md:w-20"
        >
          <MainIcon />
        </button>
      </div>
      <div className="relative w-20 flex flex-col p-3 space-y-3">
        {sidebarNavigation && sidebarNavigation.length !== 0 &&
          sidebarNavigation.map(item => {
            const isDisabled = (disabledPagesForTempUser.includes(item.name) && !user?.isRegistered) || (disabledPagesForNoUser.includes(item.name) && !user);
            return <NavBar
              unreadsSum={unreadsSum}
              key={item.id}
              item={item}
              isDisabled={isDisabled}
              disabledOnClick={() => {
                setOpenMain(true);
                setModalTypeMain('register');
              }}/>;
          })}
      </div>
    </nav>
  );
};

export default Sidebar;
