import React from "react";

import { Partnership as PartnershipType } from "../../../interfaces";

import Partnership from "./Partnership";
import "./style.css";

interface Props {
  partnerships: Array<PartnershipType>;
  isMobileView: boolean;
}

const Partnerships: React.FC<Props> = (props: Props) => {
  const { partnerships, isMobileView } = props;
  return (
    <div className={`${!isMobileView ? "flex-1 max-h-screen overflow-y-auto pb-16" : "flex flex-1 h-screen bg-gray-100 pt-16"}`}>
      <div className="deal py-4 flex flex-col px-4 pb-20 lg:pb-4 pt-4">
        {partnerships.map((partnership, idx) => (
          <Partnership key={idx.toString()} partnership={partnership} />
        ))}
      </div>
    </div>
  );
};

export default Partnerships;
