/* Component to display the message choices at the bottom of the chat */
import React, { useEffect } from 'react';

import { Avatar, User, MessageHistoryResponse, MsgChoice } from '../../../interfaces';

import { ConfirmationModalProps } from '../../../components';
import { useAppDispatch } from "../../../reducers/hooks";
import { incrementNodeInteractionCounter, setNodeInteractionCounter } from '../../../reducers/user';
import { ConnectionStatus } from '../../../reducers/websocket';
import WSApi from '../../../services/ws-api-handler/ws-api';

import ChoicepickerView from './ChoicepickerView';

interface ChoicePickerProps {
  user: User;
  isFetching: boolean;
  msgHistory: MessageHistoryResponse;
  activeAvatar: Avatar;
  connectionStatus: ConnectionStatus;
  accessToken: string;
  scrollRef: React.RefObject<HTMLInputElement>;
  isMobileView: boolean;
  setModalType: (val: string) => void;
  setOpen: (val: boolean) => void;
  nodeInteractionCounter: number;
  initialBailoutProps: ConfirmationModalProps
}

const ChoicePicker: React.FC<ChoicePickerProps | null> = (
  props: ChoicePickerProps
) => {
  const { user, msgHistory, activeAvatar, connectionStatus, accessToken, isFetching, scrollRef, isMobileView, setModalType, setOpen, nodeInteractionCounter,
  initialBailoutProps} = props;
  const { ws } = WSApi;
  const dispatch = useAppDispatch();

  const sendMessage = (msgChoice: MsgChoice, msgChoicesHeight: number | undefined) => {
    if (
      !user?.isRegistered &&
      msgHistory &&
      activeAvatar &&
      msgHistory[activeAvatar.id] &&
      msgHistory[activeAvatar.id].messageHistory
    ) {
      for (let i = 0; i < msgHistory[activeAvatar.id].messageHistory!.length; i += 1) {
        if (msgHistory[activeAvatar.id].messageHistory![i].isLocked) {
          setModalType("register");
          setOpen(true);
          return;
        }
      }
    }
    scrollUp(msgChoicesHeight);

    if(!user.isRegistered) {
      dispatch(incrementNodeInteractionCounter());
    }
    ws.sendMessage(accessToken, msgChoice);
  };

  useEffect(() => {
    if(user && !user.isRegistered && nodeInteractionCounter >= 12) {
      setModalType("register");
      setOpen(true);
      dispatch(setNodeInteractionCounter(0));
    }

  }, [nodeInteractionCounter]);

  const scrollUp = (msgChoicesHeight: number | undefined) => {
    let height;

    if (isMobileView) {
      height = window.pageYOffset;
    } else {
      height = document.getElementById('messages')?.scrollTop ? document.getElementById('messages')?.scrollTop : undefined;
    }

    if (height) {
      height -= msgChoicesHeight || 0;
      height -= 20;
      if (isMobileView) {
        window.scrollTo({
          top: height,
          behavior: 'auto'
        });
      } else {
        document.getElementById('messages')?.scrollTo({
          top: height,
          behavior: 'auto'
        });
      }
      
    }
  };

  return (
    <ChoicepickerView
      user={user}
      msgHistory={msgHistory}
      activeAvatar={activeAvatar}
      connectionStatus={connectionStatus}
      scrollRef={scrollRef}
      isFetching={isFetching}
      sendMessage={sendMessage}
      initialBailoutProps={initialBailoutProps}
    />
  );
};

export default ChoicePicker;
