import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { User } from '../../interfaces';

import { WithMobileDetectionProps, ConfirmationModalProps } from '../../components';
import { useAppDispatch, useAppSelector } from "../../reducers/hooks";
import { setPaymentModalOpen } from '../../reducers/payment';
import { setActiveAvatar} from '../../reducers/avatar';

import { MainContext } from '../Main/Main';
import BaseView from './BaseView';

enum NavigationType {
  SIGN_IN = 'SIGN_IN',
  SIGN_UP = 'SIGN_UP'
}

interface BaseProps extends WithMobileDetectionProps {
  match: {params: {avatarHandle: string}},
}

const Base: React.FC<BaseProps> = (props: BaseProps) => {
  const {
    match,
    screenViewType,
    isBarOpen,
    handleIsBarOpen,
    transformStyle,
    transformStyleDiv,
    openContactList,
    handleIsContactlistOpen,
    isMobileView,
    isIOS
  } = props;

  const {
    isPaymentModalOpen,
    activeAvatar,
    friendAvatars,
    msgHistory,
    paymentType,
    user,
  } = useAppSelector((state) => ({
    isPaymentModalOpen: state.payment.isPaymentModalOpen,
    activeAvatar: state.avatar.activeAvatar,
    friendAvatars: state.avatar.friendAvatars,
    msgHistory: state.base.msgHistory,
    paymentType: state.payment.paymentType,
    user: state.user.user
  }));

  const [contentModalOpen, setContentModalOpen] = useState<boolean>(false);
  const [closeChatForm, setCloseChatForm] = useState<boolean>(false);
  const [contentCreditCost, setContentCreditCost] = useState<number | undefined>();
  const [contentNodeId, setContentNodeId] = useState<number | undefined>();
  const [mediaUrl, setMediaUrl] = useState('');
  const [openMediaViewer, setOpenMediaViewer] = useState(false);

  const {
    setModalTypeMain: setModalType,
    openMain: open,
    setOpenMain: setOpen,
    setInitModalEmail,
    directToPayment,
    setDirectToPayment
  } = useContext(MainContext);

  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();

  React.useEffect(() => {
    if (!location || !location.state || !location.state.email || !location.state.type) {
      return;
    }
    const {email, type } = location.state;
    if (email && type && Object.values(NavigationType).includes(type)) {
      setOpen(true);
      setInitModalEmail(email);
      switch (type) {
        case NavigationType.SIGN_IN:
          setModalType('login');
          break;
        case NavigationType.SIGN_UP:
          setModalType('register');
          break;
        default:
          setModalType('register');
      }
    }
  }, []);

  React.useEffect(() => {
    if (!activeAvatar || (match.params.avatarHandle && activeAvatar.handle !== match.params.avatarHandle)) {
      const tempAvatar = friendAvatars?.find(a => a.handle === match.params.avatarHandle);
      dispatch(setActiveAvatar(tempAvatar));
    }

    if (activeAvatar && !match.params.avatarHandle) {
      dispatch(setActiveAvatar(undefined));

      history.push('/chat');
    }
  }, [location]);

  const closeUnfriendAvatarModal = () => {
    setUnfriendAvatarModalOpen(false);
  };

  const initUnfriendAvatar = {
    close: closeUnfriendAvatarModal,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    handleModalAction: () => {},
    handleModalActionParameter: '',
    modalText: null,
    leftBtnText: '',
    rightBtnText: '',
    modalTitle: '',
  };
  const [unfriendAvatarModalOpen, setUnfriendAvatarModalOpen] = useState<boolean>(false);
  const [unfriendAvatarProps, setUnfriendAvatarProps] = useState<ConfirmationModalProps>(initUnfriendAvatar);

  useEffect(() => {
    if (!activeAvatar || (match.params.avatarHandle && activeAvatar.handle !== match.params.avatarHandle)) {
      const tempAvatar = friendAvatars?.find(a => a.handle === match.params.avatarHandle);
      dispatch(setActiveAvatar(tempAvatar));
    }

    if (activeAvatar && !match.params.avatarHandle) {
      history.push(`/chat/${activeAvatar.handle}`);
    }

  }, [msgHistory]);

  useEffect(() => {
    if(isMobileView) {
      if(!isBarOpen) {
        setCloseChatForm(false);
      } else {
        setCloseChatForm(true);
      }
    }

    if(!isBarOpen) {
      setOpenMediaViewer(false);
    }
  },[history.location]);

  const transformChatForm = () => {
    if(isMobileView) {
      if(openContactList) {
        if(isBarOpen) {
          setCloseChatForm(true);
        } else {
          setCloseChatForm(false);
        }
      } else {
        setCloseChatForm(false);
      }
    }
  };
  useEffect(() => {
    transformChatForm();
  },[openContactList, isBarOpen]);

  const setPaymentModalOpenState = (isOpen: boolean) => {
    dispatch(setPaymentModalOpen(isOpen));
  };

  const handleMediaClick = (url: string, setCurrentReference?: (refId:string) => void, refId?:string) => {
    setMediaUrl(url);
    setOpenMediaViewer(true);
    if (setCurrentReference && refId) {
      setCurrentReference(refId);
    }
  };

  useEffect(() => {
    if(activeAvatar && activeAvatar.avatarName) {
      document.title = `${activeAvatar.avatarName} - Fantxt`;
    } else {
      document.title = 'Chat - Fantxt';
    }
  }, [activeAvatar]);

  return (
    <BaseView
      openMediaViewer={openMediaViewer}
      mediaUrl={mediaUrl}
      activeAvatar={activeAvatar}
      setOpenMediaViewer={setOpenMediaViewer}
      setModalType={setModalType}
      open={open}
      setOpen={setOpen}
      contentModalOpen={contentModalOpen}
      setContentModalOpen={setContentModalOpen}
      setPaymentModalOpen={setPaymentModalOpenState}
      contentCreditCost={contentCreditCost}
      contentNodeId={contentNodeId}
      isPaymentModalOpen={isPaymentModalOpen}
      screenViewType={screenViewType}
      setSlideInContactList={handleIsBarOpen}
      setContentCreditCost={setContentCreditCost}
      setContentNodeId={setContentNodeId}
      handleMediaClick={handleMediaClick}
      transformContactList={transformStyle}
      slideInContactList={isBarOpen}
      transformContactListDiv={transformStyleDiv}
      paymentType={paymentType}
      openContactList={openContactList}
      setOpenContactList={handleIsContactlistOpen}
      unfriendAvatarModalOpen={unfriendAvatarModalOpen}
      unfriendAvatarProps={unfriendAvatarProps}
      setUnfriendAvatarModalOpen={setUnfriendAvatarModalOpen}
      setUnfriendAvatarProps={setUnfriendAvatarProps}
      isMobileView={isMobileView}
      closeChatForm={closeChatForm}
      setCloseChatForm={setCloseChatForm}
      msgHistory={msgHistory}
      user={user as User}
      isIOS={isIOS}
      directToPayment={directToPayment}
      setDirectToPayment={setDirectToPayment}
    />
  );
};

export default Base;
