/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { RefObject } from 'react';
import { ChatIcon, ExternalLinkIcon } from '@heroicons/react/outline';

import { Avatar } from '../../interfaces';

import { Breadcrumb } from '../../components';
import { MediaViewer } from '../../containers';

import AvatarFeed from './_AvatarFeed';

interface AvatarProfileViewProps {
  openMediaViewer: boolean;
  mediaUrl: string;
  avatarState: Avatar | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  history: any;
  setOpenMediaViewer: (val: boolean) => void;
  handleMediaClick: (val: string) => void;
  copyProfileLink: () => void;
  handleMessageClick: () => void;
  renderModals: () => JSX.Element;
  setContentModalOpen: (isOpen: boolean) => void;
  renderBreadCrumbText: () => string;
  onScroll: () => void;
  scrollRef: RefObject<HTMLUListElement>;
  isMobileView: boolean;
  isIOS: boolean;
}

const AvatarProfileView: React.FC<AvatarProfileViewProps> = (
  props: AvatarProfileViewProps
) => {
  const {
    openMediaViewer,
    mediaUrl,
    avatarState,
    history,
    scrollRef,
    setOpenMediaViewer,
    handleMediaClick,
    copyProfileLink,
    handleMessageClick,
    renderModals,
    setContentModalOpen,
    renderBreadCrumbText,
    onScroll,
    isMobileView,
    isIOS
  } = props;

  // No view until avatar loads (no empty canvas for content)
  if (!avatarState) {
    return <div/>;
  }

  return (
    <>
      {openMediaViewer ? (
        <MediaViewer
          isMobileView={isMobileView}
          mediaNodes={[{ url: mediaUrl, title: avatarState?.avatarName || '' }]}
          initialMediaIdx={0}
          setOpenMediaViewer={setOpenMediaViewer}
          isIOS={isIOS}
          fromAvatarPost
          handle={avatarState?.handle}
        />
      ) : null}
      {renderModals()}
      <main onScroll={onScroll} ref={scrollRef} className={openMediaViewer ? 'hidden' : `${isMobileView && 'pt-16'} flex-1 overflow-y-scroll`}>
        <Breadcrumb
          history={history}
          text={renderBreadCrumbText()}
          customBackFunction={history.goBack}
          navClass={!isMobileView ? "border-b border-blue-gray-200 flex items-center space-x-2 lg:space-x-4 py-3" : undefined}
        />
        <div className="min-h-screen bg-gray-50">
          <div>
            <img
              className="h-32 w-full object-cover lg:h-48"
              src={avatarState && avatarState.backgroundPicture ? avatarState.backgroundPicture : ''}
              alt={avatarState ? avatarState.avatarName : ''}
            />
          </div>
          <div className="px-4 sm:px-6 pb-4 lg:px-8 bg-white shadow-sm">
            <div className="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">
              <div className="flex">
                <img
                  className="h-24 w-24 rounded-full ring-4 ring-white sm:h-32 sm:w-32"
                  src={avatarState ? avatarState.avatarPicture : ''}
                  alt={avatarState ? avatarState.avatarName : ''}
                />
              </div>
              <div className="mt-6 sm:flex-1 sm:min-w-0 sm:flex sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
                {/* <!-- Mobile only --> */}
                <div className="sm:hidden 2xl:block mt-6 min-w-0 flex-1">
                  <h1 className="text-2xl font-bold text-gray-900 truncate">
                    {avatarState && avatarState.avatarName}
                  </h1>
                  <span>{avatarState && avatarState.bio}</span>
                </div>
                <div className="mt-6 flex flex-col justify-stretch space-y-3 sm:flex-row sm:space-y-0 sm:space-x-4">
                  <button
                    onClick={copyProfileLink}
                    type="button"
                    className="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  >
                    <ExternalLinkIcon
                      className="-ml-1 mr-2 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    <span>Copy profile link</span>
                  </button>
                  <button
                    onClick={handleMessageClick}
                    type="button"
                    className="inline-flex justify-center px-4 py-2 border border-blue-700 shadow-sm bg-blue-600 text-sm font-medium rounded-md text-white hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 lg:px-20"
                  >
                    <ChatIcon
                      className="-ml-1 mr-2 h-5 w-5 text-white"
                      aria-hidden="true"
                    />
                    <span>Message {avatarState?.avatarName.split(" ")[0] || ''}</span>
                  </button>
                </div>
              </div>
            </div>
            {/* <!-- Desktop only --> */}
            <div className="hidden sm:block 2xl:hidden mt-6 min-w-0 flex-1">
              <h1 className="text-2xl font-bold text-gray-900 truncate">
                {avatarState && avatarState.avatarName}
              </h1>
              <span>{avatarState && avatarState.bio}</span>
            </div>
          </div>
          {/* TODO: change isPromoted to isSubscribeable or something like this */}
          {avatarState && avatarState.membership && !avatarState.membership.active && (
            <div className="mt-2 px-4 sm:px-6 py-4 lg:px-8 bg-white shadow-sm">
              <h5 className="uppercase tracking-wider font-medium text-xs md:text-sm text-gray-500 mb-1">
                Subscription
              </h5>
              <button
                type="button"
                className="block w-full px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                onClick={()=>setContentModalOpen(true)}
              >
                Subscribe for {avatarState.membership.price} credits / month
              </button>
            </div>
          )}
          {avatarState && (
            <AvatarFeed
              isMobileView={isMobileView}
              posts={avatarState?.posts}
              avatarName={avatarState!.avatarName}
              avatarHandle={avatarState!.handle}
              avatarPicture={avatarState!.avatarPicture}
              handleMediaClick={handleMediaClick}
            />
          )}
        </div>
      </main>
    </>
  );
};

export default AvatarProfileView;
