/* Header component for back button */
import React from 'react';
import { History } from 'history';

interface BreadcrumbProps {
  setMobileMenuOpen?: (value: boolean) => void;
  isRedirect?: boolean;
  history?: History;
  navClass?: string;
  buttonClass?: string;
  backRedirect?: string;
  text: string;
  customBackFunction?: () => void;
  customTextFunction?: () => void;
  setPage?: (val: string) => void;
}

const Breadcrumb: React.FC<BreadcrumbProps> = (props: BreadcrumbProps) => {
  const { setMobileMenuOpen, customBackFunction, customTextFunction, isRedirect, history, navClass, buttonClass, backRedirect, text, setPage } = props;

  const handleBackClick = () => {
    if (customBackFunction) {
      customBackFunction();
      return;
    }
    if (isRedirect && (!backRedirect && backRedirect !== '')) {
      return;
    }
    if(isRedirect) {
      if(history) {
        history.push(backRedirect!);
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      if(setMobileMenuOpen) {
        if(setPage) {
          setPage("");
        }
        setMobileMenuOpen(true);
      }
    }
  };

  return (
    <nav
      aria-label="Breadcrumb"
      className={`${navClass || "g-white fixed top-0 bg-white w-full border-b border-blue-gray-200 flex items-center space-x-2 lg:space-x-4 py-3"}`}
    >
      <button
        onClick={handleBackClick}
        type="button"
        className="inline-flex items-center justify-center rounded-full h-10 w-10 transition duration-500 ease-in-out text-gray-500 hover:bg-gray-300 focus:outline-none"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M15 19l-7-7 7-7"
          />
        </svg>
      </button>
      <div className={buttonClass || ''}>
        <button type="button" onClick={customTextFunction || handleBackClick}>
          {text}
        </button>
      </div>
    </nav>
  );
};

export default Breadcrumb;
