/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from 'react';

import { Avatar, MediaNode, MessageNode, PaymentType } from '../../interfaces';

import { Breadcrumb, ImageHandler, VideoHandler, LoadingIndicator } from '../../components';
import { MediaViewer, PaymentModal, UnlockContentModal } from '../../containers';

interface GalleryViewProps {
    openMediaViewer: boolean;
    setOpenMediaViewer: (val: boolean) => void;
    contentModalOpen: boolean;
    setContentModalOpen: (val: boolean) => void;
    setPaymentModalOpen: (val: boolean) => void;
    contentCreditCost: number | undefined;
    contentNodeId: number | undefined;
    isPaymentModalOpen?: boolean;
    setContentCreditCost: (val: number | undefined) => void;
    setContentNodeId: (val: number | undefined) => void;
    handleMediaClick: (val: string) => void;
    paymentType?: PaymentType;
    setRefreshAfterUnlock: (val: boolean) => void;
    friendAvatars: Array<Avatar>
    id: string | undefined;
    mediaNodes: Array<MediaNode>;
    mediaIdx: number;
    messageNodes: Array<MessageNode>;
    avatarName: string | undefined;
    avatarHandle: string | undefined;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    history: any;
    isMobileView: boolean;
    isIOS: boolean;
    userEmail?: string;
    isLoading: boolean;
}

const GalleryView : React.FC<GalleryViewProps> = (props: GalleryViewProps) => {
    const {
        openMediaViewer,
        setOpenMediaViewer,
        contentModalOpen,
        setContentModalOpen,
        setPaymentModalOpen,
        contentCreditCost,
        contentNodeId,
        isPaymentModalOpen,
        setContentCreditCost,
        setContentNodeId,
        handleMediaClick,
        paymentType,
        setRefreshAfterUnlock,
        friendAvatars,
        id,
        mediaNodes,
        mediaIdx,
        messageNodes,
        avatarName,
        avatarHandle,
        history,
        isMobileView,
        isIOS,
        userEmail,
        isLoading
    } = props;

    return (
        <main className={`flex-1 h-screen ${isMobileView && "pb-16"}`}>
        <UnlockContentModal
          contentModalOpen={contentModalOpen}
          setContentModalOpen={setContentModalOpen}
          setPaymentModalOpen={setPaymentModalOpen}
          contentCreditCost={contentCreditCost}
          contentNodeId={contentNodeId}
          avatar={id && friendAvatars ? friendAvatars.find(avatar => avatar.id === Number(id)) : undefined}
          refresh={setRefreshAfterUnlock}
          />
        <PaymentModal
          open={isPaymentModalOpen || false}
          setOpen={setPaymentModalOpen}
          avatar={id && friendAvatars ? friendAvatars.find(avatar => avatar.id === Number(id)) : undefined}
          redirectPage="gallery"
          paymentType={paymentType || PaymentType.SUBSCRIPTION}
          isMobileView={isMobileView}
        />
  
        { openMediaViewer ? (
          <MediaViewer
            isMobileView={isMobileView}
            mediaNodes={mediaNodes}
            initialMediaIdx={mediaIdx}
            setOpenMediaViewer={setOpenMediaViewer}
            avatarId={Number(id)}
            isIOS={isIOS}
            handle={avatarHandle}
          />
        ) : null }
  
          <section className={openMediaViewer ? 'hidden' : 'flex-1 h-full flex flex-col overflow-auto bg-white'}>
          { id ? <Breadcrumb history={history} text={avatarName || ''} backRedirect={`/chat/${avatarHandle}`} isRedirect navClass="fixed z-50 bg-white w-full bg-opacity-95 border-b border-blue-gray-200 flex items-center space-x-2 lg:space-x-4 py-3"/> : null}
            <div className={`${id ? 'my-16' : ''} flex-1 justify-between flex flex-col`}>
              <ul className="gallery-ul">
                {messageNodes.length !== 0 &&
                  messageNodes.map((node: MessageNode) => (
                    <li key={node && node.nodeId} className="gallery-li">
                      <div className="gallery-item group" tabIndex={0}>
                        {node &&
                        node.mediaFile &&
                        node.mediaFile.includes('.m3u8') ? (
                          <>
                            <VideoHandler
                              nodeId={node.nodeId}
                              bucketVideo={node.mediaFile}
                              locked={'isLocked' in node}
                              available={!node.isLocked}
                              requiredCredits={
                                node.creditCost && node.creditCost
                              }
                              setContentModalOpen={setContentModalOpen}
                              setContentNodeId={setContentNodeId}
                              setContentCreditCost={setContentCreditCost}
                              handleVideoClick={handleMediaClick}
                              fromGallery
                            />
                          </>
                        ) : (
                          <ImageHandler
                            nodeId={node.nodeId}
                            bucketImage={node.mediaFile}
                            locked={'isLocked' in node}
                            available={!node.isLocked}
                            requiredCredits={node.creditCost && node.creditCost}
                            setContentModalOpen={setContentModalOpen}
                            setContentNodeId={setContentNodeId}
                            setContentCreditCost={setContentCreditCost}
                            handleImageClick={handleMediaClick}
                            fromGallery
                            userEmail={userEmail}
                          />
                        )}
                      </div>
                    </li>
                  ))
                }
              </ul>
              {isLoading && messageNodes.length === 0 && (
                <div className="content-center m-auto text-center">
                  <LoadingIndicator />
                </div>
              )}
              {messageNodes.length === 0 && !isLoading && (
                <div className="content-center m-auto text-center">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-40 w-40 text-gray-300 m-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z" />
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 13a3 3 0 11-6 0 3 3 0 016 0z" />
                  </svg>
                  <h3 className="text-gray-400 font-bold text-2xl">Nothing to show yet</h3>
                  <p className="text-gray-400">Photos and videos you receive will show up in the gallery.</p>
                </div>
              )}
            </div>
          </section>
      </main>
    );
};

export default GalleryView;