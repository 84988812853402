/* Intermediate component to switch between image and video */
import React from 'react';

import ImageHandler from '../ImageHandler';
import VideoHandler from '../VideoHandler';

interface MediaHandlerProps {
  nodeId?: number;
  locked?: boolean;
  actionAfterMediaLoaded?: () => void;
  available?: boolean;
  mediaFile?: string | null;
  requiredCredits?: number;
  referenceId?: string;
  inChat?: boolean;
  setContentModalOpen?: (val:boolean) => void;
  setCurrentReference?: (ref:string) => void;
  setContentCreditCost?: (val:number) => void;
  setContentNodeId?: (val:number) => void;
  handleMediaClick?: (url: string, setCurrentReference?: (refId:string) => void, refId?:string) => void;
  isUserTemp?: boolean;
  handleRegisterClick?: () => void;
  userEmail?: string;
}

enum MediaType {
  VIDEO = 'VIDEO',
  IMG = 'IMG'
}

const MediaHandler: React.FC<MediaHandlerProps> = (props: MediaHandlerProps) => {
  const { isUserTemp, nodeId, referenceId, inChat, locked, available, mediaFile, requiredCredits, actionAfterMediaLoaded,
    setContentModalOpen, setCurrentReference, setContentCreditCost, setContentNodeId, handleMediaClick, handleRegisterClick,
    userEmail } = props;

  const isVideoOrImg = (file: string) => {
    const mediaSplit = file.split('.');

    if (
      mediaSplit &&
      mediaSplit[mediaSplit.length - 1] &&
      mediaSplit[mediaSplit.length - 1].toLowerCase() === 'm3u8'
    ) {
      return MediaType.VIDEO;
    }

    return MediaType.IMG;
  };

  switch (isVideoOrImg(mediaFile!)) {
    case MediaType.VIDEO:
      return (
        <VideoHandler
          nodeId={nodeId}
          actionAfterMediaLoaded={actionAfterMediaLoaded}
          referenceId={referenceId}
          locked={locked}
          requiredCredits={requiredCredits}
          available={available}
          bucketVideo={mediaFile}
          setContentModalOpen={setContentModalOpen}
          setCurrentReference={setCurrentReference}
          setContentNodeId={setContentNodeId}
          setContentCreditCost={setContentCreditCost}
          handleVideoClick={handleMediaClick}
          inChat={inChat}
          isUserTemp={isUserTemp}
          handleRegisterClick={handleRegisterClick}
        />
      );
    case MediaType.IMG:
    default:
      return (
        <ImageHandler
          nodeId={nodeId}
          actionAfterMediaLoaded={actionAfterMediaLoaded}
          referenceId={referenceId}
          bucketImage={mediaFile}
          locked={locked}
          available={available}
          requiredCredits={requiredCredits}
          setContentModalOpen={setContentModalOpen}
          setCurrentReference={setCurrentReference}
          setContentNodeId={setContentNodeId}
          setContentCreditCost={setContentCreditCost}
          handleImageClick={handleMediaClick}
          inChat={inChat}
          isUserTemp={isUserTemp}
          handleRegisterClick={handleRegisterClick}
          userEmail={userEmail}
        />
      );
  }
};

export default MediaHandler;
