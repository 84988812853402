import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';

import { Avatar, User } from '../../interfaces';

import { UnlockContentModalBtn } from '../../components';
import MembershipBlock from '../MembershipBlock';

import UnlockContentModalHeader from './UnlockContentModalHeader';

interface UnlockContentModalViewProps {
  contentModalOpen: boolean;
  setContentModalOpen: (val: boolean) => void;
  onClose: () => void;
  avatar?: Avatar;
  user: User;
  contentCreditCost?: number | undefined;
  handleClick: (action: string) => Promise<void>;
  userCreditbalance: number;
  buttonDisabled: boolean;
}

const UnlockContentModalView: React.FC<UnlockContentModalViewProps> = (
  props: UnlockContentModalViewProps
) => {
  const {
    contentModalOpen,
    setContentModalOpen,
    onClose,
    avatar,
    user,
    contentCreditCost,
    handleClick,
    userCreditbalance,
    buttonDisabled,
  } = props;

  return (
    <Transition.Root
      show={
        contentModalOpen &&
        (contentCreditCost !== undefined && userCreditbalance
          ? userCreditbalance >= contentCreditCost
          : false)
      }
      as={Fragment}
    >
      <Dialog
        as="div"
        auto-reopen="true"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={() => onClose()}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-800 bg-opacity-50 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block align-middle h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div id="unlockContentModal" className="inline-block align-bottom bg-white self-center rounded-lg p-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-full sm:max-w-xl sm:p-6">
              <UnlockContentModalHeader balance={user?.credits} />

              <div className="mt-8">
                <UnlockContentModalBtn
                  hideIcon
                  id="unlockContentModalUnlockButton"
                  divClass="mt-4 text-center"
                  btnClass="group relative w-full sm:max-w-lg inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-4 bg-blue-500 text-lg font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  btnText={`Unlock for ${contentCreditCost} credits`}
                  handleClick={handleClick}
                  disabled={buttonDisabled}
                />
                <UnlockContentModalBtn
                  hideIcon
                  id="unlockContentModalCancelButton"
                  divClass="mt-4 text-center"
                  btnClass="group relative w-full sm:max-w-lg inline-flex justify-center rounded-md border border-transparent bg-gray-100 hover:bg-gray-200 px-4 py-2 font-medium text-gray-500 hover:text-gray-600"
                  btnText="Cancel"
                  handleClick={handleClick}
                />

                {/* <!-- MEMBERSHIP AVAILABLE BLOCK STARTS // Only show if Membership is available --> */}
                {avatar && (
                  <MembershipBlock
                    avatar={avatar}
                    setOpen={setContentModalOpen}
                    subscribable
                  />
                )}
                {/* <!-- MEMBERSHIP AVAILABLE BLOCK END --> */}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default UnlockContentModalView;
