/* Component to display profile picture or if not available, the user's monograms */
import React from 'react';

interface FullName {
  firstName: string;
  lastName: string;
  nickname: string;
}

interface ProfilePictureProps {
  name: FullName;
  profilePicture: string;
  className?: string;
  fontSize?: string;
  isOnProfilePage?: boolean;
}

const ProfilePicture: React.FC<ProfilePictureProps> = (
  props: ProfilePictureProps
) => {
  const { name, profilePicture, className, fontSize, isOnProfilePage } = props;

  if (profilePicture === '') {

    let initials = name?.firstName ? name.firstName[0].toUpperCase() : '';
    if (name?.lastName)
      initials += name.lastName[0].toUpperCase();
    if (initials === '') {
      if (name?.nickname)
        initials = name.nickname[0].toUpperCase();
      else
        initials = 'U';
    }

    return (
      <span
        className={
          className ||
          'inline-flex items-center justify-center h-6 w-6 rounded-full bg-gray-500 order-2'
        }
      >
        <span
          className={`text-${
            fontSize || 'xs'
          } font-medium leading-none text-white`}
        >
          {initials}
        </span>
      </span>
    );
  }

  return (
    <img
      src={profilePicture}
      alt={`${name.firstName}_${name.lastName}`}
      className={isOnProfilePage ? "inline-flex items-center justify-center h-12 w-12 rounded-full bg-gray-500" :
      "inline-flex items-center justify-center h-6 w-6 rounded-full bg-gray-500 order-2" }
    />
  );
};

export default ProfilePicture;
