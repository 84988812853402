import {
  CheckPaymentStatusResponse,
  InitiatePaymentRequest,
  InitiatePaymentResponse,
  PaymentProduct,
  Payment,
  CancelMembershipResponse,
  GeneralWithCreditsResponse,
  HidePaymentResponse,
  BillingSettings
} from '../../interfaces';

import baseApi from '../api';

const payment = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getProducts: builder.query<Array<PaymentProduct>, void>({
      query: () => ({
        url: '/products/',
      }),
    }),
    initiatePayment: builder.mutation<InitiatePaymentResponse, InitiatePaymentRequest>({
      query: (credentials) => ({
        url: '/payments/new/',
        method: 'POST',
        body: credentials,
      })
    }),
    getPurchaseHistory: builder.query<Array<Payment>, void>({
      query: () => ({
        url: '/payments/',
      })
    }),
    checkPaymentStatus: builder.mutation<CheckPaymentStatusResponse, number>({
      query: (paymentId) => ({
        url: `/payments/${paymentId}/`,
        method: 'GET'
      })
    }),
    hidePayment: builder.mutation<HidePaymentResponse, number>({
      query: (id) => ({
        url: `/payments/hide/${id}/`,
        method: 'PATCH',
        body: { hidden: true }
      })
    }),
    getBillingSettings: builder.query<BillingSettings, void>({
      query: () => ({
        url: '/billing/',
      }),
    }),
    activateMembership: builder.mutation<GeneralWithCreditsResponse, string>({
      query: (handle) => ({
        url: `/memberships/`,
        method: 'POST',
        body: { handle }
      })
    }),
    cancelMembership: builder.mutation<CancelMembershipResponse, string>({
      query: (handle) => ({
        url: `/membership/${handle}/`,
        method: 'DELETE'
      })
    }),
  }),
  overrideExisting: false,
}).enhanceEndpoints({
  addTagTypes: ["PurchaseHistory", "BillingSettings"],
  endpoints: {
    getPurchaseHistory: {
      providesTags: ["PurchaseHistory"],
    },
    hidePayment: {
      invalidatesTags: (result, error) => error ? [] : ["PurchaseHistory"],
    },
    getBillingSettings: {
      providesTags: ["BillingSettings"],
    },
    activateMembership: {
      invalidatesTags: (result, error) => error ? [] : ["BillingSettings"],
    },
    cancelMembership: {
      invalidatesTags: (result, error) => error ? [] : ["BillingSettings"],
    },
  },
});

export const {
  useCheckPaymentStatusMutation,
  useGetProductsQuery,
  useInitiatePaymentMutation,
  useGetPurchaseHistoryQuery,
  useGetBillingSettingsQuery,
  useActivateMembershipMutation,
  useCancelMembershipMutation,
  useHidePaymentMutation,
} = payment;
