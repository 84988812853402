import moment from 'moment';

import {
  MessageHistoryResponse,
  SignedUrlRequest,
  SignedUrlResponse,
  MessageNode,
  GeneralWithCreditsResponse,
} from '../../interfaces';

import baseApi from '../api';

const msgHistory = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getMsgHistoryByAvatar: builder.mutation<MessageHistoryResponse, null>({
      query: () => ({
        url: '/conversations/',
        method: 'GET'
      })
    }),
    setMessagesRead: builder.mutation<{status: string, message: string}, {avatarId: number, messageIds: Array<number>}>({
      query: (data) => ({
        url: "/conversations/read/",
        method: 'POST',
        body: data,
      })
    }),
    getPresignedUrl: builder.mutation<SignedUrlResponse, SignedUrlRequest>({
      query: (credentials) => ({
        url: '/media/sign-url/',
        method: 'POST',
        body: credentials,
      })
    }),
    unlockContent: builder.mutation<GeneralWithCreditsResponse, number>({
      query: (nodeId) => ({
        url: '/conversations/unlock-media/',
        method: 'POST',
        body: {nodeId},
      })
    }),
    skipMessageWait: builder.mutation<GeneralWithCreditsResponse, {nodeId: number, avatarId?: number}>({
      query: (data) => ({
        url: '/conversations/skip-wait/',
        method: 'POST',
        body: {
          ...data,
          "tzOffset": moment().utcOffset()
        },
      })
    }),
    unlockLockedNode: builder.mutation<GeneralWithCreditsResponse, {nodeId: number, avatarId?: number}>({
      query: (data) => ({
        url: '/conversations/unlock-node/',
        method: 'POST',
        body: {
          ...data,
          "tzOffset": moment().utcOffset()
        },
      })
    }),
    getMediaNodesByAvatar: builder.mutation<{messageHistory: Array<MessageNode>}, {id?: string}>({
      query: ({id}) => {
        const avatarId = id || 0;
        return ({
          url: `/conversations/gallery/${avatarId}/`,
          method: 'GET'
        });
      }
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetMsgHistoryByAvatarMutation,
  useSetMessagesReadMutation,
  useGetPresignedUrlMutation,
  useUnlockContentMutation,
  useGetMediaNodesByAvatarMutation,
  useSkipMessageWaitMutation,
  useUnlockLockedNodeMutation,
} = msgHistory;
