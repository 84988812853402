/* Chat header component with the active avatar info */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { CharacterState, Avatar } from '../../../interfaces';

import { useAppDispatch, useAppSelector } from "../../../reducers/hooks";
import { setActiveAvatar } from '../../../reducers/avatar';

import CharacterView from './CharacterView';

interface CharacterProps {
  screenViewType: string;
  setSlideInContactList: (value: boolean) => void;
  openContactList: boolean;
  slideInContactList: boolean;
  setOpenContactList: (value: boolean) => void;
  isMobileView: boolean;
}

const Character: React.FC<CharacterProps> = (props: CharacterProps) => {
  const {
    screenViewType,
    setSlideInContactList,
    openContactList,
    setOpenContactList,
    slideInContactList,
    isMobileView
  } = props;
  const dispatch = useAppDispatch();
  const { activeAvatar, friendAvatars } = useAppSelector((state) => ({
    activeAvatar: state.avatar.activeAvatar,
    friendAvatars: state.avatar.friendAvatars
  }));

  const [avatarState, setAvatarState] = useState<CharacterState>({
    avatars: [],
  });
  const [activeAvatarState, setActiveAvatarState] = useState<Avatar | null>(
    null
  );
  const history = useHistory();

  const handleContactListOpen = async () => {
    dispatch(setActiveAvatar(undefined));

    history.push('/chat');
    if (screenViewType === 'mobile') {
      setSlideInContactList(true);

      if(!openContactList) {
        setOpenContactList(true);
      }
    }
  };

  useEffect(() => {
    // On mount set avatars to component state and actual avatars to state and store
    if (friendAvatars && friendAvatars.length > 0) {
      setAvatars();

      // Set the activeAvatarState to null after unfriending an avatar
      if(!activeAvatar)
       setActiveAvatarState(null);
    }
  }, [friendAvatars, activeAvatar]);

  useEffect(() => {
    if (activeAvatar && avatarState && avatarState.avatars) {
      const foundAvatar = avatarState.avatars.find(
        avt => avt.id === activeAvatar.id
      );

      if (foundAvatar) {
        setActiveAvatarState(activeAvatar);
      } else {
        setActiveAvatarState(null);
      }
    }
  }, [activeAvatar, avatarState]);

  const setAvatars = async () => {
    // Request avatars from Backend api with RTK mutation
    const avatars = friendAvatars;

    // Setting user's avatar friends to state. Avatars can be an empty array.
    setAvatarState(prev => ({ ...prev, avatars }));

    // Setting the selected avatar to the store and state only if the user has at least one avatar friend
    if (avatars && avatars.length !== 0) {
      // Set selected avatar to state
      setAvatarState(prev => ({
        ...prev,
        activeAvatar
      }));
      // Set selected avatar to the store for other components to use
      dispatch(setActiveAvatar(activeAvatar));
    }
  };

  return (
    <CharacterView
      slideInContactList={slideInContactList}
      avatarState={avatarState}
      handleContactListOpen={handleContactListOpen}
      activeAvatarState={activeAvatarState}
      activeAvatar={activeAvatar as Avatar}
      history={history}
      isMobileView={isMobileView}
      isDesktopView={screenViewType === "web"}
    />
  );
};

export default Character;
