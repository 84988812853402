import React from 'react';
import { Link } from 'react-router-dom';

import { ErrorIF, ModifyUserData, User } from '../../../interfaces';

import { GoogleTranslator } from '../../../components';
import { CustomModal } from '../../../containers';
import UtilsService from '../../../services/utils';

import AccountFormInput from './AccountformInput';

interface ModalPorpsIF {
  icon: JSX.Element;
  title: string;
  description: string;
  button1Text: string;
  button2Text: string;
}

interface AccountformViewProps {
  open: boolean;
  setOpen: (val: boolean) => void;
  ModalProps: ModalPorpsIF;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmit: (e: any) => Promise<void>;
  handleChange: ({
    target: { name, value }
  }: React.ChangeEvent<HTMLInputElement>) => void;
  errors: ErrorIF;
  profileFormState: ModifyUserData;
  user: User;
  logout: () => void;
  formIsValid: () => boolean;
  profileImageName: string;
  handleRemoveButton: () => Promise<void>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fileSelectHandler: (e: any) => Promise<void>;
  loading: boolean;
  isMobileView: boolean;
}

const AccountformView: React.FC<AccountformViewProps> = (
  props: AccountformViewProps
) => {
  const {
    open,
    setOpen,
    ModalProps,
    onSubmit,
    handleChange,
    errors,
    profileFormState,
    user,
    logout,
    formIsValid,
    profileImageName,
    handleRemoveButton,
    fileSelectHandler,
    loading,
    isMobileView
  } = props;

  return (
    <div className={`${!isMobileView ? "flex-1 max-h-screen overflow-y-auto pb-16" : "flex flex-1 h-screen bg-gray-100 pt-16"}`}>
      <CustomModal open={open} setOpen={setOpen} modalProps={ModalProps} />
      <div className="max-w-3xl mx-auto py-10 px-4 sm:px-6 lg:py-12 lg:px-8 sm:pt-20 md:pt-10">
        <h1 className="text-3xl font-extrabold text-blue-gray-900">Account</h1>

        <form
          className="mt-6 space-y-8 divide-y divide-y-blue-gray-200"
          onSubmit={onSubmit}
        >
          <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-6 sm:gap-x-6">
            <div className="sm:col-span-6">
              <p className="mt-1 text-sm text-blue-gray-500">
                Your profile is not displayed publicly, information can only be
                seen by users you interact with.
              </p>
            </div>
            <AccountFormInput
              type="text"
              name="nickname"
              id="nickname"
              className="flex-1 block w-full min-w-0 border-blue-gray-300 rounded-none rounded-r-md text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500"
              isUserName
              handleChange={handleChange}
              errors={errors.nickname}
              value={profileFormState.nickname || ''}
              autoComplete="nickname"
            />

            <AccountFormInput
              type="text"
              name="firstName"
              id="first-name"
              label="First name"
              htmlFor="first-name"
              errors={errors.firstName}
              handleChange={handleChange}
              value={profileFormState.firstName || ''}
              autoComplete="given-name"
            />

            <AccountFormInput
              type="text"
              name="lastName"
              id="last-name"
              label="Last name"
              htmlFor="last-name"
              errors={errors.lastName}
              handleChange={handleChange}
              value={profileFormState.lastName || ''}
              autoComplete="family-name"
            />

            <AccountFormInput
              id="user-photo"
              name="profilePicture"
              type="file"
              loading={loading}
              value={profileFormState.profilePicture}
              isImage
              nickname={profileFormState.nickname || ''}
              firstName={profileFormState.firstName || ''}
              lastName={profileFormState.lastName || ''}
              errors={errors.profilePicture}
              imgName={profileImageName}
              handleRemoveButton={handleRemoveButton}
              handleChange={fileSelectHandler}
              className="hidden"
            />

            <div className='sm:col-span-3'>
              <p className="block text-sm font-medium text-blue-gray-900">Translate Fantxt</p>
              <div className="flex flex-row">
                <div className='flex-1'>
                  <GoogleTranslator />
                </div>
                <p className="flex-1 ml-2 mt-2 opacity-50 text-xs">
                  Automatic translations by Google Translate
                </p>
              </div>
            </div>
          </div>

          <div className="pt-8 grid grid-cols-1 gap-y-6 sm:grid-cols-6 sm:gap-x-6">
            <div className="sm:col-span-6">
              <h2 className="text-xl font-medium text-blue-gray-900">
                Login details
              </h2>
              <p className="mt-1 text-sm text-blue-gray-500">
                Change your email address or account password.
              </p>
            </div>

            <AccountFormInput
              type="text"
              name="email"
              id="email"
              htmlFor="email"
              label="Email address"
              divClassName="sm:col-span-6"
              handleChange={handleChange}
              errors={errors.email}
              autoComplete="email"
              value={profileFormState.email || ''}
            />

            <AccountFormInput
              type="password"
              name="currentPassword"
              id="current-password"
              autoComplete='current-password'
              value={profileFormState.currentPassword || ''}
              errors={errors.currentPassword}
              label="Current password"
              htmlFor="current-password"
              handleChange={handleChange}
            />

            <AccountFormInput
              type="password"
              name="newPassword"
              id="new-password"
              autoComplete='new-password'
              value={profileFormState.newPassword || ''}
              errors={errors.newPassword}
              label="New password"
              htmlFor="current-password"
              handleChange={handleChange}
            />

            <p className="text-sm text-blue-gray-500 sm:col-span-6">
              This account was created on{' '}
              {user && user.createdAt
                ? UtilsService.processDisplayTime(user.createdAt).format(
                    'YYYY MM DD, hh:mm:ss'
                  )
                : '...'}{' '}
              <Link
                onClick={() => setOpen(true)}
                to="#"
                className="text-red-500 hover:underline hover:text-red-400"
              >
                Delete my account.
              </Link>
            </p>
          </div>

          <div className={`pt-8 flex justify-between ${isMobileView ? "pb-20" : "p-14"}`}>
            <button
              type="button"
              onClick={() => logout()}
              className="bg-white py-2 px-4 border border-red-300 rounded-md shadow-sm text-sm font-medium text-red-500 hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              Log out
            </button>
            <button
              type="submit"
              disabled={!formIsValid()}
              className={`ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white ${!formIsValid() ? 'cursor-not-allowed bg-gray-400' : 'bg-blue-600'} hover:bg-blue-700'
                focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`}
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AccountformView;
