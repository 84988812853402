import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Avatar } from '../../interfaces';

import { WithMobileDetectionProps } from '../../components';
import { useAppDispatch } from "../../reducers/hooks";
import {
  setActiveAvatar,
  useGetAvatarTagsQuery,
  useGetAllQuery,
  useGetChatPromotionsQuery,
} from '../../reducers/avatar';

import DiscoverBrowseView from './DiscoverBrowseView';

interface DiscoverBrowseProps extends WithMobileDetectionProps{
  match: {params: {dafaultTagId: number}},
}

const DiscoverBrowse: React.FC<DiscoverBrowseProps> = (props: DiscoverBrowseProps) => {
  const { match } = props;
  const dispatch = useAppDispatch();

  const [selectedTags, setSelectedTags] = useState<Array<number> | undefined>(undefined);
  const [fromDiscover, setFromDiscover] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const [delayedSearch, setDelayedSearch] = useState<string>("");

  const history = useHistory();
  const location = useLocation();
  const scrollRef = useRef<HTMLUListElement>(null);
  const searchFieldTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  const { data: tags } = useGetAvatarTagsQuery();
  const {
    data: avatars,
    isFetching: loadingAvatars,
    isUninitialized: uninitializedAvatars
  } = useGetAllQuery({ search: delayedSearch, tags: selectedTags?.join("|") || ""}, {skip: !selectedTags});
  const {
    data: chatPromotions,
    isFetching: loadingPromotions,
    isUninitialized: uninitializedPromotions
  } = useGetChatPromotionsQuery({ search: delayedSearch, tags: selectedTags?.join("|") || ""}, {skip: !selectedTags});

  useEffect(() => {
    const {dafaultTagId} = match.params;
    if (tags && tags.length > 0) {
      if (dafaultTagId)
      setSelectedTags([Number(dafaultTagId)]);
      else
        setSelectedTags([]);
    }
  }, [tags]);

  useEffect(() => {
    if(location && location.state) {
      const {previousPageDiscover} =  location.state;
      
      if(previousPageDiscover) {
        setFromDiscover(true);
      }
    }

  }, [location]);

  const setSelectedTagsToState = (id: number) => {
    if (match.params.dafaultTagId) {
      history.push('/discover/browse');
    }
    if (selectedTags!.includes(id)) {
      setSelectedTags(selectedTags!.filter(tag => tag !== id));
    } else {
      setSelectedTags([...selectedTags!, id]);
    }
  };

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    if (searchFieldTimeout.current) clearTimeout(searchFieldTimeout.current);
    searchFieldTimeout.current = setTimeout(
      () => setDelayedSearch(event.target.value), 200
    );
  };

  const isTagActiveClassName = (id: number) => selectedTags?.includes(id) ? 'active cursor-pointer' : 'cursor-pointer';

  const sideScroll = (
    element: HTMLUListElement | null,
    speed: number,
    distance: number,
    step: number
  ) => {
    let scrollAmount = 0;
    if(element) {
      const slideTimer = setInterval(() => {
        element.scrollLeft += step;
        scrollAmount += Math.abs(step);
        if (scrollAmount >= distance) {
          clearInterval(slideTimer);
        }
      }, speed);
    }
  };

  const dispatchSetActiveAvatar = async (avatar: Avatar | undefined) => {
    dispatch(setActiveAvatar(avatar));
  };

  return (
    < DiscoverBrowseView
        fromDiscover={fromDiscover}
        history={history}
        scrollRef={scrollRef}
        sideScroll={sideScroll}
        tags={tags || []}
        isTagActiveClassName={isTagActiveClassName}
        setSelectedTagsToState={setSelectedTagsToState}
        selectedAvatars={avatars || []}
        chatPromotions={chatPromotions || []}
        setActiveAvatar={dispatchSetActiveAvatar}
        search={search}
        onSearchChange={onSearchChange}
        tagsSelected={!!selectedTags?.length}
        loadingSearch={loadingAvatars || loadingPromotions || uninitializedAvatars || uninitializedPromotions}
    />
  );
};

export default DiscoverBrowse;
