import React from 'react';
import { Route, Switch, BrowserRouter, Redirect } from 'react-router-dom';
import Login from './views/Login';
import Register from './views/Register';
import ForgotPassword from './views/ForgotPassword';
import ResetPassword from './views/ResetPassword';
import Main from './views/Main';
import Base from './views/Base';
import Discover from './views/Discover';
import DiscoverBrowse from './views/DiscoverBrowse';
import Gallery from './views/Gallery';
import Profile from './views/Profile';
import AddFriend from './views/AddFriend';
import PaymentRedirect from './views/PaymentRedirect';
import AvatarProfile from './views/AvatarProfile';
import NotFound from './views/NotFound';
import ServerError from './views/ServerError';

export default (
  <BrowserRouter>
      <Main>
        <Switch>
          {process.env.REACT_APP_ENVIRONMENT === "development" ? <Route path="/login" component={Login} /> : null}
          {process.env.REACT_APP_ENVIRONMENT === "development" ? <Route path="/signup" component={Register} /> : null}
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route exact path="/password-reset/confirm/:uid/:token/" component={ResetPassword} />
          <Route exact path="/chat/:avatarHandle?" component={Base} />
          <Route path="/discover" exact component={Discover} />
          <Route exact path="/discover/browse/:dafaultTagId?" component={DiscoverBrowse} />
          <Route exact path="/discover/browse" component={DiscoverBrowse} />
          <Route exact path="/saved/:id?/:mediaId?" component={Gallery} />
          <Route exact path="/profile/:paramPage?" component={Profile} />
          <Route exact path="/add-friend" component={AddFriend} />
          <Route exact path="/payment-redirect/:code/:page/:id" component={PaymentRedirect} />
          <Route exact path="/404" component={NotFound} />
          <Route exact path="/500" component={ServerError} />
          <Route exact path="/:handle?/" component={AvatarProfile} />
          <Redirect to="/404" />
        </Switch>
      </Main>
  </BrowserRouter>
);
