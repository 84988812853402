import {
  Avatar,
  ChatSubscription,
  UpdateBlockedStatusRequest,
  UnfriendAvatarResponse,
  UserResponse,
  ChatPromotion,
  Tag
} from '../../interfaces';

import baseApi from '../api';

export const avatar = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllAvatarByUser: builder.mutation<Array<Avatar>, null>({
      query: () => ({
        url: '/avatars/friends/',
        method: 'GET'
      }),
    }),
    getAll: builder.query<Array<Avatar>, {search: string, tags: string}>({
      query: ({search, tags}) => ({
          url: `/avatars/browse/${search && `?search=${search}`}${tags && `${search ? '&' : '?'}tags=${tags}`}`,
        }),
    }),
    getChatPromotions: builder.query<Array<ChatPromotion>, {search: string, tags: string}>({
      query: ({search, tags}) => ({
          url: `/avatars/browse/chats/${search && `?search=${search}`}${tags && `${search ? '&' : '?'}tags=${tags}`}`,
        }),
    }),
    getBrowseRecommended: builder.query<Array<ChatPromotion>, boolean>({
      query: (initial) => ({
          url: `/avatars/browse/recommend/${initial ? '?initial=true' : ''}`,
        }),
    }),
    getAllDiscover: builder.mutation<Array<Avatar>, null>({
      query: () => ({
          url: '/avatars/',
          method: 'GET'
        }),
    }),
    getAvatarByHandle: builder.query<Avatar, {avatarHandle: string, page: number}>({
      query: ({avatarHandle}) => ({
        url: `/avatars/${avatarHandle}/`,
      }),
    }),
    addFriend: builder.mutation<{status: string, message: string}, string>({
      query: (handle: string) => ({
        url: "/avatars/befriend/",
        method: 'POST',
        body: {handle}
      }),
    }),
    unfriendAvatar: builder.mutation<UnfriendAvatarResponse, number>({
      query: (id) => ({
        url: `/avatars/unfriend/${id}/`,
        method: 'DELETE',
      }),
    }),
    getAvatarTags: builder.query<Array<Tag>, void>({
      query: () => ({
        url: '/tags/',
      }),
    }),
    addFriendTemp: builder.mutation<UserResponse, {handle: string, referrer: string, campaign: string | null}>({
      query: ({handle, referrer, campaign}) => ({
        url: `/avatars/tempfriend/${handle}/?referrer=${encodeURIComponent(referrer)}${campaign ? `&campaign=${campaign}` : ''}`,
        method: 'POST'
      }),
    }),
    updateBlockedStatus: builder.mutation<{status: string, message: string, result?: Array<Avatar>}, UpdateBlockedStatusRequest>({
      query: (setting) => ({
        url: '/avatar/update-blocked-status',
        method: 'POST',
        body:  setting,
      }),
    }),
    subscribeToNewChats: builder.mutation<{status: string, message: string, result?: string}, number>({
        query: (avatarId) => ({
          url: "/avatars/subscribe/",
          method: 'POST',
          body: {avatarId}
      }),
    }),
    unSubscribeFromNewChats: builder.mutation<{status: string, message: string, result?: string}, number>({
        query: (avatarId) => ({
          url: `/avatars/subscription/${avatarId}/`,
          method: 'DELETE',
      }),
    }),
    getChatSubscription: builder.mutation<ChatSubscription, number>({
        query: (avatarId) => ({
          url: `/avatars/subscription/${avatarId}/`,
          method: 'GET',
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAvatarByHandleQuery,
  useGetAllQuery,
  useGetChatPromotionsQuery,
  useLazyGetBrowseRecommendedQuery,
  useGetAllDiscoverMutation,
  useGetAllAvatarByUserMutation,
  useAddFriendMutation,
  useAddFriendTempMutation,
  useUnfriendAvatarMutation,
  useGetAvatarTagsQuery,
  useUpdateBlockedStatusMutation,
  useSubscribeToNewChatsMutation,
  useUnSubscribeFromNewChatsMutation,
  useGetChatSubscriptionMutation
} = avatar;
