/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';

import {
  Avatar,
  PaymentProgress,
  PaymentType,
  PollingResult
} from '../../interfaces';

import MembershipBlock from '../MembershipBlock';
import PaymentModalHeader from './PaymentModalHeader';

interface PaymentModalViewProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  paymentProgress?: PaymentProgress;
  avatar?: Avatar;
  paymentType?: PaymentType;
  pollingResult: PollingResult | null;
  setPaymentType: (newPaymentType: PaymentType) => void;
  renderProducts: () => JSX.Element[] | null;
  refFunc: React.MutableRefObject<() => void>;
  renderPollingResultModal: () => JSX.Element;
  startPayment: () => Promise<void>;
  successCallback?: () => void;
  isMobileView: boolean;
}

const PaymentModalView: React.FC<PaymentModalViewProps> = (
  props: PaymentModalViewProps
) => {
  const {
    open,
    setOpen,
    paymentProgress,
    avatar,
    paymentType,
    pollingResult,
    setPaymentType,
    renderProducts,
    refFunc,
    renderPollingResultModal,
    startPayment,
    successCallback,
    isMobileView
  } = props;
  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        id="paymentModal"
        as="div"
        auto-reopen="true"
        initialFocus={cancelButtonRef}
        className={`fixed ${isMobileView ? "z-30" : "z-10 overflow-y-auto"} inset-0`}
        onClose={() => setOpen(false)}
      >
        <div ref={cancelButtonRef} className={`flex ${isMobileView && "z-30"} items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0`}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            {!pollingResult ? (
              <div
                className={`fixed z-40 inset-0 ${!isMobileView && "overflow-y-auto"}`}
                aria-labelledby="modal-title"
                role="dialog"
                aria-modal="true"
                id={paymentType === PaymentType.SUBSCRIPTION ? "creditSubscriptionModal" : "buyCreditsModal"}
              >
                <div className="flex items-end justify-center self-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                  <div
                    className="fixed inset-0 bg-gray-800 bg-opacity-50 transition-opacity"
                    aria-hidden="true"
                  />
                  <span
                    className="hidden sm:inline-block align-middle h-screen"
                    aria-hidden="true"
                  >
                    &#8203;
                  </span>
                  <div className="inline-block align-bottom self-center bg-white rounded-lg p-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-full sm:max-w-xl lg:max-w-5xl sm:p-6">
                    {paymentProgress !== PaymentProgress.READY ? (
                      <div className="absolute inset-0 bg-white bg-opacity-80 h-full w-full z-50 items-center">
                        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 inline-flex items-center text-xl font-medium gap-2 text-blue-600">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="animate-spin h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                          </svg>
                          Loading…
                        </div>
                      </div>
                    ) : null}
                    <PaymentModalHeader
                      paymentType={paymentType || PaymentType.SUBSCRIPTION}
                      setOpen={setOpen}
                      setPaymentType={setPaymentType}
                    />
                    <div className="mt-8">
                      <fieldset>
                        <div className="space-y-2">{renderProducts()}</div>
                      </fieldset>
                      {/* MEMBERSHIP AVAILABLE BLOCK STARTS // Only show if Membership is available */}
                      {avatar && (
                        <MembershipBlock
                          avatar={avatar}
                          refFunc={refFunc}
                          successCallback={successCallback}
                        />
                      )}
                      {/* MEMBERSHIP AVAILABLE BLOCK END */}
                      <div className="mt-6 text-center">
                        <button
                          id="selectAndPayNowButton"
                          type="button"
                          onClick={() => {
                            startPayment();
                          }}
                          className="group relative w-full sm:max-w-lg inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 text-lg font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        >
                          <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                            {/* Heroicon name: solid/lock-closed */}
                            <svg
                              className="h-5 w-5 text-blue-400 group-hover:text-blue-400"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                fillRule="evenodd"
                                d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </span>
                          Pay now
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              renderPollingResultModal()
            )}
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default PaymentModalView;
