export interface MessageNode {
  text: string,
  chatId: number,
  sender: SenderType,
  displayTime: string,
  typingIndicatorTime: string,
  mediaId?: number,
  mediaFile?: string,
  isLocked?: boolean,
  creditCost?: number,
  nodeId?: number,
  msgChoiceId?: number,
  nextNodeType: NextNodeType,
  icebreaker?: boolean,
  icebreakerId?: number,
  isRead?: boolean,
  avatarId?: number,
  skipPrice?: number
  isLockedNode?: boolean;
}

export enum NextNodeType {
  AVATAR_NODE = 'AVATAR_NODE',
  USER_NODE = 'USER_NODE',
  FINISHED = 'FINISHED'
}
  
export enum SenderType {
  AVATAR = 'AVATAR',
  USER = 'USER',
  SYSTEM = 'SYSTEM'
}

export enum MsgChoiceType {
  MULTIPLE_CHOICE = 'MULTIPLE_CHOICE',
  PICK_NUMBER = 'PICK_NUMBER',
  ADD_FRIEND = 'ADD_FRIEND',
  INVITE_USER = 'INVITE_USER',
  ROLL_DICE = 'ROLL_DICE',
  PICK_COLOR = 'PICK_COLOR'
}

export interface MsgChoice {
  msgChoiceId: number,
  chatId: number,
  msgChoiceType: MsgChoiceType,
  displayTime: string,
  text: string,
  icebreaker?: boolean,
}

export interface AvatarMessages {
  messageHistory: Array<MessageNode>,
  messageFuture?: Array<MessageNode>,
  msgChoices: Array<MsgChoice>,
  isTyping?: boolean,
  isMsgChoicesShown?: boolean
}

export interface NewAvatarMessages {
  avatarId: number;
  messageHistory: Array<MessageNode>,
  msgChoices?: Array<MsgChoice>,
  updatedCredits?: number
}

export interface MessageHistoryResponse {
  [key: string]: AvatarMessages;
}
