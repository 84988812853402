/* Avatar feed post component for the avatar profile page */
import React, { useState, useEffect } from 'react';
import sha1 from "sha1";

import { useAppSelector } from "../../../reducers/hooks";

import MoreDeals from './MoreDeals';

const DiscoverBanner: React.FC= () => {
  const user = useAppSelector(state => state.user.user);

  const [isMobileView, setMobileView] = useState(window.innerWidth < 1024);

  const handleScreenResize = (): void => {
    if (window.innerWidth < 1024) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleScreenResize);
    return () => window.removeEventListener('resize', handleScreenResize);
  }, []);

  const userIdSha1 = sha1(user?.id?.toString() || "1").slice(0, 8);
  const url = process.env.REACT_APP_BANNER_TARGET_URL?.replace("PLAYERID", userIdSha1);

  return (
    <>
      <div className="my-4 max-h-52 md:max-h-80 overflow-hidden">
        <h5 className="my-2 border-gray-300 uppercase text-xs font-medium text-gray-500 border-t">Sponsored offer</h5>
        <div className="mb-4 discover-frame max-h-52 md:max-h-72 overflow-hidden">
          <a
            href={url}
            target="_blank"
            rel="noreferrer"
          >
            <video loop muted autoPlay className="w-full h-full object-cover" playsInline>
              <source src={isMobileView ? process.env.REACT_APP_BANNER_SMALL_URL : process.env.REACT_APP_BANNER_LARGE_URL} type="video/mp4" />
            </video>
          </a>
        </div>
      </div>
      <MoreDeals />
    </>
  );
};

export default DiscoverBanner;
