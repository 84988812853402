/* Input field component used in modals */
import React, { ChangeEventHandler } from 'react';

interface ModalFormInputProps {
  inputRef?: React.RefObject<HTMLInputElement>;
  label: string;
  name: string;
  required: boolean;
  placeHolder: string;
  autoComplete: string;
  value?: string;
  type?: string;
  handleChange: ChangeEventHandler<HTMLInputElement>;
  error?: string;
}

const ModalFormInput: React.FC<ModalFormInputProps> = (
  props: ModalFormInputProps
) => {
  const { inputRef, label, name, required, placeHolder, autoComplete, value, type, handleChange, error } = props;

  return (
    <div>
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <div className="mt-1">
        <input
          id={name}
          name={name}
          type={type || name}
          autoComplete={autoComplete}
          placeholder={placeHolder}
          required={required}
          value={value}
          ref={inputRef}
          onChange={handleChange}
          className={`appearance-none block w-full px-3 py-2 border ${error ? "border-red-600" : "border-blue-500"} rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500`}
        />
      </div>
      {error && <div className="text-sm text-red-600">{error}</div> }
    </div>
  );
};

export default ModalFormInput;
