/* Billing settings component to display the purchase history of the user */
import React, { useState } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';

import { Payment, HidePaymentResponse } from '../../../interfaces';

import { ConfirmationModalProps } from '../../../components';
import { useGetPurchaseHistoryQuery, useHidePaymentMutation } from '../../../reducers/payment';

import PurchaseHistoryView from './PurchaseHistoryView';

interface PurchaseHistoryProps {
  isMobileView: boolean;
}

const PurchaseHistory: React.FC<PurchaseHistoryProps> = (props: PurchaseHistoryProps) => {
  const {isMobileView} = props;

  const initHidePaymentModalProps = {
    close: () => {setHidePaymentModalOpen(false);},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    handleModalAction: () => {},
    handleModalActionParameter: '',
    modalText: null,
    leftBtnText: '',
    rightBtnText: '',
    modalTitle: '',
  };

  const { data: purchaseHistory } = useGetPurchaseHistoryQuery();
  const [hidePayment] = useHidePaymentMutation();
  
  const [hidePaymentModalOpen, setHidePaymentModalOpen] = useState<boolean>(false);
  const [hidePaymentModalProps, setHidePaymentModalProps] = useState<ConfirmationModalProps>(initHidePaymentModalProps);

  const hidePaymentAsync = async (payment: Payment) => {
    if (payment) {
      const response: HidePaymentResponse = await hidePayment(payment.id).unwrap();
      if(setHidePaymentModalOpen) setHidePaymentModalOpen(false);
      if (response.hidden) {
        toast.success('Purchase hidden!', {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
      }
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleHide = async(e: any, payment: Payment) => {
    e.stopPropagation();
    const newProps = hidePaymentModalProps;
    if(newProps) {
      newProps.handleModalAction = hidePaymentAsync;
      newProps.handleModalActionParameter = payment;
      newProps.rightBtnText = "Hide";
      newProps.leftBtnText = "Close";
      newProps.modalText = <p className="text-sm text-gray-500">Are you sure you want to hide this purchase?</p>;
      newProps.modalTitle = "Are you sure?";
      if(setHidePaymentModalProps)
        setHidePaymentModalProps(newProps);
      if(setHidePaymentModalOpen)
        setHidePaymentModalOpen(true);
    }
  };

  const renderPaymentHistory = () => {
    if (!purchaseHistory)
      return null;

    return purchaseHistory.map(payment => (
      <tr key={payment.id}>
        <td className="px-2 py-4 whitespace-nowrap text-sm text-gray-900">
          <time>{payment.updatedAt ? moment(payment.updatedAt).format('D MMM YYYY, HH:mm') : moment(payment.createdAt).format('D MMM YYYY, HH:mm')}</time>
        </td>
        <td className="px-2 py-4 whitespace-nowrap text-sm text-gray-500">{payment.product.creditAmount} credits</td>
        <td className="px-2 py-4 whitespace-nowrap text-sm text-gray-500">{`$${payment.price.toString().slice(0, -2)}.${payment.price.toString().slice(-2)}`}</td>
        <td className="px-2 py-4 whitespace-nowrap text-sm text-gray-500">{payment.status}</td>
        <td className="px-2 py-4 whitespace-nowrap text-sm text-right font-medium">
          <button onClick={(e) => handleHide(e, payment)} type="button" className="rounded font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">Hide</button>
        </td>
      </tr>
    ));
  };

  return (
    <PurchaseHistoryView
      isMobileView={isMobileView}
      renderPaymentHistory={renderPaymentHistory}
      hidePaymentModalOpen={hidePaymentModalOpen}
      hidePaymentModalProps={hidePaymentModalProps}
    />
  );
};

export default PurchaseHistory;
