/* Push chat functionality (no view) */
import React, { useEffect, useCallback, useRef } from 'react';

import { useAppSelector } from "../../../reducers/hooks";
import WSApi from '../../../services/ws-api-handler/ws-api';

const PUSH_CHAT_HAS_FRIENDS_INTERVAL = 30000; // 30 seconds
const PUSH_CHAT_NO_FRIENDS_INTERVAL = 10000; // 10 seconds

const PushChat: React.FC = () => {
  const { accessToken, friendCount, msgCount } = useAppSelector((state) => ({
    accessToken: state.user.accessToken,
    friendCount: state.avatar.friendAvatars?.length || 0,
    msgCount: state.base.msgCount,
  }));
  const { ws } = WSApi;

  const pushChatHasFriendsTimer = useRef<ReturnType<typeof setTimeout> | null>(null);
  const pushChatNoFriendsTimer = useRef<ReturnType<typeof setTimeout> | null>(null);

  const pushChat = useCallback((alarmHasFriends: boolean) => {
    const hasFriends = !!friendCount;
    if (hasFriends === alarmHasFriends && accessToken) {
      ws.pushChat(accessToken);
    }
  }, [friendCount, accessToken]);
  
  useEffect(() => {
    if (pushChatHasFriendsTimer.current) clearTimeout(pushChatHasFriendsTimer.current);
    if (pushChatNoFriendsTimer.current) clearTimeout(pushChatNoFriendsTimer.current);
    pushChatHasFriendsTimer.current = setTimeout(() => pushChat(true), PUSH_CHAT_HAS_FRIENDS_INTERVAL);
    pushChatNoFriendsTimer.current = setTimeout(() => pushChat(false), PUSH_CHAT_NO_FRIENDS_INTERVAL);
  }, [msgCount, friendCount, accessToken]);

  return null;
};

export default PushChat;
