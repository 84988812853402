import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum ConnectionStatus {
  CONNECTED = 'CONNECTED',
  RETRY = 'RETRY',
  DISCONNECTED = 'DISCONNECTED',
  CONNECTING = 'CONNECTING'
}

export interface WebsocketState {
  connectionStatus: ConnectionStatus;
  error?: string;
}

const initialState: WebsocketState = {
  connectionStatus: ConnectionStatus.DISCONNECTED
};

const websocketInfo = createSlice({
  name: 'websocket',
  initialState,
  reducers: {
    setConnectionStatus: (state, action: PayloadAction<ConnectionStatus>) => {
      state.connectionStatus = action.payload;
    },
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    }
  },
});

export const {
  setConnectionStatus,
  setError,
} = websocketInfo.actions;

export default websocketInfo.reducer;
