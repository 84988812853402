/* Modal component for content unlock functionality */
import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';

import { Avatar } from '../../../interfaces';

import { useAppSelector } from "../../../reducers/hooks";
import { MembershipBlock } from '../../../containers';

interface MembershipModalProps {
  contentModalOpen: boolean;
  setContentModalOpen: (val:boolean) => void;
  setPaymentModalOpen: (val:boolean) => void;
  avatar: Avatar;
  contentCreditCost?: number | undefined;
  successCallback?: () => void;
}

const MembershipModal: React.FC<MembershipModalProps> = (props: MembershipModalProps) => {
  const {contentModalOpen, setContentModalOpen, setPaymentModalOpen, avatar, contentCreditCost, successCallback} = props;
  const user = useAppSelector((state) => state.user.user);

  // For testing the unlock function, change the user.credits to number greater than the contentCreditCost (20 is fine)
  const userCreditbalance = user?.credits;

  React.useEffect(() => {
    if(contentModalOpen){
      // If the user's does not have enough credits to unlock the content,
      // Render the Buy credits modal
      if(contentCreditCost && (userCreditbalance || userCreditbalance === 0)) {
        if(userCreditbalance < contentCreditCost) {
          setPaymentModalOpen(true);
          setContentModalOpen(false);
          }
        }
      }
  },[contentModalOpen]);

  return (
    <Transition.Root show={contentModalOpen && ((contentCreditCost !== undefined && userCreditbalance) ? userCreditbalance >= contentCreditCost : false)} as={Fragment}>
      <Dialog
        as="div"
        auto-reopen="true"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={() => setContentModalOpen(!contentModalOpen)}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-800 bg-opacity-50 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block align-middle h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className="inline-block align-bottom bg-white rounded-lg p-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-full sm:max-w-xl sm:p-6"
            >
              <MembershipBlock
                avatar={avatar}
                setOpen={setContentModalOpen}
                subscribable
                onlyMembership
                successCallback={successCallback}
              />
              <div className="absolute top-4 right-4">
                <button type="button" className="p-2 rounded-full bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500">
                  <span className="sr-only">Cancel</span>
                  <XIcon
                    className="h-4 w-4 text-gray-500"
                    aria-hidden="true"
                    onClick={()=>setContentModalOpen(false)}
                  />
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default MembershipModal;
