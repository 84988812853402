import React from 'react';
import { useHistory } from 'react-router-dom';

import { PaymentProgress } from '../../interfaces';

import { useAppDispatch } from "../../reducers/hooks";
import { setPaymentModalOpen, setActivePaymentProductCode, setPaymentProgress } from '../../reducers/payment';

interface PaymentRedirectProps {
  match: {params: {code: string, page: string,  id: string}}
}

const PaymentRedirect: React.FC<PaymentRedirectProps> = (props: PaymentRedirectProps) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { match } = props;

  React.useEffect(() => {
    const {code, id, page} = match.params;
    dispatch(setPaymentModalOpen(true));
    dispatch(setActivePaymentProductCode(code));
    dispatch(setPaymentProgress(PaymentProgress.WAIT_FOR_RESULT));

    switch (page) {
      case 'profile':
        history.push('/profile');
        break;
      case 'gallery':
        history.push(`/saved/${id ? `${id}` : 'all'}`);
        break;
      case 'avatar':
        history.push(`/${id}`);
        break;
      case 'chat':
        history.push(`/chat/${id}`);
        break;
      default:
        history.push('/profile');
        break;
    }

  }, []);

  return null;
};

export default PaymentRedirect;