/* Category item component for the tag category list */
import React from 'react';
import { useHistory } from 'react-router-dom';

import { Tag } from '../../../interfaces';

interface CategoryItemProps {
  tag: Tag;
}

const CategoryItem: React.FC<CategoryItemProps> = (
  props: CategoryItemProps
) => {
  const { tag } = props;
  const history = useHistory();

  return (
    <button type="button" onClick={() => tag && history.push(`/discover/browse/${tag.id}`, {
      previousPageDiscover: true
    })} className="browse-item">
      <div className="browse-avatar">
        <img alt={tag.tagText} src={tag.tagPicture ? tag.tagPicture : ""} />
      </div>
      <div className="browse-link">
        <div>{tag && tag.tagText}</div>
      </div>
    </button>
  );
};

export default CategoryItem;
