import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { PaymentType } from '../../interfaces';

import { WithMobileDetectionProps } from '../../components';
import { LoginRegisterForgotPasswordModal, PaymentModal } from '../../containers';
import { useAppDispatch, useAppSelector } from "../../reducers/hooks";
import { setPaymentModalOpen } from '../../reducers/payment';
import { setActiveAvatar, useGetAvatarByHandleQuery } from '../../reducers/avatar';

import MembershipModal from './_MembershipModal';
import AvatarProfileView from './AvatarProfileView';

const NO_MORE_POST = -1;

interface UseParamsProps {
  handle: string;
}

const AvatarProfile: React.FC<WithMobileDetectionProps> = (props: WithMobileDetectionProps) => {
  const { isMobileView, isIOS} = props;
  const { handle } = useParams<UseParamsProps>();
  const { data: avatarState, error } = useGetAvatarByHandleQuery({ avatarHandle: handle, page: 1 }, { skip: !handle || handle === 'landing' });
  const history = useHistory();
  const location = useLocation();
  const [mediaUrl, setMediaUrl] = useState('');
  const [openMediaViewer, setOpenMediaViewer] = useState(false);
  const [contentModalOpen, setContentModalOpen] = useState<boolean>(false);
  const [modalType, setModalType] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const dispatch = useAppDispatch();
  const { isPaymentModalOpen, paymentType } = useAppSelector((state) => ({
    isPaymentModalOpen: state.payment.isPaymentModalOpen,
    paymentType: state.payment.paymentType,
  }));

  const scrollRef = useRef<HTMLUListElement>(null);

  const onScroll = () => {
    if (scrollRef.current && currentPage !== NO_MORE_POST) {
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        setCurrentPage(currentPage+1);
      }
    }
  };

  useEffect(() => {
    validateURL();
  }, [handle]);

  useEffect(() => {
    if (error) {
      history.push('/404');
    }
  }, [error]);

  const validateURL = () => {
    // Landing page sends token in the form of /landing?token=...
    if (handle !== 'landing') {
      const queryParams = history.location.search;
      if (!handle && queryParams === '') {
          history.push('/chat');
      }
    }
  };

  const handleMessageClick = () => {
    if(avatarState && avatarState.avatarName) {
      dispatch(setActiveAvatar(avatarState));
      history.push(`/add-friend?q=${handle}`, {
        openChat: true
      });
    }
  };

  const copyProfileLink = () => {
    
    try {
      const url = window.location.href;
      if(navigator.clipboard) {
        navigator.clipboard.writeText(url);

      toast.info('Copied url to the clipboard!', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
      }
    } catch(err) {
      toast.error('Failed to copy url to clipboard!', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
    }
  
  };

  const handleMediaClick = (url: string) => {
    setMediaUrl(url);
    setOpenMediaViewer(true);
  };

  const setPaymentModalOpenState = (isOpen: boolean) => {
    dispatch(setPaymentModalOpen(isOpen));
  };

  const renderBreadCrumbText = () => {
    if(location && location.state) {
      if(location.state.from === 'discover')
        return "Discover";
      
      if(location.state.from === 'settings')
        return "Settings";
    }
    return "Chat";
  };
  
  useEffect(() => {
    if(avatarState && avatarState.avatarName)
    document.title = `${avatarState.avatarName} on Fantxt`;
  }, [avatarState?.avatarName]);

  const renderModals = () => (
    <>
      <LoginRegisterForgotPasswordModal
        modalType={modalType}
        setModalType={setModalType}
        open={open}
        setOpen={setOpen}
      />
      <MembershipModal
        contentModalOpen={contentModalOpen}
        setContentModalOpen={setContentModalOpen}
        setPaymentModalOpen={setPaymentModalOpenState}
        contentCreditCost={(avatarState?.membership && 'active' in avatarState!.membership) ? avatarState?.membership?.price : avatarState?.membership?.firstTimePrice}
        avatar={avatarState!}
      />
      <PaymentModal
        open={isPaymentModalOpen || false}
        setOpen={setPaymentModalOpenState}
        avatar={avatarState!}
        paymentType={paymentType || PaymentType.SUBSCRIPTION}
        redirectPage={`avatar/${avatarState?.handle}`}
        isMobileView={isMobileView}
      />
    </>
  );

  return (
    <AvatarProfileView
      openMediaViewer={openMediaViewer}
      mediaUrl={mediaUrl}
      avatarState={avatarState || null}
      setOpenMediaViewer={setOpenMediaViewer}
      handleMediaClick={handleMediaClick}
      copyProfileLink={copyProfileLink}
      handleMessageClick={handleMessageClick}
      history={history}
      renderModals={renderModals}
      setContentModalOpen={setContentModalOpen}
      renderBreadCrumbText={renderBreadCrumbText}
      scrollRef={scrollRef}
      onScroll={onScroll}
      isMobileView={isMobileView}
      isIOS={isIOS}
    />
  );
};

export default AvatarProfile;
