import type { BillingProduct } from './user';

export enum PaymentProgress {
    READY = 'READY',
    SUB_CREATION = 'SUB_CREATION',
    WAIT_FOR_RESULT = 'WAIT_FOR_RESULT'
}

export enum PaymentType {
    SUBSCRIPTION = 'SUBSCRIPTION',
    CREDIT = 'CREDIT'
}

export interface PaymentMethods {
  code: string,
  name: string
}

export enum PayWallOrSkipMessageType {
  PAY_WALL = 'PAY_WALL',
  SKIP_THE_WAIT = 'SKIP_THE_WAIT'
}

export interface PayWallOrSkipMessage {
  type?: PayWallOrSkipMessageType,
  avatarId?: number,
  unlock: boolean
}

export interface PaymentProduct {
  id: number,
  code: string,
  creditAmount: number,
  isRecurring: boolean,
  price: number,
  paymentMethods: Array<PaymentMethods>,
  marketingLabel: string,
  marketingLabelClasses: string
  creditAmountLabel?: string;
}

export interface InitiatePaymentResponse {
  id: number,
  url: string
}

export interface InitiatePaymentRequest {
  id: number,
  code: string,
  paymentMethod: string,
  returnUrl: string,
  nodeId?: number
}

export interface CheckPaymentStatusResponse {
  status: string;
  creditAmount: number;
}

export interface GeneralWithCreditsResponse {
  message: string;
  status: string;
  credits: number;
}

export interface Payment {
  id: number,
  userId: number,
  status: string,
  product: BillingProduct,
  price: number,
  createdAt: string,
  updatedAt?: string
}

export enum PollingResult {
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  PENDING = 'PENDING'
}

export interface CancelMembershipResponse {
  message: string;
  status: string;
}

export interface HidePaymentRequest {
  id: number;
}

export interface HidePaymentResponse {
  hidden: boolean;
}
