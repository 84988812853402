/* Modal component for payment functionality */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useRef, useState } from 'react';

import { Avatar, InitiatePaymentRequest, PaymentProduct, PaymentProgress, PollingResult } from '../../interfaces';

import { PaymentElement } from '../../components';
import { useAppDispatch, useAppSelector } from "../../reducers/hooks";
import {
  setPaymentIdInProgress,
  setAvatarInProgress,
  setMembershipChecked,
  setPaymentType,
  setActivePaymentProductCode,
  setPaymentProgress,
  setPaymentModalOpen,
  useCheckPaymentStatusMutation,
  useGetProductsQuery,
  useInitiatePaymentMutation,
} from '../../reducers/payment';
import { updateCredits } from '../../reducers/user';
import { setActiveAvatar } from '../../reducers/avatar';

import PaymentModalView from './PaymentModalView';
import PaymentResponseModal from './PaymentResponseModal';

interface PaymentModalProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  avatar?: Avatar;
  redirectPage?: string;
  paymentType?: PaymentType;
  successCallback?: () => void;
  isMobileView: boolean;
  contentNodeId?: number;
}

export enum PaymentType {
  SUBSCRIPTION = 'SUBSCRIPTION',
  CREDIT= 'CREDIT'
}

const PaymentModal: React.FC<PaymentModalProps> = (props: PaymentModalProps) => {
  const {
    open,
    setOpen,
    avatar,
    redirectPage,
    paymentType,
    successCallback,
    isMobileView,
    contentNodeId
  } = props;

  const refFunc = useRef<() => void>(() => console.log('ready'));

  const dispatch = useAppDispatch();
  const {
    paymentProgress,
    activePaymentProductCode,
    paymentIdInProgress,
    avatarInProgress,
    activeAvatar,
    // currentReferenceId,
    membershipChecked,
  } = useAppSelector((state) => ({
    paymentProgress: state.payment.paymentProgress,
    activePaymentProductCode: state.payment.activePaymentProductCode,
    paymentIdInProgress: state.payment.paymentIdInProgress,
    avatarInProgress: state.payment.avatarInProgress,
    activeAvatar: state.avatar.activeAvatar,
    // currentReferenceId: state.base.currentReferenceId,
    membershipChecked: state.payment.membershipChecked
  }));

  const  { data: products, isLoading: loadingProducts } = useGetProductsQuery();
  const [initiatePayment, {error}] = useInitiatePaymentMutation();
  const [checkPaymentStatus] = useCheckPaymentStatusMutation();

  const [recurringProducts, setRecurringProducts] = useState<Array<PaymentProduct> | null>(null);
  const [notRecurringProducts, setNotRecurringProducts] = useState<Array<PaymentProduct> | null>(null);
  const [activeProduct, setActiveProduct] = useState<PaymentProduct | null>(null);
  const [pollingResult, setPollingResult] = useState<PollingResult | null>(null);

  const setPaymentTypeState = (newPaymentType: PaymentType) => {
    dispatch(setPaymentType(newPaymentType));
  };

  useEffect(() => {
    if (products && !loadingProducts) {
      setRecurringProducts(products ? products.filter(p => p.isRecurring) : null);
      setNotRecurringProducts(products ? products.filter(p => !p.isRecurring) : null);
    }
  }, [products]);

  useEffect(() => {
    if (open === true) {
      if (!paymentIdInProgress) {
        dispatch(setPaymentProgress(PaymentProgress.READY));
      }
    } else {
      dispatch(setAvatarInProgress(undefined));
    }
  }, [open]);

  useEffect(() => {
    if (error) {
      dispatch(setPaymentProgress(PaymentProgress.READY));
    }
  }, [error]);

  useEffect(() => {
    if (open === true && paymentProgress === PaymentProgress.SUB_CREATION) {
        dispatch(setPaymentProgress(PaymentProgress.READY));
        dispatch(setPaymentIdInProgress(undefined));
    }
  }, []);

  useEffect(() => {
    if (avatarInProgress) {
      dispatch(setActiveAvatar(avatarInProgress));
    }
  }, [avatarInProgress]);

  useEffect(() => {
    if (paymentProgress === PaymentProgress.WAIT_FOR_RESULT) {
      paymentStatusPolling();
    }
  }, [paymentProgress]);

  useEffect(() => {
    const tempActiveProducts = isCreditOrSub();

    if (activePaymentProductCode && tempActiveProducts) {
      const activeProductFromStore = tempActiveProducts.find(product => product.code === activePaymentProductCode);
      if (activeProductFromStore) {
        setActiveProduct(activeProductFromStore);
        dispatch(setActivePaymentProductCode(undefined));
      }
      return;
    }

    setActiveProduct(tempActiveProducts && tempActiveProducts[0]);
    
  }, [paymentType, notRecurringProducts, recurringProducts]);

  const isCreditOrSub = () => {
    let tempActiveProducts = paymentType === PaymentType.CREDIT ? notRecurringProducts : recurringProducts;

    if (activePaymentProductCode && notRecurringProducts && notRecurringProducts.find(product => product.code === activePaymentProductCode)) {
      tempActiveProducts = notRecurringProducts;
    }
    if (activePaymentProductCode && recurringProducts && recurringProducts.find(product => product.code === activePaymentProductCode)) {
      tempActiveProducts = recurringProducts;
      setPaymentTypeState(PaymentType.SUBSCRIPTION);
    }
    return tempActiveProducts;
  };

  const paymentStatusPolling = async () => {
    const pollingIntervalEnv = process.env.REACT_APP_POLLING_INTERVAL;
    const pollingCountEnv = process.env.REACT_APP_POLLING_COUNT;
    const pollingTimeout = Number(pollingIntervalEnv || 1000);
    let counter = 0;
    const maxPollingCount = Number(pollingCountEnv || 30);

    const executePoll = async () => {
      if (!paymentIdInProgress) {
        return;
      }
      const response = await checkPaymentStatus(paymentIdInProgress).unwrap();
      counter += 1;

      if (counter >= maxPollingCount || response.status === 'failed') {
        dispatch(setPaymentProgress(PaymentProgress.READY));
        dispatch(setPaymentIdInProgress(undefined));
        dispatch(setMembershipChecked(false));

        if (counter >= maxPollingCount) {
          setPollingResult(PollingResult.PENDING);
        }
        if (response.status === 'failed') {
          setPollingResult(PollingResult.FAILED);
        }
        return;
      }

      if (response.status === 'completed') {
        dispatch(setPaymentProgress(PaymentProgress.READY));
        dispatch(setPaymentIdInProgress(undefined));

        if (refFunc && refFunc.current && membershipChecked) {
          refFunc.current();
          dispatch(setMembershipChecked(false));
        } else {
          dispatch(updateCredits(response.creditAmount));
        }

        setPollingResult(PollingResult.COMPLETED);
        
        return;
      }

      setTimeout(executePoll, pollingTimeout);
    };

    return new Promise(executePoll);
  };

  const marketingLabelClasses = (product: PaymentProduct) => {
    if (!product.marketingLabel) {
      return '';
    }
    let classes = 'inline-block mr-2 text-sm ';
    if (product.marketingLabelClasses) {
      classes += product.marketingLabelClasses;
    } else {
      classes += 'border border-red-500 px-2 py-1 text-red-500 rounded-full';
    }
    
    return classes;
  };

  const renderProducts = () => {
    let text1 = ' credits';
    let text2 = '';
    const activeProducts = paymentType === PaymentType.SUBSCRIPTION ? recurringProducts : notRecurringProducts;
    if (paymentType === PaymentType.SUBSCRIPTION) {
      text1 = ' credits / month';
      text2 = ' /mo';
    }

    return activeProducts ? activeProducts.map(product => (
      <div key={product.id} onClick={() => setActiveProduct(product)}>
        <PaymentElement
          id={`${product.creditAmount}${text1}`}
          isActive={activeProduct ? activeProduct.id === product.id : false}
          leftText={product.creditAmountLabel || `${product.creditAmount}${text1}`}
          rightText={
            <div className="block sm:text-right">
              <div className="font-medium text-gray-900">{`$${product.price ? (product.price / 100).toFixed(2) : 'Amount not found'}${text2}`}</div>
            </div>
          }
          tag={
            product.marketingLabel ? <span className={marketingLabelClasses(product)}>{product.marketingLabel}</span> : undefined
          }
        />
      </div>
    )) : null;
  };

  const createReturnUrl = (product: PaymentProduct) => {
    let handle = 'null';
    // if (page === 'gallery' && avatarInProgress) {
    //   page += `/${avatarInProgress.id}`;
    // }
    // if (page === 'chat' && currentReferenceId) {
    //   page += `/${avatar?.handle}&${currentReferenceId}`;
    // }
    if (redirectPage === 'chat' && activeAvatar?.handle) {
      handle = `${activeAvatar.handle}`;
    }
    if (redirectPage === 'gallery' && activeAvatar?.id) {
      handle = `${activeAvatar.id}`;
    }
    return `${process.env.REACT_APP_API_BASE_URL}/payment-redirect/${product.code}/${redirectPage}/${handle}`;
  };

  const startPayment = async () => {
    if (activeProduct) {
      dispatch(setPaymentProgress(PaymentProgress.SUB_CREATION));

      let payment = activeProduct.paymentMethods.find(paymentMethod => paymentMethod.name === 'Credit Card');
      if (!payment) {
        // eslint-disable-next-line prefer-destructuring
        payment = activeProduct.paymentMethods[0];
      }
      const request: InitiatePaymentRequest = {
        id: activeProduct.id,
        code: activeProduct.code,
        paymentMethod: payment.code,
        returnUrl: createReturnUrl(activeProduct)
      };
      if (contentNodeId)
        request.nodeId = contentNodeId;
      const response = await initiatePayment(request).unwrap();
      dispatch(setPaymentIdInProgress(response.id));
      dispatch(setAvatarInProgress(avatar));
      window.location.href = response.url;
    } else {
      console.error('No payment product selected');
    }
  };

  const onPaymentResponseModalClose = async () => {
    dispatch(setPaymentModalOpen(false));
    setPollingResult(null);
  };

  const renderPollingResultModal = () => {
    let type = PollingResult.PENDING;
    let mainText = 'Pending confirmation';
    let secondaryText = 'Your payment is taking a little longer to process, we\'ll notify you in a message when it\'s completed.';

    switch (pollingResult) {
      case PollingResult.COMPLETED:
        type = PollingResult.COMPLETED;
        mainText = 'Purchase completed';
        secondaryText = 'Hooray! You now have access to the credits you purchased.';
        break;
      case PollingResult.FAILED:
        type = PollingResult.FAILED;
        mainText = 'Payment failed';
        secondaryText = 'The payment method you provided could not be charged. Please double check and try again!';
        break;
      default:
        type = PollingResult.PENDING;
        mainText = 'Pending confirmation';
        secondaryText = 'Your payment is taking a little longer to process, we\'ll notify you in a message when it\'s completed.';
        break;
    }

    return (
      <PaymentResponseModal
        mainText={mainText}
        secondaryText={secondaryText}
        type={type}
        onClose={onPaymentResponseModalClose}
      />
    );
  };

  return (
    <PaymentModalView
      open={open}
      setOpen={setOpen}
      paymentProgress={paymentProgress}
      avatar={avatar}
      paymentType={paymentType}
      pollingResult={pollingResult}
      setPaymentType={setPaymentTypeState}
      renderProducts={renderProducts}
      refFunc={refFunc}
      renderPollingResultModal={renderPollingResultModal}
      startPayment={startPayment}
      successCallback={successCallback}
      isMobileView={isMobileView}
    />
  );
};

export default PaymentModal;
