/* Input form component for login request */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { LockClosedIcon } from '@heroicons/react/solid';

import type { LoginRequest } from '../../interfaces';

import { ModalFormInput } from '../../components';
import { useAppDispatch } from "../../reducers/hooks";
import { persistor } from '../../reducers/store';
import { useLoginMutation } from '../../reducers/auth';
import { setCredentials } from '../../reducers/user';
import { setFriendAvatars, useGetAllAvatarByUserMutation } from '../../reducers/avatar';


interface ModalFormProps {
  initModalEmail?: string;
  setOpen: (val: boolean) => void;
}

const ModalForm: React.FC<ModalFormProps> = (props: ModalFormProps) => {
  const {
    initModalEmail,
    setOpen,
  } = props;

  const [formState, setFormState] = React.useState<LoginRequest>({
    email: '',
    password: ''
  });

  const dispatch = useAppDispatch();
  const { push } = useHistory();
  const [login, { error: loginError }] = useLoginMutation();
  const [getAvatars] = useGetAllAvatarByUserMutation();
  const passwordInput = React.createRef<HTMLInputElement>();

  let displayedError = "";
  if (loginError && "data" in loginError) {
    const { data } = loginError;
    displayedError = (data as {fieldErrors: string}).fieldErrors;
  }

  React.useEffect(() => {
    if (initModalEmail) {
      setFormState(prev => ({ ...prev, email: initModalEmail}));
      passwordInput.current?.focus();
    }
  }, [initModalEmail]);

  const handleChange = ({
    target: { name, value }
  }: React.ChangeEvent<HTMLInputElement>) =>
    setFormState(prev => ({ ...prev, [name]: value }));

  const redirect = async () => {
    const avatars = await getAvatars(null).unwrap();

    if (!avatars || avatars.length === 0) {
      push('/discover');
    } else {
      setOpen(false);
      window.location.reload();
    }

    dispatch(setFriendAvatars(avatars));
  };

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const loggedInUser = await login({
        email: formState.email,
        password: formState.password
      }).unwrap();
      persistor.purge();
      dispatch(setCredentials(loggedInUser));
      setOpen(false);
      redirect();
    } catch (err) {
      setFormState(prev => ({ ...prev, password: '' }));
    }
  };

  return (
    <form onSubmit={handleLogin} className="mt-8 space-y-6" id="signInModal">
    <ModalFormInput
      label="Email address"
      name="email"
      required
      value={formState.email}
      handleChange={handleChange}
      autoComplete="email"
      placeHolder="Enter your email address"
    />
    <ModalFormInput
      inputRef={passwordInput}
      label="Password"
      name="password"
      required
      value={formState.password}
      handleChange={handleChange}
      autoComplete="current-password"
      placeHolder="Enter your password"
    />
    {displayedError && <div className="sm:flex sm:justify-center"><div className="mt-0 pt-0 text-sm text-red-600">{displayedError}</div></div> }
    <div className="mt-6">
      <button
        id='signInModalButton'
        type="submit"
        className="group relative w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
      >
        <span className="absolute left-0 inset-y-0 flex items-center pl-3">
          <LockClosedIcon
            className="h-5 w-5 text-blue-400 group-hover:text-blue-400"
            aria-hidden="true"
          />
        </span>
        Sign in
      </button>
    </div>
  </form>
);
};

export default ModalForm;
